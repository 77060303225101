import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import './App.css';
import Header from './component/Header/Header';
import Headband from './component/Header/Headband';
import Auth from './containers/Auth/Auth';
import Home from './containers/Home/Home';
import Invoice from './containers/Invoice/Invoice';
import * as actions from './store/actions/index';
import DocumentDownload from './containers/AccessToken/AccessDocument/DocumentDownload/DocumentDownload';
import Clients from './containers/Clients/Clients';
import ChgLog from './component/ChgLog/ChgLog';
import Client from './containers/Client/Client';
import Parent from './containers/Client/Parent';
import Settings from './component/Settings/Settings';
import ClientSettings from './component/ClientSettings/ClientSettings';
import SecretDocumentDownload from './containers/AccessToken/AccessDocument/DocumentDownload/SecretDocumentDownload';
import ResetPassword from './component/Signin/ResetPassword/ResetPassword';
import ForgotPassword from './component/Signin/ForgottenPassword/ForgotPassword';
import Connect from "./component/Signin/Connect/Connect";
import ConnectWInvoiceToken from "./component/Signin/ConnectWInvoiceToken/ConnectWInvoiceToken";
import Logs from './containers/Logs/Logs';
import {HelmetProvider} from 'react-helmet-async';
import UserVisualisation from './containers/UserVisualisation/UserVisualisation';
import InvoiceChorus from './containers/InvoiceChorus/InvoiceChorus';

function App(props) {
  const {onTryAutoSignup, userRole, authRedirectPath, loading} = props;
  const [accessToken, setAccessToken] = useState(false);
  const accessTokenList = ["document", "forgotPassword", "account"];
  let location = useLocation();

  useEffect(() => {
    setAccessToken(accessTokenList.includes(location.pathname.split("/")[1]));
    onTryAutoSignup()
  }, [onTryAutoSignup]);
  let routes
  //console.log(props)
  if (props.isAuthenticated ) {
    if(userRole === 'COMPTA') {
      routes = (
        
        <>
          <Headband userRole={userRole}/>
          <div style={{display:"flex"}}>
            <Header userRole={userRole}/>
            <div className="content" >
              <Switch>
                <Route path="/clients" exact component={Clients} />
                <Route path="/client" exact component={Client} />
                <Route path="/parent" exact component={Parent} />
                <Route path="/invoice" exact component={Invoice}/>
                <Route path="/" exact component={Home} />
                <Redirect to="/" />
              </Switch>
            </div>
          </div>
        </>
      )
    }

    if(userRole === 'CLIENT_USER') {
      routes = (
        <>
          <Headband userRole={userRole}/>
          <div style={{display:"flex"}}>
            <Header userRole={userRole}/>
            <div className="content">
            <Switch>
              <Route path="/client" exact component={Client} />
              <Route path="/parent" exact component={Parent} />
              <Route path="/invoice" exact component={Invoice}/>
              <Route path="/settings" exact component={ClientSettings}/>
              <Route path="/" exact component={Home} />
              <Redirect to="/" />
            </Switch>
            </div>
          </div>
        </>
      )
    }

    if(userRole === 'ADMIN') {
      routes = (
        <>
          <Headband userRole={userRole}/>
          <div style={{display:"flex"}}>
            <Header userRole={userRole}/>
            <div className="content">
            <Switch>
              <Route path="/chglog" exact component={ChgLog} />
              <Route path="/clients" exact component={Clients} />
              <Route path="/logs" exact component={Logs} />
              <Route path="/client" exact component={Client} />
              <Route path="/parent" exact component={Parent} />
              <Route path="/invoice" exact component={Invoice}/>
              <Route path="/invoiceChorus" exact component={InvoiceChorus}/>
              <Route path="/settings" exact component={Settings}/>
              <Route path="/userVisualisation" exact component={UserVisualisation}/>
              <Route path="/userVisualisation/invoice" exact component={UserVisualisation} />
              <Route path="/" exact component={Home} />
              <Redirect to="/" />
            </Switch>
            </div>
          </div>
        </>
      )
    }

    
  } else {
    
    routes = (
      <div className="content">
      <Switch>
        <Route path="/document/:token/download" exact component={DocumentDownload}/>
        <Route path="/document/:token/view" exact component={SecretDocumentDownload}/>
        <Route path="/forgotPassword" exact component={ForgotPassword}/>
        <Route path="/account/:token" exact component={ResetPassword}/>
        <Route path="/connect" exact component={Connect}/>
        <Route path="/connectWInvoiceToken" exact component={ConnectWInvoiceToken}/>
        <Route path="/" exact component={Auth} />
        {/* <Redirect to="/" />  */}
      </Switch>
          </div>
    )
  }

  
  return (
    <HelmetProvider>
      <div className="app">
      {
        authRedirectPath && accessToken !== true ? (
          <Redirect to="/" />
        )
         : null
      }
        {routes}
      </div>
    </HelmetProvider>
  );
}

const mapStateToProps = state => {
  return {
    isAuthenticated: localStorage.getItem('token') !== null,
    loading : state.auth.loading,
    userRole: state.auth.role,
    authRedirectPath: state.auth.authRedirectPath
  }
}

const mapDispatchProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}
export default connect(mapStateToProps, mapDispatchProps)(App);

