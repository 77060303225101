import React, { useCallback, useEffect, useState } from 'react' 
import './ReplayModal.css';    
import Modal from '../../../UI/Modal/Modal'
 

function ReplayModal(props) {
    const {show, modalClosed, uidToRetry,numberToRetry, handleUpdateStep,retrySuccess, retryError} = props;    
    
    const [clicked, setClicked] = useState(false)
    const handleUpdateStepClickHandler = (uidToRetry) => {      
        handleUpdateStep(uidToRetry)
        setClicked(false)
    }

    let content = ( <div>  <Modal show={show} modalClosed={modalClosed}> 
            <div ><h3>Voulez vous rejouer la facture {numberToRetry} ? </h3></div> 
           
            <div className="statusEditModal__footer">  
                <button className="cm__confirmBtn btn btn-primary" onClick={() => handleUpdateStepClickHandler(uidToRetry)} disabled={ clicked} >Rejouer</button>                                      
                <button className="btn btn-secondary" onClick={modalClosed} >Annuler</button>
            </div>
        </Modal>   </div> )  
    if(retrySuccess){
        content =  ( <div>  <Modal show={show} modalClosed={modalClosed}> 
        <div className="statusEditModal__title">
             <div >  <h3>Voulez vous rejouer la facture {numberToRetry} ? </h3> </div> 
        </div> 
        <div className="statusEditModal__body">  
                <div className="filterContainer"  style={{paddingTop: "20px"}}> 
                <h4> Succès : la facture sera prochainement rejouée   </h4> 
                </div> 
        </div>  
        <div className="statusEditModal__footer">   
                <button className="btn btn-secondary" onClick={modalClosed} >Fermer</button>
        </div>
        </Modal>   </div> )  
    }else if(retryError){
         content =  ( <div>  <Modal show={show} modalClosed={modalClosed}>
            <div className="statusEditModal__title">
                <div >  <h3>Voulez vous rejouer la facture {numberToRetry} ? </h3>  </div> 
            </div> 
            <div className="statusEditModal__body">  
                <div className="filterContainer"  style={{paddingTop: "20px"}}> 
                <h4> Opération annulée : la facture n'a pas pu être rejouée, erreur : {retryError}  </h4> 
                </div> 
            </div>   
            <div className="statusEditModal__footer" >               
                <button className="btn btn-secondary" onClick={modalClosed} >Annuler</button>
            </div>
        </Modal>  </div> )  
    } 
     
    return content
} 
export default ReplayModal