import React, { useState, useCallback, useEffect } from 'react';
import './Header.css';
import PersonIcon from '@material-ui/icons/Person';
import NoteIcon from '@material-ui/icons/Note';
import HomeIcon from '@material-ui/icons/Home';
import DescriptionIcon from '@material-ui/icons/Description';
import BusinessIcon from '@material-ui/icons/Business';
import SettingsIcon from '@material-ui/icons/Settings';
import HistoryIcon from '@material-ui/icons/History';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import logo from '../../assets/images/logo-alkor-white.png';
import OAC from '../../assets/images/o&p.png';
import smallLogo from '../../assets/images/logo_alkor.png';
import { NavLink, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

function Header(props) {
    const {userRole} = props;
    const location = useLocation();

    const [showDropdown, setShowDropdown] = useState(false)
    const [showOpenNavBar, setShowOpenNavBar] = useState(false)

    const onClickHandler = () => {
        props.logout()
    }

    useEffect(() => {
        setShowDropdown(false);
    }, [location])
    

    const onClickHandleTransition = () =>{
        if(showOpenNavBar) setShowOpenNavBar(false);
        else setShowOpenNavBar(true)
    }

    let navList = null

    // let connect = (<NavLink className="header__logoutBtn" to="/">
    //                     {props.isAuthenticated ? 'Se déconnecter' : 'Se connecter'}
    //                 </NavLink>)

    if(userRole !== "ADMIN") {
        navList = (
            <>
                <li>
                    <NavLink exact className="nav__Link" to="/" activeClassName="nav__link_active">
                    <HomeIcon fontSize="large" className="nav__icon" style={{width: "28px"}}></HomeIcon>
                        <span className={`${showOpenNavBar ? "displayElement" : "hideElement"}`}>Accueil</span></NavLink>
                </li>
                <li>
                    <NavLink className="nav__Link" to="/invoice" activeClassName="nav__link_active">
                        <DescriptionIcon fontSize="large" className="nav__icon" style={{width: "28px"}}></DescriptionIcon>
                        <span className={`${showOpenNavBar ? "displayElement" : "hideElement"}`}>Factures</span></NavLink>
                </li>
                {userRole === "COMPTA" ? (
                    <li>
                        <NavLink className="nav__Link" to="/clients" activeClassName="nav__link_active">
                            <BusinessIcon fontSize="large" className="nav__icon" style={{width: "28px"}}></BusinessIcon>
                            <span className={`${showOpenNavBar ? "displayElement" : "hideElement"}`}>Clients</span></NavLink>
                    </li>
                ) : null}
            </>
        )
    } else {
        navList = (
            <>
                <li>
                    <NavLink exact className="nav__Link" to="/" activeClassName="nav__link_active">
                        {//<i className="fa fa-2x fa-home"></i>
                }
                <HomeIcon fontSize="large" className="nav__icon" style={{width: "28px"}}></HomeIcon>
                    <span className={`${showOpenNavBar ? "displayElement" : "hideElement"}`}>Accueil</span></NavLink>
                </li>
                <li>
                    <NavLink className="nav__Link" to="/invoice" activeClassName="nav__link_active">
                        <DescriptionIcon fontSize="large" className="nav__icon" style={{width: "28px"}}></DescriptionIcon>
                        <span className={`${showOpenNavBar ? "displayElement" : "hideElement"}`}>Factures</span></NavLink>
                </li>
                <li>
                    <NavLink className="nav__Link" to="/invoiceChorus" activeClassName="nav__link_active">
                        <NoteIcon fontSize="large" className="nav__icon" style={{width: "28px"}}></NoteIcon>
                        <span className={`${showOpenNavBar ? "displayElement" : "hideElement"}`}>Chorus</span></NavLink>
                </li>
                <li>
                    <NavLink className="nav__Link" to="/clients" activeClassName="nav__link_active">
                        <BusinessIcon fontSize="large" className="nav__icon" style={{width: "28px"}}></BusinessIcon>
                        <span className={`${showOpenNavBar ? "displayElement" : "hideElement"}`}>Clients</span></NavLink>
                </li>
                <li>
                    <NavLink className="nav__Link" to="/settings" activeClassName="nav__link_active">
                        <SettingsIcon fontSize="large" className="nav__icon" style={{width: "28px"}}></SettingsIcon>
                        <span className={`${showOpenNavBar ? "displayElement" : "hideElement"}`}>Paramètres</span></NavLink>
                    {/*<span className="nav__Link" activeClassName="nav__link_active">
                        <SettingsIcon fontSize="large" className="nav__icon"></SettingsIcon>
                    Configuration</span>
                    <ul>
                        <li><NavLink className="nav__Link" to="/settings" activeClassName="nav__link_active">Emails</NavLink></li>
                        <li><NavLink className="nav__Link" to="/settings" activeClassName="nav__link_active">SMTP</NavLink></li>
                        <li><NavLink className="nav__Link" to="/settings" activeClassName="nav__link_active">SFTP</NavLink></li>
                    </ul>*/}
                </li>
                <li>
                    <NavLink className="nav__Link" to="/logs" activeClassName="nav__link_active">
                        <HistoryIcon fontSize="large" className="nav__icon" style={{width: "28px"}}></HistoryIcon>
                        <span className={`${showOpenNavBar ? "displayElement" : "hideElement"}`}>Logs</span></NavLink>
                </li>
            </>
        )
    }

    return (
        <>
            {
                (location.pathname !== "/userVisualisation" && location.pathname !== "/userVisualisation/invoice") ? (
                    <div className="header" style={{ width : showOpenNavBar ? "209px" : "67px"}}>
                        <div className="header_openAndCLose">
                            <SwapHorizIcon className="OAC" onClick={onClickHandleTransition} style={{color:"white", background:"rgb(25, 98, 158)"}}></SwapHorizIcon>
                        </div>
                        <div className="header__container">
                            <ul className="nav__menu" id="mySidebar">
                                {navList}
                            </ul>
                        </div>
                    </div>
                ) : null
            }
        </>
        
    )
}

// const mapStateToProps = state => {
//     return {
//         isAuthenticated : state.auth.token !== null,
//         login : state.auth.login
//     }
// } 

// const mapDispatchToProps = dispatch => {
//     return {
//         onAuth : (email, password) => dispatch(actions.auth(email, password)),
//         onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/')),
//         logout: () => dispatch(actions.logout())
//     }
// }

//export default connect(mapStateToProps, mapDispatchToProps)(Header)
export default Header