import React from 'react'

function TableBody(props) {
    const {logsData, formatDate, getTimeDate} = props;

    return (
        <>     
            {
                logsData ? logsData.map(row => (
                    <tr key={row.id} className="tableBody__tr">
                        <td className="logsTable__td">
                            {formatDate(+row.creationDate) + ' ' + getTimeDate(+row.creationDate)}
                        </td>
                        <td className="logsTable__td" >
                            {row.type}
                        </td>
                        <td className="logsTable__td" >
                            {row.entity}
                        </td>
                        <td className="logsTable__td" >
                            {row.name}
                        </td>
                        <td className="logsTable__td" >
                            {row.siret}
                        </td>
                        <td className="logsTable__td">
                            {row.invoiceNumber}
                        </td>
                        <td className="logsTable__td">
                            { row.invoiceDate ? formatDate(+row.invoiceDate) + ' ' + getTimeDate(+row.invoiceDate) : ""}
                        </td>
                        <td className="logsTable__td">
                            { row.invoiceTransmissionDate? formatDate(+row.invoiceTransmissionDate) + ' ' + getTimeDate(+row.invoiceTransmissionDate):""}
                        </td>
                        <td className="logsTable__td">
                            {row.statusCode}
                        </td>
                        <td className="logsTable__td">
                            {row.description}
                        </td>
                        <td className="logsTable__td">
                            {row.level}
                        </td>
                        <td className="logsTable__td">
                            {row.fileReference}
                        </td>
                        <td className="logsTable__td">
                            {row.step}
                        </td>
                    </tr>
                )) : null
            }
        </>
    )
}

export default TableBody
