import React, { useCallback, useState } from 'react'
import './CaEnseigneYearCard.css'
import EqualizerIcon from '@material-ui/icons/Equalizer';
import CAEnseigneYear from '../Charts/CAEnseigneYear';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CAEnseigne from '../Charts/CAEnseigne';
import DateRange from '../../../InvoiceTable/TableFilters/DateRange/DateRange';

function CaEnseigneYearCard() {
    const [chartType, setChartType] = useState('mois');
    const date = new Date();
    const currentMonth = new Date().getMonth();

    const [showDRange, setShowDRange] = useState(false);
    const [dateInterval, setDateInterval] = useState({
        startDate: new Date(date.getFullYear() - 1, currentMonth + 1).getTime(),
        endDate: new Date().getTime()
    });

    const dateChangeHandler = (date) => {
        setDateInterval({
            startDate: date.startDate.getTime(),
            endDate: date.endDate.getTime()
        })
        //console.log(date.endDate.getTime());
    }

    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0){
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
        return day + '/' + month + '/' + newDate.getFullYear()
        }else return null;    
    }, [])

    return (
        <>
        <div className="card h-100" >
            <div className="card-header " >
                <div className="comptaStatBody__cardHeader">
                    <div className="comptaStatBody__cardHeader__title">
                        <EqualizerIcon style={{color:"#888888"}}  /> 
                        <h5>Évolution du CA </h5>
                    </div>
                    <div className="CaEnseigneYearCard__headerRight">
                        {
                            chartType === "periode" ? (
                                <div className="CaEnseigneYearCard__headerPeriode">
                                    <h5>Période : {formatDate(dateInterval.startDate)} -  {formatDate(dateInterval.endDate)}</h5>        
                                </div>
                            ) : null
                        }
                        <div className="CaEnseigneYearCard__headerTypes">
                            <button className={(chartType === "periode") ? "btn btn-primary" : "btn btn-secondary"} onClick={() => setChartType('mois')}>Par mois</button>
                            <button className={(chartType === "mois") ? "btn btn-primary" : "btn btn-secondary"} onClick={() => setChartType('periode')} >Par période</button>
                        </div>
                        
                    </div>
                </div>
                    
            </div>
            <div className="card-body">
                {
                    chartType === "mois" ? (
                        <CAEnseigneYear />
                    ) : <CAEnseigne dateInterval={dateInterval} />
                }  
            </div>
            <div className="card-footer text-center">
                {
                    chartType === "mois" ? null : (
                        <button className="btn btn-primary caEnseigneCard__periodeBtn" type="submit" onClick={() => setShowDRange(true)}>
                            <DateRangeIcon className="filter__icon" style={{color: "white", fontSize: 25}}  />
                            Saisir une période
                        </button>
                    )
                }    
            </div>
        </div>
        <DateRange show={showDRange} clicked={() => setShowDRange(false)} changed={(date) =>dateChangeHandler(date)} field={"caEnseigne"} />
        </>
    )
}

export default CaEnseigneYearCard
