import React, {useCallback, useState, useEffect} from 'react'
import Axios from '../../../../axios-alkor';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DateRange from '../../../InvoiceTable/TableFilters/DateRange/DateRange'
import Select from 'react-select';
import { removeObject } from "../../../../shared/utility";
import './StatHeaderElement.css'

function StatHeaderElementSales(props) {
    const {value, item, dateStart, dateEnd, clients, handleFilters, isClient} = props
    const [regions, setRegions] = useState([]);
    const [client, setClientFilter] = useState(null);
    const [region, setRegionFilter] = useState(null);
    const [dateInterval, setDateInterval] = useState(null)
    const [showDRange, setShowDRange] = useState(false)

    useEffect(() => {
        if (!isClient)
            Axios.get('/region').then((response) => {
                setRegions(response.data[0]);
            }).catch(error => {console.error(error);});
    }, [])

    /*useEffect(() => {
        if (isClient && clients)
            setClientFilter([...clients]);
    }, [clients])*/

    useEffect(() => {
        handleFilters(dateInterval, client, region);
    }, [dateInterval, client, region])
        
    const formatDate = useCallback((date) => {
        if (date !== null && date > 0){
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
        return day + '/' + month + '/' + newDate.getFullYear()
        }else return null;    
    }, [])

    const closeModalHandler = () => {
        setShowDRange(false);
    }

    const showCaModel = () => {
        setShowDRange(true);
    }

    const dateChangeHandler = (date) => {
        setDateInterval({
            startDate: date.startDate.getTime(),
            endDate: date.endDate.getTime()
        })
    }

    const handleClientFilter = (event) => {
        setClientFilter(event.target.value !== "" ? {id: event.target.value} : null);
    }

    const handleRegionFilter = (event) => {
        setRegionFilter(event.target.value !== "" ? {id: event.target.value} : null);
    }

    const handleChildrenChange = (value) => {
        setClientFilter(value.map(v => v.value));
    }

    return (
        <>
            <div className={isClient ? "col-sm-3" : "col-sm-6"}>
                <div className="card">
                    <div className="card-header">
                        <span><DateRangeIcon className="filter__icon" style={{color: "#ea5e0b", fontSize: 25}} onClick={() => showCaModel()}/></span> {item}
                        <span style={{color:"#888888", fontSize: "0.8rem"}}> 
                            { (dateEnd > 0 && dateStart > 0) ? ("  (Début: "+ formatDate(dateStart) + "  -   Fin: "+formatDate(dateEnd)+")") 
                                : "" }
                        </span>    
                    </div>
                    
                    <div className="card-body">
                        <div className="element__header">
                            <div className="elementHeader__value">
                                {value} 
                            </div>
                        </div>
                        <div className="element__progressBar">
                            <span style={{width: '100%'}} ></span>
                        </div>
                        {isClient ?
                        clients && <Select isMulti placeholder="-- Sélectionnez un ou plusieurs adhérents --" 
                        options={clients.map(c => ({value: c, label: c.name}))} onChange={handleChildrenChange}/>
                        :
                        <div className="row">
                            <div className="col-md-6">
                                <select className="form-control" onChange={handleRegionFilter}>
                                    <option value="">-- Sélectionnez une région --</option>
                                    {regions !== null && regions.length > 1 && 
                                        regions.map(r => <option key={r.id} value={r.id}>{r.name}</option>)
                                    }
                                </select>
                            </div>
                            <div className="col-md-6">
                                <select className="form-control" onChange={handleClientFilter}>
                                    <option value="">-- Sélectionnez un client --</option>
                                    {clients !== null && clients.length > 1 && 
                                        clients.map(c => <option key={c.id} value={c.id}>{c.name}</option>)
                                    }
                                </select>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            <DateRange show={showDRange} clicked={closeModalHandler} changed={(date) =>dateChangeHandler(date)} field={"ca"} />
        </>
    )
}

export default StatHeaderElementSales
