import React, { useRef, useEffect, useState } from 'react';
import './ClientSettings.css';
import Axios from '../../axios-alkor';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import TitleHelmet from '../UI/TitleHelmet/TitleHelmet';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';

const ClientSettings = () => {
    const notyf = new Notyf();
    const passwordInput = useRef(null);
    const [access, setAccess] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    
    useEffect(() => {
        Axios.get('/clientSftp//current').then(response => {
            if(response.data.access.username)
                setAccess(response.data.access);
        })
    }, [])

    const requestSftpAccess = () => {
        Axios.post('/clientSftp//request').then(response => {
            if(response.data.access.username) {
                setAccess(response.data.access);
                notyf.success("Compte SFTP activé");
            } else {
                notyf.error("Erreur dans l'activation");
            }
        })
    }

    const viewPassword = () => {
        if (showPassword)
            passwordInput.current.type = "password";
        else
            passwordInput.current.type = "text";
        setShowPassword(!showPassword);
    }
    
    return (
        <div className="clientSettings__container">
            <TitleHelmet title="Paramètres | ALKOR" />
            <div className="tableActions">
                 <div className="" style={{marginTop:"10px"}}>
                    <h2 style={{color:"#666666"}}>Paramètres</h2>
                </div>
            </div><hr width="100%" style={{ margin: "0"}}/>
            <ul className="nav nav-tabs" style={{marginTop:"9px"}}>
                <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" href="#list-sftp">SFTP</a>
                </li>
            </ul>
            <div id="myTabContent" className="tab-content justify-top">
                <div className="tab-pane fade show active" id="list-sftp">
                    {access ? <div className="core-sftp">
                        <div className="form-control-sftp">
                            <label className="clientSettings__formLabel" htmlFor="host">Nom d'hôte :</label>
                            <input className="clientSettings__formInput" type="text" name="host" value={access.host} readOnly />
                        </div>
                        <div className="form-control-sftp">
                            <label className="clientSettings__formLabel" htmlFor="Port">Port :</label>
                            <input className="clientSettings__formInput" type="text" name="port" value={access.port} readOnly />
                        </div>
                        <div className="form-control-sftp">
                            <label className="clientSettings__formLabel" htmlFor="username">Nom d'utilisateur :</label>
                            <input className="clientSettings__formInput" type="text" name="username" value={access.username} readOnly />
                        </div>
                        <div className="form-control-sftp">
                            <label className="clientSettings__formLabel" htmlFor="password">Mot de passe :</label>
                            <span style={{width: "100%"}}>
                                <input className="clientSettings__formInput" type="password" name="password" value={access.password} ref={passwordInput} readOnly />
                                {showPassword ? <VisibilityOffIcon className="visible-icon" onClick={() => viewPassword()} /> : <VisibilityIcon className="visible-icon" onClick={() => viewPassword()} />}
                            </span>
                        </div>
                    </div>
                    :
                    <div>
                        <h1 className="clientSettings__formH1">Pas d'accès SFTP</h1>
                        <button type="submit" className="btn btn-primary" onClick={() => requestSftpAccess()}>Demander un accès SFTP</button>         
                    </div>
                    }
                </div>

            </div>
        </div>
    )
}

export default ClientSettings
