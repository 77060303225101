import React, {   } from 'react'
import './TableBody.css';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CompareArrowsIcon  from '@material-ui/icons/CompareArrows';
import HourglassEmptyIcon  from '@material-ui/icons/HourglassEmpty';
import { Link} from 'react-router-dom';
import RenderPdf from './RenderPdf/RenderPdf';
import { Tooltip, withStyles } from '@material-ui/core';

const CTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
}))(Tooltip);

const KO_PORTAIL_STEPS = ['KO_DEPO','KO_TRAD']
const KO_PORTAIL_STEPS_LIBELLES = {'KO_DEPO':'ARCHIVAGE AU COFFRE','KO_TRAD':'TRADUCTION'}
const KO_CHORUS_STEPS = ['KO_CHORUS_FLUX_REJECTED','KO_CHORUS_LIFECYCLE']
const KO_CHORUS_STEPS_LIBELLES = {'KO_CHORUS_FLUX_REJECTED':'REJET IMPORT INITIAL','KO_CHORUS_LIFECYCLE':'REJET CYCLE DE VIE FACTURE'}
const PENDING_STEPS = ['WF_TRAD','WF_TRAD_SERVER_RETRY','WF_BATCH_CHORUS_SEND','WF_CHORUS_ACK','WF_DEPO']
const PENDING_STEPS_LIBELLES = {'WF_TRAD':'TRADUCTION','WF_TRAD_SERVER_RETRY':'TRADUCTION','WF_BATCH_CHORUS_SEND':'PREPARATION ENVOI CHORUS','WF_CHORUS_ACK':'ATTENTE REPONSE CHORUS','WF_DEPO':'ARCHIVAGE AU COFFRE'}


function TableBodyChorus(props) {
    const {data, formatDate, setViewed,selectedRows, setSelectedRows,replayKoInvoiceHandler ,showRetry} = props;

    const formatNumber = (number) => {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number).replaceAll('.',' ')
    }

    const getSelectedRowValue = (id) => {
        if (selectedRows) {
            return selectedRows[id] || false
        } else return false
    }

    const selectRowHandler = (e) => {
        let id = e.target.name;
        let val = e.target.checked;
        setSelectedRows(id, val)
    }

    const replayKoInvoice= (e, uid, number) => {
        e.preventDefault();
        replayKoInvoiceHandler(uid, number)
    }    

    return (
        <>    
            {
                data.map(row => (
                    <tr key={row.id} className={!KO_PORTAIL_STEPS.includes(row.step) ? "tableBody__tr" : "tableBody__tr_error" }>
                        <td >
                            <input type="checkbox" name={row.uid} onChange={(e) => selectRowHandler(e)} checked={getSelectedRowValue(row.uid)}  className="form-check-input" style={{margin: 0}} id="exampleCheck1" />
                        </td>
                        <td > 
                            {
                                KO_PORTAIL_STEPS.includes(row.step) ?
                                    <CTooltip title={"NON ENVOYEE, ERREUR " + KO_PORTAIL_STEPS_LIBELLES[row.step] + ", CONTACTEZ LE SUPPORT"} className="tableActions__icon" style={{color: '#022f67', fontSize: "20px"}}>
                                        <ErrorOutlineIcon />
                                    </CTooltip>
                                : KO_CHORUS_STEPS.includes(row.step) ?
                                    <CTooltip title={"ERREUR " +  KO_CHORUS_STEPS_LIBELLES[ row.step] + ", REJOUEZ LA FACTURE"} className="tableActions__icon" style={{color: '#022f67', fontSize: "20px"}}>
                                        <ErrorOutlineIcon />
                                    </CTooltip>
                                : PENDING_STEPS.includes(row.step) ?
                                    <CTooltip title={"EN COURS DE TRAITEMENT, ETAPE " + PENDING_STEPS_LIBELLES[row.step]} className="tableActions__icon" style={{color: '#022f67', fontSize: "20px"}}>
                                        <HourglassEmptyIcon />
                                    </CTooltip> 
                                : null 
                            }  
                        </td>
                        <td >{row.clientCode}</td>
                        <td >
                            <div className="tableBody__companyName">
                                <Link to={{
                                    pathname: "/client",
                                    state: {
                                        clientUid: row.clientUid
                                    }
                                }}>
                                    {row.clientName}
                                </Link>
                            </div>
                        </td>
                        <td >{row.clientSiret} </td>                        
                        <td >{row.number}</td> 
                        <td > <div className="tableBody__Amount"> { formatNumber(row.exclusiveTaxAmount)} </div> </td>
                        <td > <div className="tableBody__Amount"> {formatNumber(row.inclusiveTaxAmount)} </div> </td>
                        <td > {formatDate(+row.issuingDate)} </td>
                        <td > {formatDate(+row.dueDate)} </td>
                        <td >
                        {
                                 KO_PORTAIL_STEPS.includes(row.step) ? ("NON DEPOSEE, ERREUR " +  KO_PORTAIL_STEPS_LIBELLES[row.step] + " [" + row.step + "] VEUILLER CONTACTER LE SUPPORT")
                                 : row.chorusStatusComplement != null ? (  ( PENDING_STEPS.includes(row.step) ? "EN COURS : " :"")  + row.chorusStatusComplement )
                                 : PENDING_STEPS.includes(row.step) ?  ( "EN COURS : " + PENDING_STEPS_LIBELLES[row.step])                                   
                                 : ""
                        }
                        </td>
                        <td >
                            { (row.pdf !== null && row.pdf !== "") ? (
                                <RenderPdf number={row.id} uid={row.uid} type="PDF" docName={row.localCopy} linkName="PDF" clicked={setViewed} /> 
                            ): null}   
                            { (row.chorus !== null && row.pdf !== "") ? (
                                <>
                                    <span style={{margin: "0 2px"}}>|</span>
                                    <RenderPdf number={row.id} uid={row.uid} type="CHORUS" docName={row.localCopy} linkName="CHORUS" clicked={setViewed} />
                                </>
                            ) : null}
                            { (row.alkor !== null && row.pdf !== "") ? (
                                <>
                                    <span style={{margin: "0 2px"}}>|</span>
                                    <RenderPdf number={row.id} uid={row.uid} type="ALKOR" docName={row.localCopy} linkName="ALKOR" clicked={setViewed} />
                                </>
                            ) : null}
                            { (KO_PORTAIL_STEPS.includes(row.step) && showRetry) &&
                                <CTooltip title={"REJOUER " + KO_PORTAIL_STEPS_LIBELLES[row.step]} className="tableActions__icon" style={{color: '#022f67', fontSize: "20px"}}>                                 
                                    <div className="tableActions__action" style={{border: "none"}}>                                          
                                         <CompareArrowsIcon onClick={(e) => replayKoInvoice(e, row.uid, row.number)} fontSize="small"  /> 
                                    </div>
                                </CTooltip>
                            }                            
                        </td> 
                    </tr>
                    
                ))
                
            }
        </>
    )
}

export default TableBodyChorus
