import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import Axios from '../../../axios-alkor';
import { authSuccess } from "../../../store/actions/auth";
import LOGO from '../../../assets/images/logo-alkor.png';
import { Link, useLocation, useParams } from 'react-router-dom';
import './Connect.css'
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';

function Connect({checkLogin}) {
    const location = useLocation();
    const query = location.search + (location.hash ? location.hash : "");
    
    const [error, setError] = useState(null);

    useEffect(() => {
        checkLogin(query);
    }, []);

    let content = (
        <div className="forgotPassword__formContainer">
            <span className="signin__error">{error}</span>
        </div>
    )

    return (
        <div className="forgotPassword__container" style={{padding: "30px"}}>
            <Link to="/" className="signin__Icon">
                <img src={LOGO} alt="logo" />
            </Link>
            {content}
        </div>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.token !== null,
    loading : state.auth.loading,
})
const mapDispatchToProps = dispatch => ({
    checkLogin: (query) => dispatch(actions.checkLogin(query))
})
export default connect(mapStateToProps, mapDispatchToProps) (Connect)
