import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import authReducer from './store/reducers/auth';
import invoiceReducer from './store/reducers/invoice'
import clientsReducer from './store/reducers/clients'
import logsReducer from './store/reducers/logs'
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  invoice: invoiceReducer,
  clients: clientsReducer,
  logs: logsReducer
})
const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
))
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter >
        <App/>
      </BrowserRouter>
    
    </React.StrictMode>
  </Provider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
