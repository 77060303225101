import React, { useState } from 'react'
import './RangeSlider.css';
import Modal from '../../../UI/Modal/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

function RangeSlider(props) {

    const {value, changed,modalState, closeModal, title, defaultVal, defaultValStart,defaultValEnd} = props;

    const useStyles = makeStyles({
        root: {
            width: 600,
            height: 8,
            color: '#022f67'
        },
        thumb: {
            height: 24,
            width: 24,
            backgroundColor: '#ea5e0b',
            border: '2px solid currentColor',
            marginTop: -8,
            marginLeft: -12,
            '&:focus, &:hover, &$active': {
              boxShadow: 'inherit',
            },
        },
        active: {},
        track: {
            height: 8,
            borderRadius: 4,
        },
        rail: {
            height: 8,
            borderRadius: 4,
        },
    });
      
    
    const classes = useStyles();
    const [sliderValue, setSliderValue] = useState([defaultValStart, defaultValEnd]);
    
    const handleChange = (event, newValue) => {
        setSliderValue(newValue)
    }

    const handleValidate = (e) => {
        changed(sliderValue[0], sliderValue[1])
        closeModal()
    }

    return (
        <>
            <Modal show={modalState}  modalClosed={closeModal} defaultVal={defaultVal} >
                
                <div className="slider__container">
                    <div className="slider__title">
                        {title}
                    </div>
                    <div className="slider__body">
                        <div className="slider__start">
                            <input 
                                className="slider__input" 
                                style={{marginBottom: "5px"}} 
                                placeholder="De :" 
                                onChange={(e) => setSliderValue([e.target.value, sliderValue[1]])}
                                value={sliderValue[0]} 
                                name="start" 
                                type="number" 
                                step="0.01" 
                                autoComplete="off" />
                        </div>
                        <Slider
                            defaultValue={[+value("start"), +value("end")]}
                            classes={classes}
                            value={sliderValue}
                            step={0.01}
                            min={+defaultVal.start}
                            max={+defaultVal.end}
                            onChange={handleChange}
                            valueLabelDisplay="off"
                            
                        />
                        <div className="slider__end">
                            <input 
                                className="slider__input" 
                                placeholder="À : "
                                onChange={(e) => setSliderValue([sliderValue[0], e.target.value])}   
                                value={sliderValue[1]} 
                                name="end" 
                                type="number" 
                                step="0.01" 
                                autoComplete="off" />
                        </div> 
                    </div>
                    <div className="slider__footer">
                        <button className="slider__btn" onClick={(e) => handleValidate(e)}>
                            Valider
                        </button>
                    </div>
                    
                </div>
                
            </Modal> 
        </>
    )
}

export default RangeSlider
