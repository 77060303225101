import React, { useCallback, useEffect, useState } from 'react';
import Axios from '../../axios-alkor';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import TablePagination from '../../component/InvoiceTable/TablePagination/TablePagination';
import './Logs.css';
import TableActions from '../../component/LogsTable/TableActions/TableActions';
import TableHead from '../../component/LogsTable/TableHead/TableHead';
import TableFilters from '../../component/LogsTable/TableFilters/TableFilters';
import TableBody from '../../component/LogsTable/TableBody/TableBody';
import Spinner from '../../component/UI/Spinner/Spinner';
import TitleHelmet from '../../component/UI/TitleHelmet/TitleHelmet';

function Logs(props) {
    let {logsData,loading,  count, typesList, stepsList, levelsList, sortQuery, reverse, currentPage, 
        filtersQuery, showFilters, updateShowFilters, setLogsFilters, getLogsPage, setSortQuery, 
        setReverse, setLogsPage} = props

    useEffect(() => {
        getLogsPage(currentPage, filtersQuery, sortQuery, reverse)
    }, [getLogsPage])

    const columns = [
        {title: 'Date creation', field: 'creationDate', width: "130px"},
        {title: 'Type', field: 'type', width: "95px"},
        {title: 'Entité', field: 'entity', width: "100px"},
        {title: 'Nom', field: 'name'},
        {title: 'Siret', field: 'siret', width: "90px"},
        {title: 'N° facture', field: 'invoiceNumber', width: "100px"},
        {title: 'Date facture', field: 'invoiceDate', width: "120px"},
        {title: 'Date émission', field: 'invoiceTransmissionDate', width: "120px"},
        {title: 'Code status', field: 'statusCode', width: "90px"},
        {title: 'Description', field: 'Description'},
        {title: 'Niveau', field: 'level', width: "60px"},
        {title: 'Nom fichier', field: 'fileReference', width: "250px"},
        {title: 'Étape', field: 'step', width: "130px"}
    ]
    const columnClickHandler = (field, reverse) => {
        setSortQuery(field);
        let reverseValue= reverse[field] ? reverse[field] : false 
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse);
        getLogsPage(currentPage, filtersQuery, field, newReverse)
    }

    const handlePageChange = useCallback((newPage) => {
        setLogsPage(newPage)
        getLogsPage(newPage, filtersQuery, sortQuery, reverse);
    }, [currentPage]);

    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0){
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
        return day + '/' + month + '/' + newDate.getFullYear()
        }else return null;    
    }, [])

    const getTimeDate = (date) => {
        let newDate = new Date(date);
        let hour = newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours()
        let minutes = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes()
        return hour + ":" + minutes
    }
    const handleApplyFiltering = useCallback((filters) => {
        getLogsPage(1,filters, sortQuery, reverse);
    }, [currentPage, filtersQuery, reverse, sortQuery]);

    const resetLogs = () => {
        setLogsFilters(null);
        setLogsPage(1)
        getLogsPage(1, null, sortQuery, reverse)
    }
    
    let tBody = null;
    let tSpinner = loading ? (
        <div className="invoiceTable__spinner">
            <Spinner />
        </div>
        ) : <h2 style={{fontSize:"1rem",color:"#888888",padding:"0px 0px 0px 48px"}}>Aucun log à afficher</h2>;

    if(!loading && logsData?.length > 0){
        tSpinner = null;
        tBody = (
            <TableBody
                logsData={logsData}
                formatDate={formatDate}
                getTimeDate={getTimeDate}
            />
        )       
    }

    return (
        <div className="invoice">
            <TitleHelmet title="Portail facture Alkor | Logs " />
            <div className="invoiceTable">
                <div className="invoiceHeader">
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        {/* <p style={{color:"white", margin:"5px 0px 0px 23px", fontSize:"16px"}}>Logs </p> */}
                        <TableActions 
                            resetLogs={resetLogs}
                            showFilters={showFilters}
                            setShowFilters={updateShowFilters}
                            formatDate={formatDate}
                            logsFilters={filtersQuery}
                        />
                        <TablePagination currentPage={currentPage} pageChange={handlePageChange} totalElement={count} perPage={100} />
                    </div>
                </div>
                <table className="table">
                    <TableHead
                        columns={columns}
                        reverse={reverse}
                        columnClickHandler={columnClickHandler}
                    />
                    <tbody style={{overflow:"auto",maxHeight:"calc(100vh - 166px)"}}>
                        <TableFilters 
                            showFilters={showFilters}
                            formatDate={formatDate}
                            logsFilters={filtersQuery}
                            filtersChange={setLogsFilters}
                            typesList={typesList}
                            stepList={stepsList}
                            levelsList={levelsList}
                            applyFilters={handleApplyFiltering}
                        />
                        {tBody}
                    </tbody>
                </table>
                {tSpinner}
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    loading: state.logs.loading,
    logsData: state.logs.data,
    count: state.logs.count,
    typesList: state.logs.typesList,
    stepsList: state.logs.stepsList,
    levelsList: state.logs.levelsList,
    sortQuery: state.logs.sortQuery,
    reverse: state.logs.reverse,
    currentPage: state.logs.currentPage,
    filtersQuery: state.logs.filtersQuery,
    showFilters: state.logs.showFilters
})
const mapDispatchToProps = dispatch => ({
    getLogsPage: (page, filters, sort, reverse) => dispatch(actions.logsGetPage(page, filters, sort, reverse)),
    setSortQuery: (sortQ) => dispatch(actions.logsSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.logsSetReverseSort(reverseS)),
    setLogsPage: (page) => dispatch(actions.logsSetPage(page)),
    setLogsFilters: (filters) => dispatch(actions.logsSetFilterQuery(filters)),
    updateShowFilters: (show) => dispatch(actions.logsUpdateShowFilters(show)) 
})
export default connect(mapStateToProps, mapDispatchToProps)(Logs)
