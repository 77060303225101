import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Pie } from 'react-chartjs-2'
import PieChartSharpIcon from '@material-ui/icons/PieChartSharp';
import Axios from '../../../../axios-alkor';
import Select from 'react-select';

function CamembertChartSpec({visualisation}) {
    //const {spentInterval, childrenFilters, isClient, updateClientList} = props;
    const userRole = localStorage.getItem('role');
    const [enseignes, setEnseignes] = useState([]);
    const [enseigne, setEnseigneFilter] = useState(null);
    const [chartLabels, setChartLabels] = useState([]);
    const [chartData, setChartData] = useState([]);
    let customLabels= [];
    const [nullEnseigne, setNullEnseigne] = useState(0);

    // const [ LITIGATION, setLitigation ] = useState([])
    // const [ TO_BE_PAID, setToBePaid ] = useState([])
    // const [ PAID, setPaid ] = useState([])
    // const [ NEW, setNew ] = useState([])
    // const [ NbInvoices, setNbInvoices]= useState(0)
    
    useEffect ( () =>{
        let queryParams = null
        if(visualisation)
            queryParams = {
                visualisation: true
            }
        let dataChart = [];
        let chartLab = [];
        Axios.get("/invoice//invoicesByEnseigne").then( (response) => {
            response.data.result.map(row => {
                chartLab.push(row?.name)
                dataChart.push(row?.nbEnseigne)
            })
            setChartData(dataChart);
            setChartLabels(chartLab);
        })
    }, [])
    useEffect(() => {
        if (userRole === "COMPTA" || userRole === "ADMIN")
            Axios.get('/enseigne').then((response) => {
                setEnseignes(response.data[0]);
            }).catch(error => {console.error(error);});
    }, [])


    //chartdata = [NEW?.length, TO_BE_PAID?.length, PAID?.length, LITIGATION?.length]
    customLabels = chartLabels?.map((label,index) =>`${label}`);//chartdata[index]
    console.log("custom invoice \n"+chartData);
    console.log("chart data \n"+chartLabels);
    const data ={
        labels: customLabels,
        datasets: [
            {    
                backgroundColor: [
                    "#CDE371",
                    "#61CFE9",
                    "#4CC85E",//"#CDE371",
                    "#FF5E6D",
                    "#82E0AA"
                ],
                data: chartData
            }
            ,{
                backgroundColor:["yellow"]
            }
        ],
        series:[{
            data: chartData
        }]
        // These labels appear in the legend and in the tooltips when hovering different arcs
        
    }

    const options = {
        //maintainAspectRatio: false,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        },
        title: {
            display: false,
            text: 'État des factures',
            fontSize: 35,
            fontColor: "#566573",
            padding:30
        },
        legend: {
            position: "right",
            labels: {
                fontColor: '#566573',
                fontSize: 15,
                padding: 10
            }
        },
        tooltips: {
            backgroundColor: "#5a6e7f"
        }
    }
    return (

            <div className="card h-100">
                <div className="card-header" style={{display:"flex", justifyContent:"space-between"}}>
                    <div >
                        <span><PieChartSharpIcon/></span> Factures par enseigne
                    </div>
                    {/* {  userRole === "COMPTA" || userRole === "ADMIN" ? 
                        <div style={{width:"41%", display:"flex"}}>
                            <span style={{margin:"auto"}}>Enseigne:&nbsp;&nbsp;</span>
                            <select className="form-control">
                                <option value="">-- Sélectionnez un enseigne --</option>
                                {enseignes !== null && enseignes.length > 1 && 
                                    enseignes.map(e => <option key={e.id} value={e.id}>{e.name}</option>)
                                }
                            </select> 
                        </div>: ""
                    }    */}
                </div>
                <div className="card-body">
                    <Pie data={data} options={options} datasetKeyProvider={() => btoa(Math.random()).substring(0,12)} />
                </div>
                <div className="card-footer text-center">
                </div>
            </div>
        
    )
}

export default CamembertChartSpec

