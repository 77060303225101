import React, { useEffect, useState } from 'react'
import './DocumentDownload.css';
import Axios from '../../../../axios-alkor';
import { useParams } from 'react-router-dom';
import LOGO from '../../../../assets/images/logo-alkor.png'

function DocumentDownload() {

    let {token} = useParams(); 
    const [fileBase64, setFileBase64] = useState(null);
    const [docName, setDocName] = useState(null)

    useEffect(() => {
        // Axios.get('/invoice/'+token+'/documentByToken')
        // .then(response => {
        //     setDocName(response.data.name);
        //     setFileBase64(response.data.content);
        // })
        // .catch(err => {
        //     console.log(err.response.data.message)
        // })
    }, [])

    const downloadHandleClick = (e) => {
        e.preventDefault();
        Axios.get('/invoice/'+token+'/documentByToken')
        .then(response => {
            let fileName = response.data.name;
            let data = response.data.content;
            if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
                let byteCharacters = atob(data);
                let byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                let byteArray = new Uint8Array(byteNumbers);
                let blob = new Blob([byteArray], {type: 'application/pdf'});
                window.navigator.msSaveOrOpenBlob(blob, fileName);
            }
            else { // much easier if not IE
                //________Direct Download_____
                let blob = new Blob([data], { type: 'application/pdf' })
                const downloadUrl = URL.createObjectURL(blob)
                let a = document.createElement("a"); 
                a.href = "data:application/pdf;base64," + data;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();

                //__________Visualize In The Browser _____
                // const blob = dataURItoBlob(data);
                // const url = URL.createObjectURL(blob);

                // // to open the PDF in a new window
                // window.open(url, '_blank');
            }
        })
        .catch(err => {
            console.log(err.response?.data.message)
        })    
    }

    return (
        <div className="DocDld__container">
            <div className="DocDld__wrapper">
                <div className="DocDld__logo">
                    <img src={LOGO} />
                </div>
                <div className="DocDld__title">
                    <h2>Cliquez sur le bouton pour télécharger le document</h2>
                </div>
                <div className="DivDld__btn">
                    <a onClick={(e) => downloadHandleClick(e)} href="#">Télécharger</a>
                    {/* <a download={docName} href={'data:application/octet-stream;base64,'+fileBase64}>Télécharger</a> */}
                </div>
            </div>
            
        </div>
    )
}

export default DocumentDownload
