import React from 'react'
import './ComptaStatBody.css'
import CamembertChartSpec from '../../../component/HomeStat/StatBody/Charts/CamembertChartSpec';
import CaEnseigneCard from './CaEnseigneCard/CaEnseigneCard';
import CaEnseigneYearCard from './CaEnseigneYearCard/CaEnseigneYearCard';

function ComptaStatBody() {

    return (
        <>
            <div className="row">
                <div className=" col-sm-6" > 
                    <CamembertChartSpec />
                </div>
                <div className=" col-sm-6" > 
                    <CaEnseigneYearCard />
                </div>
                {/* <CaEnseigneCard /> */}
            </div>
            {/* <div className="row">
                <div className=" col-sm-12" style={{minHeight: "500px"}}>
                    <CaEnseigneYearCard />
                </div>
            </div> */}
        </>
        
    )
}

export default ComptaStatBody
