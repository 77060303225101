import React, { useState, useEffect } from 'react'
import CamembertChart from '../../../component/HomeStat/StatBody/Charts/CamembertChart';
import BarChart from './Charts/BarChart'
import './StatBody.css'
import EqualizerIcon from '@material-ui/icons/Equalizer';

function StatBody({visualisation}) {

    return (
        <div className="row" style={{marginBottom:"50px"}}>
            {/*<div className="StatBody__charts">
                <BarChartYear />
                <LineCharts spentInterval={spentInterval} />
                <button className="btn btn-primary" onClick={() => setShowDRange(true)}>Saisir un intervalle</button>
                <DateRange show={showDRange} clicked={closeModalHandler} changed={(date) =>dateChangeHandler(date)} field={"spent"} />
                <DoughnutChart />
            </div>*/}
            {/*<div className="col-sm-6" > 
                <CamembertChart visualisation={visualisation} />
            </div>*/}
            
            <div className="col-sm-12" >
                <div className="card">
                    <div className="card-header" >
                        <span><EqualizerIcon/></span>Achats sur les 12 derniers mois
                    </div>
                    <div className="card-body" style={{height:"40vh"}}>
                    <BarChart/>
                    </div>
                    {/*<div className="card-footer text-center"></div>*/}
                </div>
            </div>
        </div>
    )
}

export default StatBody
