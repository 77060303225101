import React from 'react'
import './TablePagination.css';
import Pagination from "react-js-pagination";

function TablePagination(props) {
    const {currentPage, pageChange, totalElement, perPage} = props;
    const pageNumbers = [];
    const pageElement = perPage || 10;
    for (let i = 1; i <= Math.ceil(totalElement / pageElement); i++) {
        pageNumbers.push(i);
    }

  return (
      <>
        <nav className="paginationContainer">
            <div className="paginationList">
                <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={pageElement}
                    totalItemsCount={totalElement}
                    pageRangeDisplayed={5}
                    onChange={(page) => pageChange(page)}
                    innerClass={"pagination"}
                    itemClass={"page-item"}
                    linkClass={"page-link"}
                />
                <span className="pagination__total">{totalElement} résultat(s)</span>
            </div>
            
        </nav>
    </>
  )
}

export default TablePagination
