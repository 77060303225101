import React, { useEffect, useState } from 'react'
import './DocumentDownload.css';
import Axios from '../../../../axios-alkor';
import { useParams } from 'react-router-dom';
import LOGO from '../../../../assets/images/logo-alkor.png'

function SecretDocumentDownload() {

    let {token} = useParams(); 
    const [fileBase64, setFileBase64] = useState(null);
    const [docName, setDocName] = useState(null)

    useEffect(() => {
        Axios.get('/invoice/'+token+'/secretDocumentByToken')
        .then(response => {
            setDocName(response.data.name);
            setFileBase64(response.data.content);
        })
        .catch(err => {
            console.log(err.response.data.message)
        })
    }, [])
    return (
        <div className="DocDld__container">
            <div className="DocDld__wrapper">
                <div className="DocDld__logo">
                    <img src={LOGO} />
                </div>
                <div className="DocDld__title">
                    <h2>Cliquez sur le bouton pour télécharger le document</h2>
                </div>
                <div className="DivDld__btn">
                    <a download={docName} href={'data:application/octet-stream;base64,'+fileBase64}>Télécharger</a>
                </div>
            </div>
            
        </div>
    )
}

export default SecretDocumentDownload
