import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import './ManualAckModal.css';  
import * as actions from '../../../../store/actions/index';
import Modal from '../../../UI/Modal/Modal'; 
import Axios from '../../../../axios-alkor'; 

function ManualAckModal(props) {
    const {show, modalClosed, currentPage, filtersQuery, sortQuery, reverse,getInvoicesPage} = props;    
    const [invoiceNumbers, setInvoiceNumbers] = useState()
    const [data, setData] = useState([]) 
    const [idFlux, setIdFlux] = useState()  
    const [alert, setAlert] = useState(null); 
    const [finalSuccess, setFinalSuccess] = useState(null); 
    const [finalError, setFinalError] = useState(null);  

    const searchClickHandler = () => {    
        setAlert(null) 
        Axios.get("/invoice/"+idFlux+"/manualAck").then(response => {            
            setData(response.data)
        }).catch(err => {
            setAlert("numéro ID flux non trouvé")
        })    
    } 

    const updateClickHandler = () => {   
        let filteredData =  data.filter(elem => elem?.checked === "1")
        filteredData =  filteredData.map(elem => elem.uid);
        Axios.put("/invoice/"+idFlux+"/manualAck", {         
            uids : filteredData
        }).then(response => { 
            setFinalSuccess("Acquittement importé avec success.")
            getInvoicesPage(currentPage, filtersQuery, sortQuery, reverse)
        }).catch(err => {
            console.log(err) 
            setFinalError("Une erreur est survenu lors de l'import de l'acquittement.")
        })    
    }

    const selectRowHandler = (e) => {
        let uid = e.target.name;
        let val = e.target.checked;   
        let newData = [...data]
        newData = newData.map(obj => { 
            if (obj.uid === uid) {
                obj.checked=(val===true ? "1" :"0");
            }
            return obj;
        })
        setData(newData); 
    } 
 
    let content =  
        (
        <div>
            <Modal show={show} modalClosed={modalClosed}>
                <div className="statusEditModal__title">
                   <h2>Saisie manuel de rejet chorus</h2> 
                </div>
                <div className="statusEditModal__body">
                    <div className="filterContainer">  
                        NUMERO ID FLUX   
                    </div>  
                    <div className="filterContainer">
                        <input 
                            type="text" 
                            class="form-control" 
                            placeholder= "ID flux du flux acquitté"   
                            onChange={(e) => setIdFlux(e.target.value)} 
                            value={idFlux || ''}
                            disabled={ data.length > 0 }
                        /> 
                    </div>  
                    {data.length > 0 && 
                        <>   
                        <div className="filterContainer"  style={{paddingTop: "20px"}}> 
                            Cocher parmis ces factures celles qui figurent dans l'acquittement chorus comme refusées. <br/>
                            Elle seront isolées et les autres partirons dans un nouvel envoi chorus.
                        </div> 
                        <div style={{paddingTop: "10px",paddingLeft: "20px"}}> 
                        {   
                            data.map(row => ( 
                                <tr key={row.uid} >
                                    <td > 
                                        <input type="checkbox" name={row.uid} onChange={(e) => selectRowHandler(e)} checked={row.checked==="1"}  className="form-check-input" style={{margin: 0}} id="exampleCheck1" />
                                    </td> 
                                    <td style={{paddingLeft:"10px"}}>{row.number} </td> 
                                </tr> 
                            )) 
                        }    
                        </div>
                        </>
                    }
                    {alert && 
                        <div style ={{color: "red", paddingTop: "10px", paddingLeft: "10px"}}> 
                            <h5>{alert} </h5>   
                        </div>
                    }  
                </div>  
                <div className="statusEditModal__footer">
                    {data.length === 0 && 
                        <button className="cm__confirmBtn btn btn-primary" onClick={() => searchClickHandler()}>Rechercher</button>
                    } 
                    {data.length > 0 &&
                        <button className="cm__confirmBtn btn btn-primary" onClick={() => updateClickHandler()} disabled={ !data ||  !(data.some(elem => elem?.checked === "1")) }  >Acquitter</button>
                    }
                    <button className="btn btn-secondary" onClick={modalClosed} >Annuler</button>
                </div>
            </Modal>   
        </div>
    )

 
    if(finalSuccess || finalError) {
        content = ( 
            <div>
            <Modal show={show} modalClosed={modalClosed}>
                <div className="statusEditModal__title">
                   <h2>Saisie manuel de rejet chorus</h2> 
                </div>
                <div className="statusEditModal__body">

                    {finalSuccess && 
                    <div style ={{paddingTop: "10px", paddingLeft: "10px"}}> 
                        <h5>{finalSuccess} </h5>
                    </div>
                    }
                    {finalError && 
                    <div style ={{color: "red", paddingTop: "10px", paddingLeft: "10px"}}> 
                        <h5>{finalError} </h5>
                    </div>
                    }
                </div>
                <div className="statusEditModal__footer"> 
                    <button className="btn btn-secondary" onClick={modalClosed} >Fermer</button>
                </div>
            </Modal>   
            </div>
        )
    } 
    return content
}



const mapStateToProps = (state) => ({ 
    currentPage: state.invoice.currentChorusPage,
    filtersQuery: state.invoice.filtersQuery,
    sortQuery: state.invoice.sortQuery,
    reverse: state.invoice.reverse 
})
const mapDispatchToProps = dispatch => ({
    getInvoicesPage: (page, filters, sort, reverse) => dispatch(actions.invoiceChorusGetPage(page, filters, sort, reverse)),   
})
export default connect(mapStateToProps, mapDispatchToProps) (ManualAckModal)