import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Bar, defaults , Chart, Doughnut} from 'react-chartjs-2'
import Axios from '../../../../axios-alkor';

function CAEnseigneYear() {

    const [labels, setLabels] = useState([]);
    const [dataAmounts, setDataAmounts] = useState([]);
    const monthList = useMemo(() => ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"], [])
    const colorList = useMemo(() => ['rgba(255, 166, 24, 1)', "rgb(198, 65, 165)", "rgb(26, 150, 172)", "rgb(202, 188, 94)", "rgb(148, 70, 142)"])
    const chartRef = useRef();
    
    const randomRGBColor = () => {
        let x = Math.floor(Math.random() * 256);
        let y = Math.floor(Math.random() * 256);
        let z = Math.floor(Math.random() * 256);
        return "rgb(" + x + "," + y + "," + z + ")";
    }

    useEffect(() => {
        const date = new Date();
        const currentMonth = new Date().getMonth();
        const firstMonth = new Date(date.getFullYear() - 1, currentMonth + 1).getTime();
        const lastMonth = new Date().getTime();
        const dateInterval = firstMonth + ":" + lastMonth;
        Axios.get('/invoice//adminSpentByMonth?caMonthInterval='+dateInterval).then((response) => {
            
            let enseigneList = [];
            let dataSetTemp = []
            const statData = response.data.data;
            statData.map(row => {
                for (const key in row) {
                   if(!(enseigneList.indexOf(row.name) >= 0)) 
                    enseigneList.push(row.name)
                }
            })
            enseigneList.map((enseigneKey, index) => {
                let amounts = [0,0,0,0,0,0,0,0,0,0,0,0];
                let enseigneColor = colorList[index] || randomRGBColor();
                let filtredArray = statData.filter(row => row.name === enseigneKey)
                filtredArray.map(row => {
                    for (const key in row) {
                        let temp = +row['monthTotal'].substr(5,2) - 1;
                        let pos = temp - (currentMonth + 1) >= 0 ? temp - (currentMonth + 1) : (temp - (currentMonth + 1)) + 12
                        if (key == 'totalAmountBT') {
                            amounts[pos] = +row[key]
                        }    
                    }
                })

                dataSetTemp.push({
                    label: enseigneKey,
                    yAxisID: 'A',
                    data: amounts,
                    borderColor: enseigneColor,
                    backgroundColor: enseigneColor,
                    barPercentage: 0.4,
                    stack: 1 
                })
            })
            let monthLabels = monthList.slice(currentMonth + 1, 12).concat(monthList.slice(0,currentMonth + 1))  
            setLabels(monthLabels);
            setDataAmounts(dataSetTemp);
            
        }).catch(error => {
            console.log(error)
        })

    }, [])
    
    const data ={
        labels: labels,
        datasets: dataAmounts
    }

    const options = {
        maintainAspectRatio: false,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        },
        title: {
            display: false,
            text: 'ÉVOLUTION DU CA SUR LES 12 DERNIERS MOIS',
            fontSize: 20,
            fontColor: "white"
        },
        scales: {
            yAxes: [
                {
                    id: 'A',
                    position: 'left',
                    gridLines: {
                        color: "rgba(200,200,200,0.5)"
                    },
                    ticks: {
                        fontColor: 'black',
                        // min: 0,
                        // max: 6,
                        //stepSize: 200
                    }
                }
             ]
            ,
            xAxes: [{
                gridLines: {
                    color: "rgba(200,200,200,0.5)"
                },
                ticks: {
                    fontColor: "black",
                    fontSize: 14,
                },
                position: "relative"

            }]
        },
        legend: {
            position: "bottom",
            labels: {
                fontColor: 'black',
                fontSize: 15,
                padding: 10
            }
        }
    }

    
    return (
        <>
            <Bar ref={chartRef} data={data} options={options} />    
        </>
        
    )
}

export default CAEnseigneYear