import React, { useState } from 'react'
import FindInPageIcon from '@material-ui/icons/FindInPage';
import ListIcon from '@material-ui/icons/List';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DateRange from '../../InvoiceTable/TableFilters/DateRange/DateRange';
import CloseIcon from '@material-ui/icons/Close';

function TableFilters(props) {
    const {showFilters, formatDate, logsFilters, typesList, stepList, levelsList, filtersChange, applyFilters} = props;

    const [show, setShow] = useState(null); 
    const [dateField, setDateField] = useState('');

    const convertDate = (date) => {
        let dateArray = date.split(':');
        return [formatDate(+dateArray[0]) , formatDate(+dateArray[1])]
    }
    let [dateInputValue,setDateInputValue] = useState({
        creationDate: logsFilters?.creationDate ? convertDate(logsFilters.creationDate) : ''
    });
    const getCreationdate = () => {
        return logsFilters?.creationDate ? convertDate(logsFilters.creationDate) : [];
    }
    const getInvoiceTransmissionDate = () => {
        return logsFilters?.invoiceTransmissionDate ? convertDate(logsFilters.invoiceTransmissionDate) : [];
    }
    const getInvoiceDate = () => {
        return logsFilters?.invoiceDate ? convertDate(logsFilters.invoiceDate) : [];
    }
    const dateShowHandler = (field) => {
        setShow({
            [field]: true
        })
        setDateField(field);
    }

    const closeModalHandler = () => {
        setShow(false);
    }

    const dateChangeHandler = (date) => {
        setDateInputValue({
            ...dateInputValue,
            [dateField]: formatDate(date.startDate) + '-' + formatDate(date.endDate)
        })
        let key = date.field;
        let newFilters = {
            ...logsFilters,
            [key]: date.startDate.getTime() + ':' + date.endDate.getTime()
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    const dateResetHandler = (field) => {
        setDateInputValue({
            ...dateInputValue,
            [field]: ''
        })
        let newFilters = {
            ...logsFilters,
            [field]: null
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...logsFilters,
            [key]: e.target.value
        }
        filtersChange(newFilters);
        applyFilters(newFilters)
    }
    return (
        <>
            <tr className="tableFilters" style={{display: showFilters ? 'table-row' : 'none'}}>
                <td style={{padding: 0}}>
                    <div className="filterContainer">
                        <div className="filter__amountInterval">
                            <h6 className="filter__input" style={{marginBottom: "5px"}}  > De: {getCreationdate()[0] || ''}</h6>
                            <h6 className="filter__input" style={{marginBottom: "5px"}}  > À: {getCreationdate()[1] || ''}</h6>
                        </div>
                        <DateRangeIcon className="filter__icon" style={{color: "#022f67"}} onClick={() => dateShowHandler("creationDate")} />
                        <DateRange format={formatDate} show={show?.creationDate} clicked={closeModalHandler} changed={(date) =>dateChangeHandler(date)} field={dateField} reset={dateResetHandler} />
                        <CloseIcon className="filter__icon" style={{color: "red", display: logsFilters?.creationDate ? "block" : "none"}} onClick={() => dateResetHandler("creationDate")} />
                    </div>  
                </td>
                <td>
                    <div className="filterContainer">
                        <select className="form-control filter__select" onChange={(e) => inputChangeHandler(e)} value={logsFilters?.type || ''} name="type">
                            <option value=""></option>
                            {
                                typesList ? typesList.map(row => (
                                    <option value={row}>{row}</option>
                                )) : null
                            }
                        </select>
                    </div> 
                </td>
                <td>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={logsFilters?.entity || ''} name="entity" type="text" autoComplete="off" />
                    </div> 
                </td>
                <td>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={logsFilters?.name || ''} name="name" type="text" autoComplete="off" />
                    </div>
                </td>
                <td>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={logsFilters?.siret || ''} name="siret" type="text" autoComplete="off" />
                    </div>
                </td>
                <td>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={logsFilters?.invoiceNumber || ''} name="invoiceNumber" type="text" autoComplete="off" />
                    </div>
                </td>
                <td style={{padding: 0}}>
                    <div className="filterContainer">
                        <div className="filter__amountInterval">
                            <h6 className="filter__input" style={{marginBottom: "5px"}}  > De: {getInvoiceDate()[0] || ''}</h6>
                            <h6 className="filter__input" style={{marginBottom: "5px"}}  > À: {getInvoiceDate()[1] || ''}</h6>
                        </div>
                        <DateRangeIcon className="filter__icon" style={{color: "#022f67"}} onClick={() => dateShowHandler("invoiceDate")} />
                        <DateRange format={formatDate} show={show?.invoiceDate} clicked={closeModalHandler} changed={(date) =>dateChangeHandler(date)} field={dateField} reset={dateResetHandler} />
                        <CloseIcon className="filter__icon" style={{color: "red", display: logsFilters?.invoiceDate ? "block" : "none"}} onClick={() => dateResetHandler("invoiceDate")} />
                    </div>  
                </td>
                <td style={{padding: 0}}>
                    <div className="filterContainer">
                        <div className="filter__amountInterval">
                            <h6 className="filter__input" style={{marginBottom: "5px"}}  > De: {getInvoiceTransmissionDate()[0] || ''}</h6>
                            <h6 className="filter__input" style={{marginBottom: "5px"}}  > À: {getInvoiceTransmissionDate()[1] || ''}</h6>
                        </div>
                        <DateRangeIcon className="filter__icon" style={{color: "#022f67"}} onClick={() => dateShowHandler("invoiceTransmissionDate")} />
                        <DateRange format={formatDate} show={show?.invoiceTransmissionDate} clicked={closeModalHandler} changed={(date) =>dateChangeHandler(date)} field={dateField} reset={dateResetHandler} />
                        <CloseIcon className="filter__icon" 
                            style={{color: "red", display: logsFilters?.invoiceTransmissionDate ? "block" : "none"}} 
                            onClick={() => dateResetHandler("invoiceTransmissionDate")} 
                        />
                    </div>  
                </td>
                <td>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={logsFilters?.statusCode || ''} name="statusCode" type="text" autoComplete="off" />
                    </div>
                </td>
                <td>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={logsFilters?.description || ''} name="description" type="text" autoComplete="off" />
                        
                    </div>
                </td>
                <td>
                    <div className="filterContainer">
                        <select className="form-control filter__select" onChange={(e) => inputChangeHandler(e)} value={logsFilters?.level || ''} name="level">
                            {
                                levelsList ? levelsList.map(row => (
                                    <option { ...row == "ERROR" ? "SELECTED" : ""} value={row}>{row}</option>
                                )) : null
                            }
                        </select>                        
                    </div>
                </td>
                <td>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={logsFilters?.fileReference || ''} name="fileReference" type="text" autoComplete="off" />
                        
                    </div>
                </td>
                <td>
                    <div className="filterContainer">
                        <select className="form-control filter__select" onChange={(e) => inputChangeHandler(e)} value={logsFilters?.step || ''} name="step">
                            <option value=""></option>
                            {
                                stepList ? stepList.map(row => (
                                    <option value={row}>{row}</option>
                                )) : null
                            }
                        </select>
                    </div>
                </td>
            </tr>
        </>
    )
}

export default TableFilters
