import React, { useCallback, useRef, useState } from 'react'
import './TableFilters.css';
import Axios from '../../../axios-alkor'
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import CloseIcon from '@material-ui/icons/Close';
import ListIcon from '@material-ui/icons/List';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import BusinessIcon from '@material-ui/icons/Business';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DateRange from './DateRange/DateRange';
import RangeSlider from './RangeSlider/RangeSlider';

function TableFilters(props) {
    const { formatDate, filters, filtersChange, applyFilters, amountBTTotal, amountATTotal, amountBTValues, showErrors } = props;
    const userRole = localStorage.getItem('role');

    const getAmountBT = (field) => {
        if (filters?.exclusiveTaxAmount) {
            let amount = filters?.exclusiveTaxAmount.split(":");
            if (field === "start")
                return amount[0]
            else return amount[1]
        } else {
            return '';
        }
    }
    const [show, setShow] = useState(null)
    const [rangeShow, setRangeShow] = useState(false);
    const [rangeBTShow, setRangeBTShow] = useState(false);
    const [dateField, setDateField] = useState('')

    const [amountBT, setAmountBT] = useState(null)
    const [amountAT, setAmountAT] = useState(null)

    const rangeBTModalHandler = () => {
        setRangeBTShow(true);
        getAmountBTValues();
    }
    const getAmountBTValues = () => {
        const role = localStorage.getItem('role');
        Axios.get("invoice//amountBTValues", {
            params: { visualisation: filters.visualisation }
        }).then(response => {
            setAmountBT({
                start: +response.data.minAmountBT,
                end: +response.data.maxAmountBT
            })
        })
    }

    const rangeATModalHandler = () => {
        setRangeShow(true);
        getAmountATValues();
    }
    const getAmountATValues = () => {
        const role = localStorage.getItem('role');
        Axios.get("invoice//amountATValues", {
            params: { visualisation: filters.visualisation }
        }).then(response => {
            setAmountAT({
                start: +response.data.minAmountAT,
                end: +response.data.maxAmountAT
            })
        })
    }

    const closeModalHandler = () => {
        setShow(false);
    }
    const dateShowHandler = (field) => {
        setShow({
            [field]: true
        })
        setDateField(field);
    }
    const convertDate = (date) => {
        let dateArray = date.split(':');
        return [formatDate(+dateArray[0]), formatDate(+dateArray[1])]
    }

    let [dateInputValue, setDateInputValue] = useState({
        issuingDate: filters?.issuingDate ? convertDate(filters.issuingDate) : '',
        dueDate: filters?.dueDate ? convertDate(filters.dueDate) : ''
    });

    const getCreationdate = () => {
        return filters?.issuingDate ? convertDate(filters.issuingDate) : [];
    }

    const getDueDate = () => {
        return filters?.dueDate ? convertDate(filters.dueDate) : [];
    }
    const dateChangeHandler = (date) => {
        setDateInputValue({
            ...dateInputValue,
            [dateField]: formatDate(date.startDate) + '-' + formatDate(date.endDate)
        })
        let key = date.field;
        let newFilters = {
            ...filters,
            [key]: date.startDate.getTime() + ':' + date.endDate.getTime()
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    const dateResetHandler = (field) => {
        setDateInputValue({
            ...dateInputValue,
            [field]: ''
        })
        let newFilters = {
            ...filters,
            [field]: null
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    const closeAmountModal = () => {
        setRangeShow(false);
    }

    const closeAmountBTModal = () => {
        setRangeBTShow(false);
    }


    const getAmountAT = (field) => {
        if (filters?.inclusiveTaxAmount) {
            let amount = filters?.inclusiveTaxAmount.split(":");
            if (field === "start")
                return amount[0]
            else return amount[1]
        } else {
            return '';
        }
    }

    const amountAtChanged = (startval, endval) => {
        setAmountAT({
            start: startval,
            end: endval
        })
        let newFilters = {
            ...filters,
            inclusiveTaxAmount: startval + ':' + endval
        };
        filtersChange(newFilters);
        applyFilters(newFilters);
    }

    const amountATReset = () => {
        let newFilters = {
            ...filters,
            inclusiveTaxAmount: null
        };
        filtersChange(newFilters);
        applyFilters(newFilters);
    }

    const amountBtChanged = (startval, endval) => {
        setAmountBT({
            start: startval,
            end: endval
        })
        let newFilters = {
            ...filters,
            exclusiveTaxAmount: startval + ':' + endval
        };
        filtersChange(newFilters);
        applyFilters(newFilters);
    }

    const amountBTReset = () => {
        let newFilters = {
            ...filters,
            exclusiveTaxAmount: null
        };
        filtersChange(newFilters);
        applyFilters(newFilters);
    }

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...filters,
            [key]: e.target.value
        }
        filtersChange(newFilters);
        applyFilters(newFilters);
    }

    return (
        <>
            <tr className="tableFilters" style={{ display: props.show ? 'table-row' : 'none' }}>
                <td ></td>
                {
                    showErrors &&
                    <td >
                        <div className="filterContainer">
                            <select className="form-control filter__select" onChange={(e) => inputChangeHandler(e)} value={filters?.error || ''} name="error" style={{ height: "32px", fontSize: "13px" }}>
                                <option value=""></option>
                                <option value="KO">Erreur</option>
                                <option value="OK">OK</option>
                                <option value="WF">En cours</option>
                            </select>
                        </div>
                    </td>
                }
                <td >
                    <div className="filterContainer">
                        <select className="form-control filter__select" onChange={(e) => inputChangeHandler(e)} value={filters?.firstAccessDate || ''} name="firstAccessDate" style={{ height: "32px", fontSize: "13px" }}>
                            <option value=""></option>
                            <option value="0">NON LUS</option>
                            <option value="1">LUS</option>
                        </select>
                    </div>
                </td>
                <td style={{ padding: "5px 0px 0px 0px" }}>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={filters?.clientName || ''} name="clientName" type="text" autoComplete="off" />
                    </div>
                </td>
                <td style={{ padding: "5px 0px 0px 0px" }}>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={filters?.clientEnseigne || ''} name="clientEnseigne" type="text" autoComplete="off" />
                    </div>
                </td>
                <td style={{ padding: "5px 0px 0px 0px" }}>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={filters?.clientCode || ''} name="clientCode" type="text" autoComplete="off" />
                    </div>
                </td>
                <td style={{ padding: "5px 0px 0px 0px" }}>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={filters?.number || ''} name="number" type="text" autoComplete="off" />
                    </div>
                </td>
                <td style={{ padding: "5px 0px 0px 0px" }}>
                    <div className="filterContainer">
                        <select className="form-control filter__select" onChange={(e) => inputChangeHandler(e)} value={filters?.invoiceType || ''} name="invoiceType" id="exampleFormControlSelect2" style={{ fontSize: "13px" }}>
                            <option value=""></option>
                            <option value="avoir">AVOIR</option>
                            <option value="facture">FACTURE</option>
                            <option value="releve">RELEVE</option>
                        </select>
                    </div>
                </td>
                <td style={{ padding: 0 }}>
                    <div className="filterContainer">
                        <div className="filter__amountInterval">
                            <h6 className="filter__input" style={{ marginBottom: "5px" }}  > De: {getAmountBT("start") || ''}</h6>
                            <h6 className="filter__input" style={{ marginBottom: "5px" }}  > À: {getAmountBT("end") || ''}</h6>
                            {amountBTTotal !== 0 ? (<h6 className="filter__input" style={{ marginBottom: "5px", fontWeight: "600" }}  > Total: {amountBTTotal}</h6>) : null}
                        </div>
                        <div className="filter__actions">
                            <SettingsEthernetIcon className="filter__icon" style={{ color: "#022f67" }} onClick={() => rangeBTModalHandler()} />
                            <CloseIcon className="filter__icon" style={{ color: "red", display: filters?.exclusiveTaxAmount ? "block" : "none" }} onClick={() => amountBTReset()} />
                        </div>
                        {(rangeBTShow && amountBT) ? (<RangeSlider defaultVal={amountBT} defaultValStart={+getAmountBT("start") || amountBT?.start} defaultValEnd={+getAmountBT("end") || amountBT?.end} defaultUpdateVal={amountBTValues} title={"Montant HT : "} value={getAmountBT} changed={amountBtChanged} modalState={rangeBTShow} closeModal={closeAmountBTModal} applyFilters={applyFilters} />) : null}
                    </div>
                </td>
                <td style={{ padding: 0 }}>
                    <div className="filterContainer">
                        <div className="filter__amountInterval">
                            <h6 className="filter__input" style={{ marginBottom: "5px" }}  > De: {getAmountAT("start") || ''}</h6>
                            <h6 className="filter__input" style={{ marginBottom: "5px" }}  > À: {getAmountAT("end") || ''}</h6>
                            {amountATTotal !== 0 ? (<h6 className="filter__input" style={{ marginBottom: "5px", fontWeight: "600" }}  > Total: {amountATTotal}</h6>) : null}
                        </div>
                        <div className="filter__actions">
                            <SettingsEthernetIcon className="filter__icon" style={{ color: "#022f67" }} onClick={() => rangeATModalHandler()} />
                            <CloseIcon className="filter__icon" style={{ color: "red", display: filters?.inclusiveTaxAmount ? "block" : "none" }} onClick={() => amountATReset()} />
                        </div>

                        {(rangeShow && amountAT) ? (<RangeSlider defaultVal={amountAT} defaultValStart={+getAmountAT("start") || amountAT?.start} defaultValEnd={+getAmountAT("end") || amountAT?.end} value={getAmountAT} changed={amountAtChanged} modalState={rangeShow} closeModal={closeAmountModal} />) : null}

                    </div>
                </td>
                <td style={{ padding: 0 }}>
                    <div className="filterContainer">
                        <div className="filter__amountInterval">
                            <h6 className="filter__input" style={{ marginBottom: "5px" }}  > De: {getCreationdate()[0] || ''}</h6>
                            <h6 className="filter__input" style={{ marginBottom: "5px" }}  > À: {getCreationdate()[1] || ''}</h6>
                        </div>
                        <DateRangeIcon className="filter__icon" style={{ color: "#022f67" }} onClick={() => dateShowHandler("issuingDate")} />
                        <DateRange format={formatDate} show={show?.issuingDate} clicked={closeModalHandler} changed={(date) => dateChangeHandler(date)} field={dateField} reset={dateResetHandler} />
                        <CloseIcon className="filter__icon" style={{ color: "red", display: filters?.issuingDate ? "block" : "none" }} onClick={() => dateResetHandler("issuingDate")} />
                    </div>
                </td>
                <td style={{ padding: 0 }}>
                    <div className="filterContainer">
                        <div className="filter__amountInterval">
                            <h6 className="filter__input" style={{ marginBottom: "5px" }}  > De: {getDueDate()[0] || ''}</h6>
                            <h6 className="filter__input" style={{ marginBottom: "5px" }}  > À: {getDueDate()[1] || ''}</h6>
                        </div>
                        <DateRangeIcon className="filter__icon" style={{ color: "#022f67" }} onClick={() => dateShowHandler("dueDate")} />
                        <DateRange format={formatDate} show={show?.dueDate} clicked={closeModalHandler} changed={(date) => dateChangeHandler(date)} field={dateField} reset={dateResetHandler} />
                        <CloseIcon className="filter__icon" style={{ color: "red", display: filters?.dueDate ? "block" : "none" }} onClick={() => dateResetHandler("dueDate")} />
                    </div>
                </td>
                <td></td>
            </tr>
            <tr></tr>
        </>
    )
}

export default TableFilters
