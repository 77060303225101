import React, { useCallback, useEffect } from 'react';
import TablePagination from '../../component/InvoiceTable/TablePagination/TablePagination';
import TableActions from '../../component/ClientsTable/TableActions/TableActions';
import TableHead from '../../component/ClientsTable/TableHead/TableHead';
import TableBody from '../../component/ClientsTable/TableBody/TableBody';
import TableFilters from '../../component/ClientsTable/TableFilters/TableFilters';
import { connect } from 'react-redux';
import "./Clients.css";
import * as actions from '../../store/actions/index';
import Spinner from '../../component/UI/Spinner/Spinner';
import TitleHelmet from '../../component/UI/TitleHelmet/TitleHelmet';

function Clients(props) {
    // autres <=> conf demat: demat, chorus, ubl
    //                        demat sur portail

    // coordonées   : email, ville, cp
     
    const {loading, clientsData, count, currentPage, getClientsPage, setClientsPage, sortQuery, reverse, filtersQuery, showFilters, updateShowFilters, setClientsFilters, setSortQuery, setReverse} = props;

    const columns = [
        {title: 'Code Client', field: 'customerCode', width: "110px"},
        {title: 'Client', field: 'name', width: "250px"},
        {title: 'Enseigne', field: 'clientEnseigne', width: "150px"},
        {title: 'Code postal', field: 'postCode', width: "100px"},
        {title: 'Ville', field: 'town', width: "180px"},
        {title: 'Type de demat', field: 'dematType', width: "110px"},
        {title: 'UBL', field: 'ubl', width: "60px"},
        {title: 'Chorus', field: 'chorus', width: "60px"},
        {title: 'Four', field: 'supplier', width: "60px"},
        {title: 'Email d’envoi', field: 'email', width: "300px"}
    ]

    useEffect(() => {
        getClientsPage(currentPage, filtersQuery, sortQuery, reverse)
    }, [currentPage])

    const columnClickHandler = (field, reverse) => {
        setSortQuery(field);
        let reverseValue= reverse[field] ? reverse[field] : false 
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse);
        getClientsPage(currentPage, filtersQuery, field, newReverse)
    }

    const handlePageChange = useCallback((newPage) => {
        setClientsPage(newPage);
    }, [currentPage]);

    const handleApplyFiltering = useCallback((filters) => {
        getClientsPage(1,filters, sortQuery, reverse);
    }, [currentPage, filtersQuery, reverse, sortQuery]);

    const resetLogs = () => {
        setClientsFilters(null);
        setClientsPage(1)
        getClientsPage(1, null, sortQuery, reverse)
    }

    let tBody = null;
    let tSpinner = loading ? (
        <div className="invoiceTable__spinner">
            <Spinner />
        </div>
        ) : <h2 style={{fontSize:"1rem",color:"#888888",padding:"0px 0px 0px 48px"}}>Aucun client à afficher</h2>;

    if(!loading && clientsData?.length > 0){
        tSpinner = null;
        tBody = ( <TableBody clientsData={clientsData} />)
    }

    return (
        <div className="invoice">
            <TitleHelmet title="Portail facture Alkor | Clients " />
            <div className="invoiceTable">
                <div className="invoiceHeader">
                    <TableActions 
                        resetLogs={resetLogs}
                        showFilters={showFilters}
                        setShowFilters={updateShowFilters}
                    />
                    <TablePagination currentPage={currentPage} pageChange={handlePageChange} totalElement={count} perPage={100}/>
                </div>
                <table className="table" >
                    <TableHead 
                        columns={columns}
                        reverse={reverse}
                        columnClickHandler={columnClickHandler} 
                    />
                    <tbody style={{display: "table-row-group"}}>
                        <TableFilters 
                            showFilters={showFilters}
                            clientsFilters={filtersQuery}
                            filtersChange={setClientsFilters}
                            applyFilters={handleApplyFiltering}
                        />
                        {tBody}
                    </tbody>            
                </table>
                {tSpinner}
				 
            
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.clients.loading,
    clientsData: state.clients.data,
    count: state.clients.count,
    currentPage: state.clients.currentPage,
    sortQuery: state.clients.sortQuery,
    reverse: state.clients.reverse,
    filtersQuery: state.clients.filtersQuery,
    showFilters: state.clients.showFilters
})
const mapDispatchToProps = dispatch => ({
    getClientsPage: (page, filters, sort, reverse) => dispatch(actions.clientsGetPage(page, filters, sort, reverse)),
    setClientsPage: (page) => dispatch(actions.clientsSetPage(page)),
    setSortQuery: (sortQ) => dispatch(actions.clientsSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.clientsSetReverseSort(reverseS)),
    setClientsFilters: (filters) => dispatch(actions.clientsSetFilterQuery(filters)),
    updateShowFilters: (show) => dispatch(actions.clientsUpdateShowFilters(show)),
})
export default connect(mapStateToProps, mapDispatchToProps) (Clients)
