import axios from 'axios';
import * as actionTypes from './actionTypes';
import {Base64} from 'js-base64';
import Axios from '../../axios-alkor';
import { env } from './../../env'

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const authSuccess = (login,token, userId, role, isSupport) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        login: login,
        userId: userId,
        role: role,
        isSupport: isSupport
    }
}

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate')
    localStorage.removeItem('userId')
    localStorage.removeItem('login')
    localStorage.removeItem('loginVisualisation')
    localStorage.removeItem('tokenTemp')
    localStorage.removeItem('role')
    localStorage.removeItem('isSupport')
    
    return {
        type: actionTypes.AUTH_LOGOUT
    }
}

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout())
        }, expirationTime)
    }
}

export const auth = (email, password) => { 
    return dispatch => {
        dispatch(authStart());
        let authAuthorization = Base64.encode(email + ":" + password)
        let config = {
            headers: {
                'Authorization': 'Basic ' + authAuthorization
            }
        }
        axios.get(env.REACT_APP_BASE_URL+'/user//authenticate/', config)
        //axios.get('http://favexws.rec.e.icdsc.fr/user/'+email+'/authenticate/', config)
        //axios.get('https://favexws.preprod.dematrust.com/user/'+email+'/authenticate/', config)
            .then(response => {
                const expirationDate = new Date(response.data.expireIn * 1000 );
                Axios.defaults.headers.authorization = 'Bearer ' + response.data.token;
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('expirationDate', response.data.expireIn)
                //localStorage.setItem('userId', response.data.id)
                localStorage.setItem('userId', response.data.uid)
                localStorage.setItem('login', response.data.email)
                localStorage.setItem('role', response.data.role)
                localStorage.setItem('isSupport', response.data.isSupport)
                dispatch(authSuccess(response.data.email ,response.data.token, response.data.id, response.data.role, response.data.isSupport));
                //dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000))
            })
            .catch(err => {
                dispatch(authFail(err.response?.data.message))
            })
    }
}

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    }
}

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if(!token) {
            dispatch(logout())
        } else {
            dispatch(authStart());
            const instance = Axios;
            instance.defaults.headers.authorization = 'Bearer ' + token;
            //console.log("brear");
            //console.log(instance.defaults.headers.authorization );
            instance.get('/user//checkAuth')
                .then(response =>{
                    const userId = localStorage.getItem('userId')
                    const role = localStorage.getItem('role')
                    const isSupport = localStorage.getItem('isSupport')
                    const login = localStorage.getItem('login') || ''
                    dispatch(authSuccess(login,token, userId, role ,isSupport))
                })
                .catch(err => {
                    dispatch(logout());                    
                })
            // const expirationDate = new Date(localStorage.getItem('expirationDate') * 1000)
            // if (expirationDate <= new Date()) {
            //     dispatch(logout())
            // } else {
            //     const userId = localStorage.getItem('userId')
            //     dispatch(authSuccess(token, userId))
            //     //dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000))
            // }
        }
    }
}

export const checkLogin = (query) => {
    return dispatch => {
        const params = new URLSearchParams(query);
        const contactId = params.get("contactId");
        const sharepointId = params.get("sharepointId");
        const adhId = params.get("adhId");
        const token = params.get("token");
        axios.get('https://api.alkor-groupe.com/WS_Auth_API/v1/Authenticate?contactId='+contactId+"&sharepointId="+sharepointId+"&adhId="+adhId, {headers: {"X-Alkor-API-Key":"10d56650-fc4a-4dc2-800d-481d072ae823"}}).then(response => {
            if (response.data === token) {
                Axios.get("client/"+adhId+"/authenticate").then(response => {
                    Axios.defaults.headers.authorization = 'Bearer ' + response.data.token;
                    localStorage.setItem('token', response.data.token)
                    localStorage.setItem('expirationDate', response.data.expireIn)
                    localStorage.setItem('userId', response.data.uid)
                    localStorage.setItem('login', response.data.email)
                    localStorage.setItem('role', response.data.role)
                    localStorage.setItem('isSupport', response.data.isSupport)
                    dispatch(authSuccess(response.data.email, response.data.token, response.data.id, response.data.role, response.data.isSupport));
                }).catch(error => {
                    console.error(error);
                })
            }
        }).catch(error => {
            console.error(error);
        })
    }
    
}

export const checkLoginByInvoiceToken = (query) => { 
    return dispatch => {
        const params = new URLSearchParams(query); 
        const invoiceToken = params.get("invoiceToken"); 
         
        Axios.get("client/"+invoiceToken+"/authenticateByInvoiceToken").then(response => {
            Axios.defaults.headers.authorization = 'Bearer ' + response.data.token;
            localStorage.setItem('token', response.data.token)
            localStorage.setItem('expirationDate', response.data.expireIn)
            localStorage.setItem('userId', response.data.uid)
            localStorage.setItem('login', response.data.email)
            localStorage.setItem('role', response.data.role)
            localStorage.setItem('isSupport', response.data.isSupport)
            dispatch(authSuccess(response.data.email, response.data.token, response.data.id, response.data.role, response.data.isSupport));
        }).catch(error => {
            console.error(error);
        }) 
    } 
}