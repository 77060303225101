import React, {useCallback} from 'react'
import './StatHeaderElement.css'

function StatHeaderElement(props) {
    const {value, icon, progress, item, percentage, containerBGColor, textColor, width} = props
    
        
    const formatDate = useCallback((date) => {
        if (date !== null && date > 0){
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
        return day + '/' + month + '/' + newDate.getFullYear()
        }else return null;    
    }, [])


    return (
        <div className="col-sm-6">
            <div className="card">
                <div className="card-header">
                    <span>{icon}</span> {item} 
                </div>
                
                <div className="card-body">
                    <div className="element__header">
                        <div className="elementHeader__value">
                            {value} 
                        </div>
                        <div className="elementHeader__icon">
                            <span>%</span>
                        </div>
                    </div>
                    <div className="element__progressBar">
                        <span style={{width: progress + '%'}} ></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatHeaderElement
