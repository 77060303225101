import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Bar, defaults , Chart, Doughnut} from 'react-chartjs-2'
import Axios from '../../../../axios-alkor';

function CAEnseigne({dateInterval}) {

    const [labels, setLabels] = useState([]);
    const [dataAmounts, setDataAmounts] = useState([]);
    const monthList = useMemo(() => ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"], [])
    const colorList = useMemo(() => ['rgba(255, 166, 24, 1)', "rgb(198, 65, 165)", "rgb(26, 150, 172)", "rgb(202, 188, 94)", "rgb(148, 70, 142)"])
    const chartRef = useRef();
    
    const randomRGBColor = () => {
        let x = Math.floor(Math.random() * 256);
        let y = Math.floor(Math.random() * 256);
        let z = Math.floor(Math.random() * 256);
        return "rgb(" + x + "," + y + "," + z + ")";
    }

    useEffect(() => {
        const date = new Date();
        const currentMonth = new Date().getMonth();
        const firstMonth = dateInterval?.startDate || new Date(date.getFullYear() - 1, currentMonth + 1).getTime();
        const lastMonth = dateInterval?.endDate || new Date().getTime();
        const Interval = firstMonth + ":" + lastMonth;
        Axios.get('/invoice//adminSpentByEnseigne?caMonthInterval='+Interval).then((response) => {
            let enseigneList = [];
            let amounts = [];
            let enseigneColor = [];
            const statData = response.data.data;
            statData.map(row => { 
                enseigneList.push(row.enseigne)
                amounts.push(+row.totalAmountBT)
            })
            enseigneList.map((enseigneKey, index) => {
                enseigneColor.push(colorList[index] || randomRGBColor())
            })
            setLabels(enseigneList);
            setDataAmounts([
                {
                    //label: 'CA',
                    yAxisID: 'A',
                    data: amounts,
                    borderColor: enseigneColor,
                    backgroundColor: enseigneColor,
                    barPercentage: 1   
                }
            ]);
            
        }).catch(error => {
            console.log(error)
        })

    }, [dateInterval])
    
    const data ={
        labels: labels,
        datasets: dataAmounts
    }

    const options = {
        maintainAspectRatio: false,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        },
        title: {
            display: false,
            text: 'ÉVOLUTION DU CA SUR LES 12 DERNIERS MOIS',
            fontSize: 20,
            fontColor: "white"
        },
        scales: {
            yAxes: [
                {
                    id: 'A',
                    position: 'left',
                    gridLines: {
                        color: "rgba(200,200,200,0.5)"
                    },
                    ticks: {
                        fontColor: 'black',
                        // min: 0,
                        // max: 6,
                        //stepSize: 200
                    }
                }
             ]
            ,
            xAxes: [{
                gridLines: {
                    color: "rgba(200,200,200,0.5)"
                },
                ticks: {
                    fontColor: "black",
                    fontSize: 14,
                },
                position: "relative"

            }]
        },
        legend: {
            display: false,
            position: "bottom",
            labels: {
                fontColor: 'black',
                fontSize: 15,
                padding: 10
            }
        }
    }

    
    return (
        <>
            <Bar ref={chartRef} data={data} options={options} />    
        </>
        
    )
}

export default CAEnseigne