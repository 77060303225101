import React, { useCallback, useEffect, useState } from 'react';
import Axios from '../../axios-alkor';
import { connect } from 'react-redux';
import Spinner from '../../component/UI/Spinner/Spinner';
import * as actions from '../../store/actions/index';
import './InvoiceChorus.css';
import TableBodyChorus from '../../component/InvoiceTable/TableBody/TableBodyChorus';
import TableActions from '../../component/InvoiceTable/TableActions/TableActions';
import TableHead from '../../component/InvoiceTable/TableHead/TableHead';
import TableFiltersChorus from '../../component/InvoiceTable/TableFilters/TableFiltersChorus';
import TablePagination from '../../component/InvoiceTable/TablePagination/TablePagination';
import TitleHelmet from '../../component/UI/TitleHelmet/TitleHelmet';
import ReplayModal from '../../component/InvoiceTable/TableBody/ReplayModal/ReplayModal'


function InvoiceChorus(props) {
    let { userRole, userIsSupport, token, loading, invoicesData, invoicesSafeData, count, currentPage, setInvoicePage, getInvoicesPage, setInvoiceFilters, filtersQuery, filtering, sortQuery, reverse, setSortQuery, setReverse, updateShowFilters, showFilters, selectedRows, setSelectedRows, getCompressedDoc, selectedAllRows, setSelectedAllRows, setDeselectedAllRows, amountBTTotal, amountATTotal, amountBTValues, amountATValues } = props;


    const columns = [
        { title: 'Err', field: 'error', width: "50px" },
        { title: 'Entite', field: 'clientCode', width: "120px" },
        { title: 'Nom client', field: 'clientName', width: "180px" },
        { title: 'Siret', field: 'clientSiret', width: "120px" },
        { title: 'Numéro', field: 'number', width: "80px" },
        { title: 'Montant HT', field: 'exclusiveTaxAmount', width: "150px" },
        { title: 'Montant TTC', field: 'inclusiveTaxAmount', width: "150px" },
        { title: 'Date de facture', field: 'issuingDate', width: "160px" },
        { title: 'Date d\'échéance', field: 'dueDate', width: "160px" },
        { title: 'Status', field: 'chorusStatusComplement', width: "100px" }
    ]
    const [showReplayModal, setShowReplayModal] = useState(false)
    const [uidToRetry, setUidToRetry] = useState()
    const [numberToRetry, setNumberToRetry] = useState()
    const [retrySuccess, setRetrySuccess] = useState()
    const [retryError, setRetryError] = useState()

    useEffect(() => {
        setDeselectedAllRows()
    }, [])

    useEffect(() => {
        let newFilters = {
            visualisation: false
        }
        updateShowFilters(false)
        setInvoiceFilters(newFilters)
        getInvoicesPage(currentPage, newFilters, "issuingDate", { issuingDate: true });
    }, [getInvoicesPage, token])

    const handleShowFilters = () => {
        updateShowFilters(!showFilters)
    }

    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0) {
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
            return day + '/' + month + '/' + newDate.getFullYear()
        } else return null;
    }, [])

    const handleChangePage = useCallback((newPage) => {
        //e.preventDefault();
        setInvoicePage(newPage);
        getInvoicesPage(newPage, filtersQuery, sortQuery, reverse);
    }, [filtersQuery, reverse, sortQuery]);

    const handleSort = useCallback((sort, newReverse) => {
        getInvoicesPage(currentPage, filtersQuery, sort, newReverse)
    }, [currentPage, filtersQuery, sortQuery, reverse])

    const handleApplyFiltering = useCallback((filters) => {
        getInvoicesPage(1, filters, sortQuery, reverse);
    }, [currentPage, filtersQuery, reverse, sortQuery]);

    const handleUpdateViewed = useCallback((e, id) => {
        if (userRole && userRole === "CLIENT_USER") {
            // Axios.put("/invoice/"+id+"/viewed",{
            Axios.put("/invoice/" + id + "/firstAccessDate", {
                firstAccessDate: new Date().getTime()
            }).then(response => getInvoicesPage(currentPage, filtersQuery, sortQuery, reverse))
                .catch(err => console.log(err))
        }

    }, [])


    const handleUpdateStep = useCallback((uid) => {
        if (userRole && userRole === "ADMIN") {
            Axios.put("/invoice/" + uid + "/retryStep")
                .then(response => {
                    setRetrySuccess(true)
                    getInvoicesPage(currentPage, filtersQuery, sortQuery, reverse)
                })
                .catch(err => {
                    setRetryError(err?.response?.data?.message)
                })
        }
    }, [currentPage, filtersQuery, reverse, sortQuery])

    /*const handleUpdateLitige = (id, newLitige) => {
        Axios.put("/invoice/"+id+"/litige", {
            litigation : (!newLitige || newLitige === "0") ? 1 : 0
        }).then(response => getInvoicesPage(currentPage, filtersQuery, sortQuery, reverse))
        .catch(err => console.log(err))
    }
    */

    const handleReset = useCallback(() => {
        setSortQuery("issuingDate")
        setReverse({ issuingDate: true })
        setInvoiceFilters(null);
        setInvoicePage(1);
        getInvoicesPage(1, null, "issuingDate", { issuingDate: true });
    }, [])

    const replayKoInvoiceHandler = useCallback((uid, number) => {
        if (userRole && userRole === "ADMIN") {
            setRetrySuccess(null)
            setRetryError(null)
            setUidToRetry(uid);
            setNumberToRetry(number);
            setShowReplayModal(true);
        }
    }, [])

    let tBody = null;
    let tSpinner = loading ? (
        <div className="invoiceTable__spinner">
            <Spinner />
        </div>
    ) : <h2 style={{ fontSize: "1rem", color: "#888888", padding: "0px 0px 0px 48px" }}>Aucun document à afficher</h2>;

    if (!loading && invoicesData?.length > 0) {
        tSpinner = null;
        tBody = (
            <TableBodyChorus
                columns={columns}
                data={invoicesData}
                safeData={invoicesSafeData}
                formatDate={formatDate}
                setViewed={handleUpdateViewed}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                showRetry={userIsSupport}
                replayKoInvoiceHandler={replayKoInvoiceHandler}
            />
        )
    }

    return (
        <div className="invoice">
            <TitleHelmet title="Portail facture Alkor | Documents " />
            <div className="invoiceTable">
                <div className="invoiceHeader">
                    <TableActions
                        filterState={showFilters}
                        showFilters={handleShowFilters}
                        filtering={filtering}
                        resetData={handleReset}
                        formatDate={formatDate}
                        getCompressedDoc={getCompressedDoc}
                        selectedRows={selectedRows}
                        selectedAllRows={selectedAllRows}
                        setSelectedAllRows={setSelectedAllRows}
                        showManualAck={userIsSupport} />
                    <TablePagination currentPage={currentPage} pageChange={handleChangePage} totalElement={count} perPage={100} />
                </div>
                <table className="table">
                    <TableHead
                        columns={columns}
                        sortQuery={sortQuery}
                        reverse={reverse}
                        setSortQuery={setSortQuery}
                        setReverse={setReverse}
                        getData={handleSort}
                        selectedRows={selectedRows}
                        selectedAllRows={selectedAllRows}
                        setSelectedAllRows={setSelectedAllRows} />
                    <tbody>
                        {showFilters && <TableFiltersChorus
                            show={showFilters}
                            formatDate={formatDate}
                            filters={filtersQuery}
                            filtersChange={setInvoiceFilters}
                            applyFilters={handleApplyFiltering}
                            amountBTTotal={amountBTTotal}
                            amountATTotal={amountATTotal}
                            amountBTValues={amountBTValues}
                            amountATValues={amountATValues}
                        />}
                        {tBody}
                    </tbody>
                </table>
                {tSpinner}
                {showReplayModal ? (
                    <ReplayModal
                        modalClosed={() => setShowReplayModal(false)}
                        show={showReplayModal}
                        uidToRetry={uidToRetry}
                        numberToRetry={numberToRetry}
                        handleUpdateStep={handleUpdateStep}
                        retrySuccess={retrySuccess}
                        retryError={retryError}
                    />) : null}

            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    isAuth: state.auth.token !== null,
    token: state.auth.token,
    loading: state.invoice.loading,
    invoicesData: state.invoice.data,
    invoicesSafeData: state.invoice.safeData,
    count: state.invoice.count,
    currentPage: state.invoice.currentChorusPage,
    filtersQuery: state.invoice.filtersQuery,
    filtering: state.invoice.filtering,
    sortQuery: state.invoice.sortQuery,
    reverse: state.invoice.reverse,
    showFilters: state.invoice.showFilters,
    selectedRows: state.invoice.selectedRows,
    selectedAllRows: state.invoice.selectedAllRows,
    userRole: state.auth.role,
    userIsSupport: state.auth.isSupport,
    amountBTTotal: state.invoice.amountBTTotal,
    amountATTotal: state.invoice.amountATTotal,
    amountBTValues: state.invoice.amountBTValues,
    amountATValues: state.invoice.amountATValues
})
const mapDispatchToProps = dispatch => ({
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    getInvoices: () => dispatch(actions.invoiceGetAllChorus()),
    getInvoicesPage: (page, filters, sort, reverse) => dispatch(actions.invoiceChorusGetPage(page, filters, sort, reverse)),
    setInvoicePage: (page) => dispatch(actions.invoiceChorusSetPage(page)),
    setInvoiceFilters: (filters) => dispatch(actions.invoiceSetFiltersQuery(filters)),
    setSortQuery: (sortQ) => dispatch(actions.invoiceSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.invoiceSetReverseSort(reverseS)),
    updateShowFilters: (show) => dispatch(actions.invoiceUpdateShowFilters(show)),
    getDocumentFile: (uid, type) => dispatch(actions.invoiceGetDocumentFile(uid, type)),
    setSelectedRows: (rowId, selceted) => dispatch(actions.invoiceSelectRow(rowId, selceted)),
    setSelectedAllRows: (selectedAll) => dispatch(actions.invoiceSelectAllRows(selectedAll)),
    setDeselectedAllRows: () => dispatch(actions.invoiceDeselectAllRows()),
    getCompressedDoc: (selection) => dispatch(actions.invoiceGetCompressedDocument(selection))
})
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceChorus)
