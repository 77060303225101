import React from 'react'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

function TableHead(props) {
    const {columns, columnClickHandler, reverse} = props;
    
    return (
        <thead>
            <tr>
                {columns.map(column => (
                    <th key={column.field} scope="col" id={column.field} style={{borderTop: "none", width: column?.width}}  className="columnHead" onClick={() => columnClickHandler(column.field, reverse)}>
                        <div className="columnHead__container">
                            <div className="columnHead__title">
                                {column.title}
                            </div>
                            <div className="columnHead__sort">
                                <ArrowUpwardIcon style={{fontSize: 20, display: reverse[column.field] == true ? 'block' : 'none' }} />
                                <ArrowDownwardIcon style={{fontSize: 20, display: reverse[column.field] == false ? 'block' : 'none' }}/>
                                
                            </div>
                        </div>    
                    </th>
                ))}
                <th key="visualiser" scope="col" id="visualiser" style={{borderTop: "none", width:"100px"}}  className="columnHead">
                    <div className="columnHead__container">
                        <div className="columnHead__title">
                            Visualisation
                        </div>
                        <div className="columnHead__sort">
                        </div>
                    </div>    
                </th>
            </tr>
        </thead>
    )
}

export default TableHead
