import React, { useEffect, useState } from 'react'
import StatBody from '../../component/HomeStat/StatBody/StatBody';
import StatHeader from '../../component/HomeStat/StatHeader/StatHeader';
import ComptaStatHeader from '../../component/HomeStat/StatHeader/ComptaStatHeader';
import ComptaStatBody from '../../component/HomeStat/StatBody/ComptaStatBody';
import TitleHelmet from '../../component/UI/TitleHelmet/TitleHelmet';
import './Home.css';

function Home(props) {
    const userRole = localStorage.getItem('role');

    return (
        <div className="home">
            <TitleHelmet title="Portail facture Alkor | Accueil" />
            <div className="tableActions">
                <div className="tableActions__title">
                    <h2 style={{color:"#666666"}}>Tableau de bord </h2>
                </div>
            </div><hr width="100%" style={{ margin: "0"}}/>
            {/* <div className="tableActions"> 
                <div className="tableActions__title" style={{marginTop: "24.5px"}}>
                    <h2 style={{color:"#666666"}}>Tableau du bord</h2>
                </div>
            </div>
            */}
            {
                userRole === "COMPTA" || userRole === "ADMIN" ? (
                    <>
                        <ComptaStatHeader />
                        <ComptaStatBody />
                    </>
                ) : (
                    <>
                        <StatHeader />
                        <StatBody />
                    </>
                )
                
            }
        </div>
    )
}

export default Home
