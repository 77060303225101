import React, { useEffect, useState } from 'react'
import './UserVisualisation.css';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import UserNavigation from '../../component/UserNavigation/UserNavigation';
import UserVisHome from './UserVisHome/UserVisHome';
import UserVisInvoice from './UserVisInvoice/UserVisInvoice';

function UserVisualisation() {
    const history = useHistory();
    const location = useLocation();

    const [showUserNav, setShowUserNav] = useState(false)
    return (
        <div className="userVisualisation">
            
            {/* <div className="userVisualisation__Nav">
                <UserNavigation 
                    show={showUserNav}
                    toggleShow={setShowUserNav}
                />
            </div> */}
            
            <div className="userVisualisation__body">
                <div className="container-fluid">
                    {
                        location.pathname === "/userVisualisation" ? <UserVisHome /> 
                            : location.pathname === "/userVisualisation/invoice" ? <UserVisInvoice /> : null
                    }
                </div>
            
            </div>
        </div>
        
    )
}

export default UserVisualisation
