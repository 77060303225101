import * as actionTypes from './actionTypes';
import Axios from '../../axios-alkor';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';

export const invoiceStart = () => {
    return {
        type: actionTypes.INVOICE_START
    }
}

export const invoiceSetPage = (page) => ({
    type: actionTypes.INVOICE_SET_PAGE,
    currentPage: page
})

export const invoiceChorusSetPage = (page) => ({
    type: actionTypes.INVOICE_CHORUS_SET_PAGE,
    currentChorusPage: page
})

export const invoiceSuccess = (data, count, amountBTTotal, amountATTotal, amountBTValues, amountATValues) => {
    return {
        type: actionTypes.INVOICE_SUCCESS,
        data: data,
        amountBTTotal: amountBTTotal,
        amountATTotal: amountATTotal,
        amountBTValues: amountBTValues,
        amountATValues: amountATValues,
        count: count
    }
}

export const invoiceFail = (error) => {
    return {
        type: actionTypes.INVOICE_FAIL,
        error: error
    }
}

export const invoiceGetAll = () => {
    console.log("invoiceGetAll");
    return dispatch => {
        dispatch(invoiceStart());
        Axios.get('/invoice/')
            .then(response => {
                dispatch(invoiceSuccess(response.data.result))
            })
            .catch(err => {
                dispatch(invoiceFail(err.response.data.message))
            })
    }
}
export const invoiceGetAllChorus = () => {
    return dispatch => {
        dispatch(invoiceStart());
        Axios.get('/invoice//allChorus')
            .then(response => {
                dispatch(invoiceSuccess(response.data.result))
            })
            .catch(err => {
                dispatch(invoiceFail(err.response.data.message))
            })
    }
}
export const invoiceGetPage = (page, filters, sort, reverse) => {
    let newFilters
    if(filters){
        for (const key in filters) {
            if(filters[key])
                newFilters = {
                    ...newFilters,
                    [key]: filters[key]
                }
        }
    }
    if(sort){
        newFilters = {
            ...newFilters,
            orderBy: sort
        }
    }
    if(reverse){
        newFilters = {
            ...newFilters,
            reverse: reverse[sort]
        }
    }
    return dispatch => {
        dispatch(invoiceStart());
        Axios.get('/invoice?page='+page, {
            params: {...newFilters}
        }).then(response => {
                dispatch(invoiceSuccess(response.data.result,
                     response.status === 204 ? 0 : response.data.count,
                     response.data.amountBTTotal,
                     response.data.amountATTotal,
                     response.data.amountBTValues,
                     response.data.amountATValues))
            }).catch(err => {
                dispatch(invoiceFail(err.response.data.message))
            })
    }
}
export const invoiceChorusGetPage = (page, filters, sort, reverse) => {
    let newFilters
    if(filters){
        for (const key in filters) {
            if(filters[key])
                newFilters = {
                    ...newFilters,
                    [key]: filters[key]
                }
        }
    }
    if(sort){
        newFilters = {
            ...newFilters,
            orderBy: sort
        }
    }
    if(reverse){
        newFilters = {
            ...newFilters,
            reverse: reverse[sort]
        }
    }
    return dispatch => {
        dispatch(invoiceStart());
        Axios.get('/invoice//allChorus?page='+page, {
            params: {...newFilters}
        }).then(response => {
                dispatch(invoiceSuccess(response.data.result,
                    response.status === 204 ? 0 : response.data.count,
                    response.data.amountBTTotal,
                    response.data.amountATTotal,
                    response.data.amountBTValues,
                    response.data.amountATValues))
            }).catch(err => {
                dispatch(invoiceFail(err.response.data.message))
            })
    }
}

export const invoiceSetFiltersQuery = (filtersQuery) => {
    return {
        type: actionTypes.INVOICE_SET_FILTERSQUERY,
        filters: filtersQuery,
        filtering: invoiceUpdateFiltering(filtersQuery)
    }
}

export const invoiceUpdateFiltering = (filtersQuery) => {
    let filter = false;
    if(filtersQuery){
        for(const key in filtersQuery){
            if(filtersQuery[key]){
                filter = true
            }
        }
    }
    return filter
}

export const invoiceUpdateShowFilters = (show) => {
    return {
        type: actionTypes.INVOICE_UPDATE_SHOWFILTERS,
        showFilters: show
    }
}

export const invoiceSetSortQuery = (sortQuery) => {
    return {
        type: actionTypes.INVOICE_SET_SORTQUERY,
        sortQuery: sortQuery
    }
}

export const invoiceSetReverseSort = (reverse) => {
    return {
        type: actionTypes.INVOICE_SET_REVERSESORT,
        reverse: reverse
    }
}

export const invoiceGetDocSuccess = (fileB64) => {
    return {
        type: actionTypes.INVOICE_GET_DOCUMENT_FILE,
        file: fileB64
    }
} 

export const invoiceGetDocumentFile = (uid, type) => {
    console.log(type)
    return dispatch => {
        Axios.get('/invoice/'+uid+'/documentFile?type='+type, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
        .then(response => {
            if(response.status === 200){
                let blob = new Blob([response.data], { type: type ==="PDF" ? 'application/pdf' : 'application/xml' })
                            let filename =""
                            const disposition = response.request.getResponseHeader('Content-Disposition')
                            console.log(disposition);
                            if (disposition && disposition.indexOf('attachment') !== -1) {
                                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                                var matches = filenameRegex.exec(disposition);
                                if (matches!== null && matches[1]) { 
                                filename = matches[1].replace(/['"]/g, '');
                                }
                            }
                            const downloadUrl = URL.createObjectURL(blob)
                            let a = document.createElement("a"); 
                            a.href = downloadUrl;
                            a.download = filename;
                            document.body.appendChild(a);
                            a.click();
            }else {
                // Create an instance of Notyf
                const notyf = new Notyf();

                // Display an error notification 
                notyf.error('Document non trouvée');
            }
            
        })
        .catch(err => {
            //dispatch(invoiceFail(err.response.data.message))
        })
    }
    
}



export const invoiceSelectRow = (rowId, selected) => {
    return {
        type: actionTypes.INVOICE_SELECT_ROW,
        rowId: rowId,
        selected: selected
    }
}

export const invoiceSelectAllRows = (selectedAll) => {
    return {
        type: actionTypes.INVOICE_SELECT_ALL_ROWS,
        selectedAll: selectedAll
    }
}

export const invoiceDeselectAllRows = () => {
    return {
        type: actionTypes.INVOICE_DESELECT_ALL_ROWS        
    }
}

export const invoiceGetCompressedDocument = (selectedIds) => {
    return dispatch => {
        Axios.get('/invoice//compressedDocument?selection='+selectedIds, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then((response) => {
            let blob = new Blob([response.data], { type: 'application/zip' })

            const downloadUrl = URL.createObjectURL(blob)
            let a = document.createElement("a"); 
            a.href = downloadUrl;
            a.download = "compressed.zip";
            document.body.appendChild(a);
            a.click();
        })
    }
}



