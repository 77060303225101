//_______Authentication Actions_______
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';


//________Invoice Actions__________
export const INVOICE_START = 'INVOICE_START';
export const INVOICE_SUCCESS = 'INVOICE_SUCCESS';
export const INVOICE_FAIL = 'INVOICE_FAIL';
export const INVOICE_GET_ALL = 'INVOICE_GET_ALL';
export const INVOICE_SET_PAGE = 'INVOICE_SET_PAGE';
export const INVOICE_CHORUS_SET_PAGE = 'INVOICE_CHORUS_SET_PAGE';
export const INVOICE_SET_FILTERSQUERY = 'INVOICE_SET_FILTERSQUERY';
export const INVOICE_UPDATE_FILTERING = 'INVOICE_UPDATE_FILTERING';
export const INVOICE_SET_SORTQUERY = 'INVOICE_SET_SORTQUERY';
export const INVOICE_SET_REVERSESORT = 'INVOICE_SET_REVERSESORT';
export const INVOICE_UPDATE_SHOWFILTERS = 'INVOICE_UPDATE_SHOW_FILTERS';
export const INVOICE_GET_DOCUMENT_FILE = 'INVOICE_GET_DOCUMENT_FILE';
export const INVOICE_SELECT_ROW = 'INVOICE_SELECT_ROW';
export const INVOICE_SELECT_ALL_ROWS = 'INVOICE_SELECT_ALL_ROWS';
export const INVOICE_DESELECT_ALL_ROWS = 'INVOICE_DESELECT_ALL_ROWS';
export const INVOICE_GET_COMPRESSED_DOCUMENT = 'INVOICE_GET_COMPRESSED_DOCUMENT';

//____________Clients Actions_________
export const CLIENTS_START = 'CLIENTS_START';
export const CLIENTS_SUCCESS = 'CLIENTS_SUCCESS';
export const CLIENTS_FAIL = 'CLIENTS_FAIL';
export const CLIENTS_GET_ALL = 'CLIENTS_GET_ALL';
export const CLIENTS_SET_PAGE = 'CLIENTS_SET_PAGE';
export const CLIENTS_SET_FILTERSQUERY = 'CLIENTS_SET_FILTERSQUERY';
export const CLIENTS_UPDATE_FILTERING = 'CLIENTS_UPDATE_FILTERING';
export const CLIENTS_SET_SORTQUERY = 'CLIENTS_SET_SORTQUERY';
export const CLIENTS_SET_REVERSESORT = 'CLIENTS_SET_REVERSESORT';
export const CLIENTS_UPDATE_SHOWFILTERS = 'CLIENTS_UPDATE_SHOW_FILTERS';

//___________Logs Actions_____________
export const LOGS_START = 'LOGS_START';
export const LOGS_SUCCESS = 'LOGS_SUCCESS';
export const LOGS_FAIL = 'LOGS_FAIL';
export const LOGS_SET_PAGE = 'LOGS_SET_PAGE';
export const LOGS_SET_FILTERSQUERY = 'LOGS_SET_FILTERSQUERY';
export const LOGS_UPDATE_FILTERING = 'LOGS_UPDATE_FILTERING';
export const LOGS_SET_SORTQUERY = 'LOGS_SET_SORTQUERY';
export const LOGS_SET_REVERSESORT = 'LOGS_SET_REVERSESORT';
export const LOGS_UPDATE_SHOWFILTERS = 'LOGS_UPDATE_SHOW_FILTERS';

