import React, { useCallback, useEffect } from 'react';
import Axios from '../../../axios-alkor';
import { connect } from 'react-redux';
import Spinner from '../../../component/UI/Spinner/Spinner';
import * as actions from '../../../store/actions/index';
import './UserVisInvoice.css';
import TableBody from '../../../component/InvoiceTable/TableBody/TableBody';
import TableActions from '../../../component/InvoiceTable/TableActions/TableActions';
import TableHead from '../../../component/InvoiceTable/TableHead/TableHead';
import TableFilters from '../../../component/InvoiceTable/TableFilters/TableFilters';
import TablePagination from '../../../component/InvoiceTable/TablePagination/TablePagination';

function UserVisInvoice(props) {
    let { userRole, loading, invoicesData, count, currentPage, setInvoicePage, getInvoicesPage, setInvoiceFilters, filtersQuery, filtering, sortQuery, reverse, setSortQuery, setReverse, updateShowFilters, showFilters, selectedRows, setSelectedRows, getCompressedDoc, selectedAllRows, setSelectedAllRows, amountBTTotal, amountATTotal, amountBTValues, amountATValues } = props;

    const columns = [
        { title: 'Lu', field: 'firstAccessDate', width: "50px" },
        { title: 'Client', field: 'clientName' },
        { title: 'Enseigne', field: 'clientEnseigne' },
        { title: 'Code client', field: 'clientCode', width: "120px" },
        { title: 'Numéro', field: 'number' },
        { title: 'Type', field: 'invoiceType', width: "100px" },
        //{title: 'Etat', field: 'status', width: "150px"},
        { title: 'Montant HT', field: 'exclusiveTaxAmount' },
        { title: 'Montant TTC', field: 'inclusiveTaxAmount' },
        { title: 'Date de facture', field: 'issuingDate' },
        { title: 'Date d\'échéance', field: 'dueDate' },
        //{title: 'Litige', field: 'litigation'} 
    ]

    useEffect(() => {
        let newFilters = {
            visualisation: true
        }
        setSortQuery("issuingDate")
        setReverse({ issuingDate: true })
        setInvoiceFilters(newFilters)
        setSelectedAllRows(false)
        getInvoicesPage(currentPage, newFilters, "issuingDate", { issuingDate: true });
        return () => {
            setSortQuery("clientName")
            setReverse({ clientName: false })
            setInvoiceFilters(null);
            setSelectedAllRows(false)
            setInvoicePage(1);
        }
    }, [])

    const handleShowFilters = () => {
        updateShowFilters(!showFilters)
    }

    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0) {
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
            return day + '/' + month + '/' + newDate.getFullYear()
        } else return null;
    }, [])

    const handleChangePage = useCallback((newPage) => {
        setInvoicePage(newPage);
        getInvoicesPage(newPage, filtersQuery, sortQuery, reverse);
    }, [filtersQuery, reverse, sortQuery]);

    const handleSort = useCallback((sort, newReverse) => {
        getInvoicesPage(currentPage, filtersQuery, sort, newReverse)
    }, [currentPage, filtersQuery, sortQuery, reverse])

    const handleApplyFiltering = useCallback((filters) => {
        getInvoicesPage(1, filters, sortQuery, reverse);
    }, [currentPage, filtersQuery, reverse, sortQuery]);

    const handleUpdateViewed = useCallback((e, id) => {
        if (userRole && userRole === "CLIENT_USER") {
            Axios.put("/invoice/" + id + "/firstAccessDate", {
                firstAccessDate: new Date().getTime()
            }).then(response => getInvoicesPage(currentPage, filtersQuery, sortQuery, reverse))
                .catch(err => console.log(err))
        }
    }, [])

    const handleReset = useCallback(() => {
        setInvoiceFilters({
            visualisation: true
        });
        setSortQuery("issuingDate")
        setReverse({ issuingDate: true })
        setInvoicePage(1);
        getInvoicesPage(1, { visualisation: true }, "issuingDate", { issuingDate: true });
    }, [])

    let tBody = null;
    let tSpinner = loading ? (
        <div className="invoiceTable__spinner">
            <Spinner />
        </div>
    ) : <h2 style={{ fontSize: "1rem", color: "#888888", padding: "0px 0px 0px 48px" }}>Aucun document à afficher</h2>;

    if (!loading && invoicesData?.length > 0) {
        tSpinner = null;
        tBody = (
            <TableBody
                columns={columns}
                data={invoicesData}
                formatDate={formatDate}
                setViewed={handleUpdateViewed}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                showErrors={false}
                showRetry={false} />
        )
    }

    return (
        <div className="invoice">
            <div className="invoiceTable">
                <div className="invoiceHeader">
                    <TableActions
                        filterState={showFilters}
                        showFilters={handleShowFilters}
                        filtering={filtering}
                        resetData={handleReset}
                        formatDate={formatDate}
                        getCompressedDoc={getCompressedDoc}
                        selectedRows={selectedRows}
                        selectedAllRows={selectedAllRows}
                        setSelectedAllRows={setSelectedAllRows}
                        showManualAck={false} />
                    <TablePagination currentPage={currentPage} pageChange={handleChangePage} totalElement={count} perPage={100} />
                </div>
                <table className="table">
                    <TableHead
                        columns={columns}
                        sortQuery={sortQuery}
                        reverse={reverse}
                        setSortQuery={setSortQuery}
                        setReverse={setReverse}
                        getData={handleSort}
                        selectedRows={selectedRows}
                        selectedAllRows={selectedAllRows}
                        setSelectedAllRows={setSelectedAllRows} />
                    <tbody>
                        {showFilters && <TableFilters
                            showErrors={false}
                            show={showFilters}
                            formatDate={formatDate}
                            filters={filtersQuery}
                            filtersChange={setInvoiceFilters}
                            applyFilters={handleApplyFiltering}
                            amountBTTotal={amountBTTotal}
                            amountATTotal={amountATTotal}
                            amountBTValues={amountBTValues}
                            amountATValues={amountATValues}
                        />}
                        {tBody}
                    </tbody>
                </table>
                {tSpinner}


            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    loading: state.invoice.loading,
    invoicesData: state.invoice.data,
    count: state.invoice.count,
    currentPage: state.invoice.currentPage,
    filtersQuery: state.invoice.filtersQuery,
    filtering: state.invoice.filtering,
    sortQuery: state.invoice.sortQuery,
    reverse: state.invoice.reverse,
    showFilters: state.invoice.showFilters,
    selectedRows: state.invoice.selectedRows,
    selectedAllRows: state.invoice.selectedAllRows,
    userRole: state.auth.role,
    amountBTTotal: state.invoice.amountBTTotal,
    amountATTotal: state.invoice.amountATTotal,
    amountBTValues: state.invoice.amountBTValues,
    amountATValues: state.invoice.amountATValues
})
const mapDispatchToProps = dispatch => ({
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    getInvoices: () => dispatch(actions.invoiceGetAll()),
    getInvoicesPage: (page, filters, sort, reverse) => dispatch(actions.invoiceGetPage(page, filters, sort, reverse)),
    setInvoicePage: (page) => dispatch(actions.invoiceSetPage(page)),
    setInvoiceFilters: (filters) => dispatch(actions.invoiceSetFiltersQuery(filters)),
    setSortQuery: (sortQ) => dispatch(actions.invoiceSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.invoiceSetReverseSort(reverseS)),
    updateShowFilters: (show) => dispatch(actions.invoiceUpdateShowFilters(show)),
    getDocumentFile: (uid) => dispatch(actions.invoiceGetDocumentFile(uid)),
    setSelectedRows: (rowId, selceted) => dispatch(actions.invoiceSelectRow(rowId, selceted)),
    setSelectedAllRows: (selectedAll) => dispatch(actions.invoiceSelectAllRows(selectedAll)),
    getCompressedDoc: (selection) => dispatch(actions.invoiceGetCompressedDocument(selection))
})
export default connect(mapStateToProps, mapDispatchToProps)(UserVisInvoice)
