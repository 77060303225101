import React from 'react';
import { useState } from 'react';
import './MailLngForm.css';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Backdrop from '../../UI/Backdrop/Backdrop'
import MailPreview from '../MailPreview/MailPreview'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useTranslation } from 'react-i18next';

function MailLngForm({inputName, inputChange, tsettingsMail, tsettings, inputValue, logo, MDILogo}) {

    const [mailLang, setMailLang] = useState("adh")
    const {t} = useTranslation();

    const handleLangMail = (lang) => {
        console.log(lang)
        setMailLang(lang)
    }
    
     
    return (
        <>
            <div className="menuContainer">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" >
                        <a className="nav-link active" id="home-tab" data-toggle="tab" href={`#${inputName}ADH`} role="tab" aria-controls={`${inputName}ADH`} aria-selected="true" onClick={() => handleLangMail('adh')}>CLI</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="contact-tab" data-toggle="tab" href={`#${inputName}ADHSU`} role="tab" aria-controls={`${inputName}ADHSU`} aria-selected="false" onClick={() => handleLangMail('adhSu')} >FOU</a>
                    </li> 
                    <li className="nav-item">
                        <a className="nav-link" id="profile-tab" data-toggle="tab" href={`#${inputName}MDI`} role="tab" aria-controls={`${inputName}MDI`} aria-selected="false" onClick={() => handleLangMail('mdi')} >MDI</a>
                    </li>
                    
                </ul>
                {/* <VisibilityIcon className="mailPreview" onClick={() => setShowMailPreview(!showMailPreview)} /> */}
                {/* <Backdrop className="mailPreview" clicked={() => setShowMailPreview(!showMailPreview)}  /> */}
                {
                    (inputName=="block_Token" ||  inputName=="block_TokenSecondary"  || inputName=="block_Attachement" || inputName=="block_crateAccount" || inputName=="block_urlPortal" ) ? 
                        <div class="previewDropDownMenu">
                            <ArrowDropDownIcon className="previewIcon" />
                            <div className="previewDropDownMenuContent">
                                <div className="admInfoCard__headerDropDownAction cardInfo modalBody">  
                                    {
                                        inputName=="block_Token" ? 
                                            <>
                                                <p className="formMailCustomer" data-toggle="modal" data-target="#blockToken_clientConnected">Client ou parent avec compte existant (token)</p>
                                                <p className="formMailCustomer" data-toggle="modal" data-target="#blockToken_clientNotConnected">Client ou parent sans compte (token)</p>
                                            </>
                                        :   inputName=="block_TokenSecondary" ? 
                                            <>
                                                <p className="formMailCustomer" data-toggle="modal" data-target="#blockTokenSecondary_clientConnected">Personne en copie avec compte existant (token)</p>
                                                <p className="formMailCustomer" data-toggle="modal" data-target="#blockTokenSecondary_clientNotConnected">Personne en copie sans compte (token)</p>
                                            </>
                                        : inputName=="block_Attachement" ? 
                                            <>
                                                <p className="formMailCustomer" data-toggle="modal" data-target="#blockAttachement">Client avec attachement</p>
                                            </>
                                        : inputName=="block_crateAccount" ? 
                                            <>
                                                <p className="formMailCustomer" data-toggle="modal" data-target="#blockAccount_clientNotC">Client ou parent sans compte (token)</p>
                                                <p className="formMailCustomer" data-toggle="modal" data-target="#blockAccount_clientNotCSecondary">Personne en copie sans compte (token)</p>
                                            </>
                                        : inputName=="block_urlPortal" ? 
                                            <>
                                                <p className="formMailCustomer" data-toggle="modal" data-target="#blockPortal_clientC">Client ou parent avec compte existant (token)</p>
                                                <p className="formMailCustomer" data-toggle="modal" data-target="#blockPortal_clientNotC">Client ou parent sans compte (token)</p>
                                                <p className="formMailCustomer" data-toggle="modal" data-target="#blockPortal_clientCSecondary">Personne en copie avec compte existant (token)</p>
                                                <p className="formMailCustomer" data-toggle="modal" data-target="#blockPortal_clientNotCSecondary">Personne en copie sans compte (token)</p>
                                            </>
                                        : null
                                    }

                                </div>
                                
                            </div>

                        </div>
                        : null        
                }
                       
            </div>

            {
                inputName=="block_Token" ? 
                <>
                    <MailPreview dataTarget="blockToken_clientConnected" targetName="blockToken" targetValue={tsettingsMail} targetCommonValue={tsettings} mailLang={mailLang} logo={logo} MDILogo={MDILogo}/>
                    <MailPreview dataTarget="blockToken_clientNotConnected" targetName="blockTokenNotC" targetValue={tsettingsMail} targetCommonValue={tsettings} mailLang={mailLang} logo={logo} MDILogo={MDILogo}/>
                </>
                : inputName=="block_TokenSecondary" ? 
                <>
                    <MailPreview dataTarget="blockTokenSecondary_clientConnected" targetName="blockTokenSecondary" targetValue={tsettingsMail} targetCommonValue={tsettings} mailLang={mailLang} logo={logo} MDILogo={MDILogo}/> 
                    <MailPreview dataTarget="blockTokenSecondary_clientNotConnected" targetName="blockTokenSecondaryNotC" targetValue={tsettingsMail} targetCommonValue={tsettings} mailLang={mailLang} logo={logo} MDILogo={MDILogo}/>
                </>
                : inputName=="block_Attachement" ? 
                <MailPreview dataTarget="blockAttachement" targetName="blockAttachement" targetValue={tsettingsMail} targetCommonValue={tsettings} mailLang={mailLang} logo={logo} MDILogo={MDILogo}/>                                                 
                : inputName=="block_crateAccount" ? 
                <>
                    <MailPreview dataTarget="blockAccount_clientNotC" targetName="blockAccountNotC" targetValue={tsettingsMail} targetCommonValue={tsettings} mailLang={mailLang} logo={logo} MDILogo={MDILogo}/>
                    <MailPreview dataTarget="blockAccount_clientNotCSecondary" targetName="blockAccountNotCSecondary" targetValue={tsettingsMail} targetCommonValue={tsettings} mailLang={mailLang} logo={logo} MDILogo={MDILogo}/> 
                </>
                : inputName=="block_urlPortal" ? 
                <>
                    <MailPreview dataTarget="blockPortal_clientC" targetName="blockPortalC" targetValue={tsettingsMail} targetCommonValue={tsettings} mailLang={mailLang} logo={logo} MDILogo={MDILogo}/> 
                    <MailPreview dataTarget="blockPortal_clientNotC" targetName="blockPortalNotC" targetValue={tsettingsMail} targetCommonValue={tsettings} mailLang={mailLang} logo={logo} MDILogo={MDILogo}/> 
                    <MailPreview dataTarget="blockPortal_clientCSecondary" targetName="blockPortalCSecondary" targetValue={tsettingsMail} targetCommonValue={tsettings} mailLang={mailLang} logo={logo} MDILogo={MDILogo}/>
                    <MailPreview dataTarget="blockPortal_clientNotCSecondary" targetName="blockPortalNotCSecondary" targetValue={tsettingsMail} targetCommonValue={tsettings} mailLang={mailLang} logo={logo} MDILogo={MDILogo}  /> 
                </>
                : null
            }

            <div className="tab-content mt-0" id="myTabContent">
                <div className="tab-pane fade show active" id={`${inputName}ADH`} role="tabpanel" aria-labelledby="home-tab">
                    <textarea 
                        className="emailSettings__formInput" 
                        rows="5" cols="90" title="Utilisateur principal" maxLength="600"
                        name={inputName} 
                        onChange={(e) => inputChange(e, "adhValue")} 
                        value={inputValue?.adhValue || ''} required  />
                </div>
                <div className="tab-pane fade" id={`${inputName}ADHSU`} role="tabpanel" aria-labelledby="contact-tab">
                    <textarea 
                        className="emailSettings__formInput" 
                        rows="5" cols="90" title="Utilisateur principal" maxLength="600"
                        name={inputName} 
                        onChange={(e) => inputChange(e, "adhSuValue")} 
                        value={inputValue?.adhSuValue || ''} required  />
                </div>
                <div className="tab-pane fade" id={`${inputName}MDI`} role="tabpanel" aria-labelledby="profile-tab">
                    <textarea 
                        className="emailSettings__formInput" 
                        rows="5" cols="90" title="Utilisateur principal" maxLength="600"
                        name={inputName}  
                        onChange={(e) => inputChange(e, "mdiValue")} 
                        value={inputValue?.mdiValue || ''} required  />
                </div>
                
            </div>
        </>
    )
}

export default MailLngForm
