import React, { useState } from 'react'
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from 'react-date-range';
import { OverrideStaticRanges} from './RangesOverride'
import "./DateRange.css"
import Modal from '../../../UI/Modal/Modal';
import { fr } from 'date-fns/locale';

function DateRange(props) {

    const newStaticRanges = OverrideStaticRanges;

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const selectionRange = {
        startDate: startDate,
        endDate: endDate,
        key: props.field,
      };

    function adjustTime(time, type) {
        if(type=='start')
            time.setHours(0,0,0,0);
        else
            time.setHours(23,59,59,999);
        return time;
    }

    function handleSelect(ranges) {
        let selection = ranges[props.field];
        var startDate = adjustTime(selection.startDate, 'start');
        var endDate = adjustTime(selection.endDate, 'end');
        setStartDate(startDate);
        setEndDate(endDate);
    }
    const handleApply = (e) => {
        e.preventDefault();
        var startDate = adjustTime(selectionRange.startDate, 'start');
        var endDate = adjustTime(selectionRange.endDate, 'end');
        props.changed({
            startDate: startDate,
            endDate: endDate,
            field: props.field
        })
        props.clicked();
    }
    return (
        <>
            <Modal show={props.show} modalClosed={props.clicked}>
                <div>
                    <DateRangePicker staticRanges={newStaticRanges} inputRanges={[]} ranges={[selectionRange]} onChange={handleSelect} locale={fr} rangeColors={["#ea5e0b"]} />
                </div>
                <button className="btn btn-primary" onClick={(e) => handleApply(e)} style={{width:"100%"}} >Valider </button>
            </Modal>
        </>
        
    )
}

export default DateRange
