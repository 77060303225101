import React from 'react'
import StatBody from '../../../component/HomeStat/StatBody/StatBody'
import StatHeader from '../../../component/HomeStat/StatHeader/StatHeader'

function UserVisHome() {
    return (
        <>
            <StatHeader />
            <StatBody visualisation={true} />
        </>
    )
}

export default UserVisHome
