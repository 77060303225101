import React from 'react'
import FindInPageIcon from '@material-ui/icons/FindInPage';
import ListIcon from '@material-ui/icons/List';

function TableFilters(props) {
    const {showFilters, clientsFilters, filtersChange, applyFilters} = props;

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...clientsFilters,
            [key]: e.target.value
        }
        filtersChange(newFilters);
        applyFilters(newFilters)
    }
    return (
        <>
            <tr className="tableFilters" style={{display: showFilters ? 'table-row' : 'none'}}>
                
                <td>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={clientsFilters?.customerCode || ''} name="customerCode" type="text" autoComplete="off" />
                        {/* <FindInPageIcon style={{color: ""}} /> */}
                    </div>
                </td>
                <td>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={clientsFilters?.name || ''} name="name" type="text" autoComplete="off" />
                    </div>
                </td>
                <td>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={clientsFilters?.clientEnseigne || ''} name="clientEnseigne" type="text" autoComplete="off" />
                    </div>
                </td>
                <td>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={clientsFilters?.postCode || ''} name="postCode" type="text" autoComplete="off" />
                    </div>
                </td>
                <td>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={clientsFilters?.town || ''} name="town" type="text" autoComplete="off" />
                    </div>
                </td>
                <td>
                    <div className="filterContainer">
                        <select className="form-control filter__select" onChange={(e) => inputChangeHandler(e)} value={clientsFilters?.dematType || ''} name="dematType">
                            <option value=""></option>
                            <option value="0">NON</option>
                            <option value="1">OUI</option>
                        </select>
                        {/* <ListIcon style={{color: ""}} />  */}
                    </div>
                </td>
                <td>
                    <div className="filterContainer">
                        <select className="form-control filter__select" onChange={(e) => inputChangeHandler(e)} value={clientsFilters?.ubl || ''} name="ubl">
                            <option value=""></option>
                            <option value="0">NON</option>
                            <option value="1">OUI</option>
                        </select>
                        {/* <ListIcon style={{color: ""}} />  */}
                    </div>
                </td>
                <td>
                    <div className="filterContainer">
                        <select className="form-control filter__select" onChange={(e) => inputChangeHandler(e)} value={clientsFilters?.chorus || ''} name="chorus">
                            <option value=""></option>
                            <option value="0">NON</option>
                            <option value="1">OUI</option>
                        </select>
                        {/* <ListIcon style={{color: ""}} />  */}
                    </div>
                </td>
                <td>
                    <div className="filterContainer">
                        <select className="form-control filter__select" onChange={(e) => inputChangeHandler(e)} value={clientsFilters?.supplier || ''} name="supplier">
                            <option value=""></option>
                            <option value="0">NON</option>
                            <option value="1">OUI</option>
                        </select>
                        {/* <ListIcon style={{color: ""}} />  */}
                    </div>
                </td>
                <td>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={clientsFilters?.email || ''} name="email" type="text" autoComplete="off" />
                        {/* <FindInPageIcon style={{color: ""}} /> */}
                    </div>
                </td>
            </tr>
        </>
    )
}

export default TableFilters
