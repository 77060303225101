import React from 'react'
import Axios from '../../../axios-alkor';
import { Link, useHistory } from 'react-router-dom';
import './TableBody.css'

function TableBody(props) {
    const {clientsData} = props;
    const history = useHistory();
    
    const getTokenVisualisation = (uid, login) => {
        Axios.get('/client/'+uid+'/userTokenVisualisation')
            .then(response => {
                Axios.defaults.headers.authorization = 'Bearer ' + response.data.token;
                //window.location.reload(false);
                //Axios.defaults.headers.authorization = 'Bearer ' + response.data.token;
                localStorage.setItem('tokenTemp', localStorage.getItem('token'))
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('loginVisualisation', login)
                history.push("/userVisualisation")
            })
    }

    return (
        <>
            {
                clientsData ? clientsData.map(row => (
                    <tr key={row.id} className="clientTableBody__tr">
                        <td>
                            {row.customerCode}
                        </td>
                        <td>
                            <div className="tableBody__companyName">
                                <Link to={{
                                    pathname: "/client",
                                    state: {
                                        clientUid: row.uid
                                    }
                                }}>
                                    {row.name}
                                </Link>
                            </div>
                            
                        </td>
                        <td>
                            {row.clientEnseigne}
                        </td>
                        <td>
                            {row.postCode}
                        </td>
                        <td>
                            {row.town}
                        </td>
                        <td>
                            {row.dematType == "1" ? "OUI" : "NON"}
                        </td>
                        <td>
                            {row.ubl == "1" ? "OUI" : "NON"}
                        </td>
                        <td>
                            {row.chorus == "1" ? "OUI" : "NON"}
                        </td>
                        <td>
                            {row.supplier == "1" ? "OUI" : "NON"}
                        </td>
                        <td>
                            {row.email}
                        </td>
                        <td style={{width:"100px"}}>
                            <div className="tableBody__companyName" onClick={() => getTokenVisualisation(row.uid, row.email)}>
                                {row.active == "1" ? (
                                    <button type="button" className="btn btn-outline-success btn-sm">
                                        Visualiser
                                    </button>
                                ) : null}   
                            </div>
                        </td>
                    </tr>
                )) : null
            }
        </>
    )
}

export default TableBody
