import React from 'react'
import LanguageIcon from '@material-ui/icons/Language';
import { useTranslation } from 'react-i18next';
import './MailPreview.css';
//import logo from '../../../assets/images/logo-restagraf.png';

  
function MailPreview(props) {
    const {dataTarget, targetName, targetValue, targetCommonValue, mailLang, logo, MDILogo} = props; 
    const nl2br = require('react-nl2br');
    const { t, i18n } = useTranslation();
    const lang = mailLang;

    //console.log ("lang = " )
    //console.log (lang )
   // console.log ("targetCommonValue = " )
    //console.log (targetCommonValue )

    return (
        <>
            <div className="modal fade" id={dataTarget} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content" style={{minWidth: "635px"}}>
                        <div className="modal-header">
                            <p className="modal-title" id="exampleModalLabel" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("default:subject")} style={{fontSize: "large"}}>
                                 Sujet :&nbsp;&nbsp;
                                { lang == 'adh' ? 
                                    targetValue?.mailSubject?.adhValue 
                                : lang == 'adhSu' ?
                                    targetValue?.mailSubject?.adhSuValue 
                                :   targetValue?.mailSubject?.mdiValue 
                                }
                            </p>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" >
                            <div >
                            {/* blockPortalCSecondary blockPortalNotCSecondary */}
                                <p data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("default:header")}>
                                    { lang == 'adh' ? 
                                        nl2br(targetValue?.block_header?.adhValue)
                                    : lang == 'adhSu' ?
                                        nl2br(targetValue?.block_header?.adhSuValue) 
                                    :   nl2br(targetValue?.block_header?.mdiValue) 
                                    }
                                </p>
                                
                                { (targetName == "blockTokenNotC" || targetName == "blockToken" || targetName == "blockAccountNotC" || targetName == "blockPortalC" || targetName == "blockPortalNotC") ?
                                    <p style={{border: targetName=="blockToken" || targetName=="blockTokenNotC" ?  "1px solid rgb(38, 146, 235)" : "" }} data-bs-toggle="tooltip" data-bs-placement="bottom" title="client ou parent (token)">
                                        { lang == 'adh' ? 
                                            nl2br(targetValue?.block_Token?.adhValue)
                                        : lang == 'adhSu' ?
                                            nl2br(targetValue?.block_Token?.adhSuValue) 
                                        :   nl2br(targetValue?.block_Token?.mdiValue) 
                                        }
                                    </p>
                                    : null
                                }
                                
                                { (targetName == "blockTokenSecondaryNotC" || targetName == "blockTokenSecondary"  || targetName == "blockAccountNotCSecondary" || targetName == "blockPortalCSecondary" || targetName == "blockPortalNotCSecondary") ?
                                    <p style={{border: targetName=="blockTokenSecondaryNotC" || targetName=="blockTokenSecondary" ?  "1px solid rgb(38, 146, 235)" : "" }} data-bs-toggle="tooltip" data-bs-placement="bottom" title="personne en copie">
                                        { lang == 'adh' ? nl2br(targetValue?.block_TokenSecondary?.adhValue) : nl2br(targetValue?.block_TokenSecondary?.mdiValue) }
                                    </p>
                                    : null
                                }

                                { (targetName == "blockAttachement") ?
                                    <p style={{border: targetName=="blockAttachement"  ?  "1px solid rgb(38, 146, 235)" : "black" }} data-bs-toggle="tooltip" data-bs-placement="bottom" title="client avec attachement">
                                        { lang == 'adh' ? 
                                            nl2br(targetValue?.block_Attachement?.adhValue)
                                        : lang == 'adhSu' ?
                                            nl2br(targetValue?.block_Attachement?.adhSuValue) 
                                        :   nl2br(targetValue?.block_Attachement?.mdiValue) 
                                        }
                                    </p>
                                    : null
                                }
                              
                                { (targetName == "blockAccountNotC" || targetName == "blockAccountNotCSecondary" || targetName == "blockTokenNotC" || targetName == "blockTokenSecondaryNotC" || targetName == "blockPortalNotCWithToken" || targetName=="blockPortalNotCWithAttachment"  ) ?
                                    <p style={{border: targetName=="blockAccountNotC" || targetName=="blockAccountNotCSecondary" ?  "1px solid rgb(38, 146, 235)" : "black" }} data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("default:accountCreation")}>
                                        { lang == 'adh' ? 
                                            nl2br(targetValue?.block_crateAccount?.adhValue)
                                        : lang == 'adhSu' ?
                                            nl2br(targetValue?.block_crateAccount?.adhSuValue) 
                                        :   nl2br(targetValue?.block_crateAccount?.mdiValue) 
                                        }
                                    </p>
                                    : null
                                }
                               

                                { (targetName == "blockTokenNotC" || targetName == "blockToken"  || targetName == "blockTokenSecondaryNotC" || targetName == "blockTokenSecondary" || targetName=="blockPortalC" || targetName=="blockPortalNotC" || targetName=="blockPortalCSecondary" || targetName=="blockPortalNotCSecondary" || targetName=="blockAccountNotC" || targetName=="blockAccountNotCSecondary"  ) ?
                                    <p style={{border: targetName=="blockPortalC" || targetName=="blockPortalNotC" || targetName=="blockPortalCSecondary" || targetName=="blockPortalNotCSecondary" ?  "1px solid rgb(38, 146, 235)" : "black" }} data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("default:portalAccess")}>
                                        {nl2br(targetCommonValue?.portalAccessMailCore) }
                                    </p>
                                    : null
                                }

                                <p data-bs-toggle="tooltip" data-bs-placement="bottom" title="Block pied de page">
                                    { nl2br(targetCommonValue?.mailCommonSection) }
                                </p> 

                                <div> 
                                    <img className="mailpreview_logo" src={lang=='mdi' ? MDILogo?.content : logo?.content} alt="logo" />
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MailPreview
