import React, { useState, useCallback, useEffect } from 'react';
import './Headband.css';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import './Header.css';
import PersonIcon from '@material-ui/icons/Person';
import smallLogo from '../../assets/images/logo_alkor.png';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import HomeIcon from '@material-ui/icons/Home';
import DescriptionIcon from '@material-ui/icons/Description';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Axios from '../../axios-alkor';
import PublicIcon from '@material-ui/icons/Public';
import { Tooltip, withStyles } from '@material-ui/core';

const CTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 15,
    },
}))(Tooltip);


function Headband(props) {
    const location = useLocation();
    const { userRole } = props;
    let login = localStorage.getItem("loginVisualisation") !== null ? localStorage.getItem("loginVisualisation") : localStorage.getItem("login");
    const history = useHistory();

    const onClickHandler = () => {
        props.logout()
    }

    const exitVisualisation = () => {
        const originalToken = localStorage.getItem('tokenTemp');
        localStorage.setItem('token', originalToken);
        localStorage.removeItem('tokenTemp');
        localStorage.removeItem('loginVisualisation');
        Axios.defaults.headers.authorization = 'Bearer ' + originalToken;
        history.push("/")
    }

    const [showDropdown, setShowDropdown] = useState(false)
    const [showOpenNavBar, setShowOpenNavBar] = useState(false)

    return (
        <div className="headband">
            <div className="headband__left" style={{ display: "flex", width: "250px" }}>
                <img src={smallLogo} alt="smallLogo" style={{ width: "20", margin: "10px 10px 10px 17px" }} />
                <p style={{ fontSize: "large", fontWeight: "400px", color: "white", margin: "auto" }}>PORTAIL FACTURES</p>
            </div>
            <div className="headband__right" >
                {
                    (location.pathname === "/userVisualisation" || location.pathname === "/userVisualisation/invoice") ? (
                        <div className="headband__visualisation">
                            <div className="headbandVisualisation__profil">
                                <h5>Connecté en tant que :  <span>{login}</span></h5>
                            </div>
                            <div className="headbandVisualisation__nav__menu">
                                <li className="headbandVisualisation__nav__item" >
                                    <NavLink exact className="headbandVisualisation__nav__Link" to="/userVisualisation" activeClassName="headbandVisualisation__nav__link_active">
                                        <HomeIcon fontSize="large" className="headbandVisualisation__nav__icon" style={{ width: "28px" }}></HomeIcon>
                                        <span>Accueil</span>
                                    </NavLink>
                                </li>
                                <li className="headbandVisualisation__nav__item">
                                    <NavLink className="headbandVisualisation__nav__Link" to="/userVisualisation/invoice" activeClassName="headbandVisualisation__nav__link_active">
                                        <DescriptionIcon fontSize="large" className="headbandVisualisation__nav__icon" style={{ width: "28px" }}></DescriptionIcon>
                                        <span>Factures</span>
                                    </NavLink>
                                </li>
                                <li className="headbandVisualisation__nav__item" style={{ backgroundColor: "red" }}>
                                    <NavLink className="headbandVisualisation__nav__Link" to="/clients" activeClassName="headbandVisualisation__nav__link_active" onClick={() => exitVisualisation()}>
                                        <ExitToAppIcon fontSize="large" className="headbandVisualisation__nav__icon" style={{ width: "28px", color: "white" }}></ExitToAppIcon>
                                        <span style={{ color: "white" }}>Retour</span>
                                    </NavLink>
                                </li>
                            </div>
                        </div>
                    ) : (
                        <div className="headbandVisualisation__profils">
                            <h5>Connecté en tant que :  <span>{login}</span></h5>
                        </div>
                    )
                }

                <div className="profileDropdown">
                    <PersonIcon fontSize="large" style={{ cursor: "pointer", color: showDropdown ? "" : "", width: "28px" }} className="nav__icon"></PersonIcon>
                    <div className="cssDropdown">
                        {userRole === "CLIENT_USER" ? (<NavLink className="dropdownLink" to="/settings">Paramètres</NavLink>) : null}
                        {userRole === "ADMIN" ? (<NavLink className="dropdownLink" to="/chglog">Notes de version</NavLink>) : null}
                        <NavLink className="dropdownLink" onClick={() => onClickHandler()} to="/">{props.isAuthenticated ? 'Se déconnecter' : 'Se connecter'}</NavLink>
                    </div>
                </div>
                <div className=" " style={{ padding: "0" }}>
                    <CTooltip title="Extranet" className="">
                        <a href='https://extranet.alkor-groupe.com/' style={{ color: "rgb(234, 94, 11)" }} target='blank' >
                            <PublicIcon fontSize="large" style={{ cursor: "pointer", width: "28px" }} className="nav__icon " />
                        </a>
                    </CTooltip>
                </div>

            </div>
        </div>
    )
}

const Hover = ({ onHover, children }) => (
    <div className="hover">
        <div className="hover__no-hover">{children}</div>
        <div className="hover__hover">{onHover}</div>
    </div>
)


const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        login: state.auth.login
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password) => dispatch(actions.auth(email, password)),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/')),
        logout: () => dispatch(actions.logout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Headband)