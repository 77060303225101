import React, { useEffect, useState } from 'react'
import Axios from '../../../axios-alkor';
import './StatHeader.css';
import StatHeaderElement from './StatHeaderElement/StatHeaderElement';
import StatHeaderElementSales from './StatHeaderElement/StatHeaderElementSales';
import WarningIcon from '@material-ui/icons/Warning';
function ComptaStatHeader(props) {
    const [totalCa, setTotalCa] = useState(0)
    const [docsWeekLeft, setDocWeekLeft] = useState(0)
    //const [viewedCount, setViewedCount] = useState(0);
    const [startDate, setStartDate] = useState(0)
    const [endDate, setEndDate] = useState(0)

    const [dateInterval, setDateInterval] = useState(null)
    const [clients, setClients] = useState([]);
    const [clientFilter, setClientFilter] = useState(null);
    const [regionFilter, setRegionFilter] = useState(null)

    const formatNumber = (number) => {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number).replaceAll('.',' ')
    }

    const handleFilters = (dateInterval, client, region) => {
        setDateInterval(dateInterval);
        setClientFilter(client);
        setRegionFilter(region);
    }

    useEffect(() => {
        const today = new Date().getTime();
        const start = dateInterval?.startDate || today - 2628000000;
        const end = dateInterval?.endDate || new Date().getTime();
        let query = '/invoice//comptaStat?issuingDate='+start+":"+end;
        if (clientFilter)
            query += "&client="+clientFilter.id;
        if (regionFilter)
            query += "&region="+regionFilter.id;
        Axios.get(query).then((response) => {
            setTotalCa(response.data.totalCA);
            setDocWeekLeft(response.data.docWeekLeft);
            setStartDate(start);
            setEndDate(end);
            setClients(response.data.clients);
        })
    }, [dateInterval, clientFilter, regionFilter])
    return (
        <>
            <div className=" row">
                <StatHeaderElementSales value={formatNumber(totalCa)} className="col-sm-6" item="Total des ventes" dateStart={startDate} dateEnd={endDate} clients={clients} handleFilters={handleFilters}/>
                {/* <StatHeaderElement value={viewedCount} icon={<DraftsIcon style={{color: "#ff8354", fontSize: 25}} />} progress={((viewedCount * 100) / totalDocs).toFixed(2)} item="Lus" percentage={((viewedCount * 100) / totalDocs).toFixed(2) + "%"} textColor="white" containerBGColor="#5243ac" /> */}
                <StatHeaderElement value={docsWeekLeft} className="col-sm-6" icon={<WarningIcon style={{color: "red", fontSize: 25}} />} progress='100' item="Non lus et à moins d'une semaine de la date d'échéance" percentage="" textColor="rgb(17, 39, 50)" containerBGColor="white" hasFilter={false} />
                {/* <StatHeaderElement value="95325" icon={<BugReportIcon style={{color: "#ff716e", fontSize: 25}} />} progress='50' item="Litiges" percentage="12.5" textColor="white" containerBGColor="#82b2bd" /> */}
            </div>
        </>
    )
}



export default ComptaStatHeader
