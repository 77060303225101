import React from 'react'
import BusinessIcon from '@material-ui/icons/Business';
import FilterListIcon from '@material-ui/icons/FilterList';
import ReplayIcon from '@material-ui/icons/Replay';
import { Tooltip, withStyles } from '@material-ui/core';

const CTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
}))(Tooltip);

function TableActions(props) {
    const {resetLogs, setShowFilters, showFilters} = props;
    return (
        <div className="tableActions">
            <div className="tableActions__icons">
                <div className="tableActions__action">
                    <CTooltip title="Reinitialiser les filtres" className="tableActions__icon" className="">
                        <ReplayIcon onClick={() => resetLogs()} fontSize="small"  />
                    </CTooltip>
                </div>
                <div className="tableActions__action">
                    <CTooltip title="Filtres" className="tableActions__icon"  style={{color: showFilters ? '#0D7D65' : '#00000080'}}>
                        <FilterListIcon onClick={() => setShowFilters(!showFilters)} fontSize="small"  />
                    </CTooltip>
                </div>
            </div>
        </div>
    )
}

export default TableActions
