
import React, { useCallback, useEffect, useState } from 'react';
import Axios from '../../../axios-alkor';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import LOGO from '../../../assets/images/logo-alkor.png';
import '../ForgottenPassword/ForgottenPassword.css'

function ResetPassword() {
    let {token} = useParams(); 
    const [login, setLogin] = useState('');
    const [user, setUser] = useState(null)
    const [resetState, setResetState] = useState(false);
    const [error, setError] = useState(null);
    const [password, setPassword] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const [alert, setAlert] = useState(null);

    useEffect(() => {
        Axios.get('/user/'+token+"/checkExistToken").then(response =>{

        }).catch(err => {
            setAlert("Le token envoyé est expiré ou invalide !");
        })
    }, [token])

    const ResetPassword = (e) => {
        e.preventDefault();
        setError(null)
        if (password === confirmedPassword) {
            editPassword(password);
        } else {
            setError('les mots de passe ne sont pas identiques')
        }
    }

    const editPassword = useCallback((newPassword) => {
        Axios.put("/user/"+token+"/password", {
            password : newPassword
        }).then(response => {
            setResetState(false)
            setAlert("Votre mot de passe à été reinitialisé ")
        })
        .catch(err => console.log(err))
    }, [password])



    
    let content = (<>
            <div className="forgotPassword__formContainer" style={{padding: "30px"}}>
            
            <form className="signin__form" action="#" style={{marginTop: "25px"}}>
            <h1 className="signin__formH1">Reinitialiser votre mot de passe</h1>
                <label className="signin__formLabel" htmlFor='username'>Nouveau mot de passe : </label>
                <input className="signin__formInput" type="password" name="password" onChange={(e) => setPassword(e.target.value)} value={password}  required autoFocus />
                <label className="signin__formLabel" htmlFor='username'>Confirmation mot de passe : </label>
                <input className="signin__formInput" type="password" name="confirmedPassword" onChange={(e) => setConfirmedPassword(e.target.value)} value={confirmedPassword}  required/>
                <span className="signin__error"> {error} </span>
                <button className="signin__formButton" type="submit" onClick={(e) => ResetPassword(e)} >Valider</button>

            </form>
        </div>
        </>)
    
    if(alert) {
        content = (<>
            <h4>{alert} </h4>
            <Link to="/">Se connecter</Link>
        </>)
    }

    return (
        <div className="forgotPassword__container" style={{padding: "30px"}}>
            <Link to="/" className="signin__Icon">
                    <img src={LOGO} alt="logo" />
            </Link>
            {content}
            
            
        </div>
    )
}

export default ResetPassword
