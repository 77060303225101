import React, { useEffect, useState } from 'react'
import Axios from '../../../axios-alkor';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index'
import './StatHeader.css';
import StatHeaderElementSales from './StatHeaderElement/StatHeaderElementSales';
import StatHeaderElementU from './StatHeaderElement/StatHeaderElementU';
import DescriptionIcon from '@material-ui/icons/Description';
import DraftsIcon from '@material-ui/icons/Drafts';
import MailIcon from '@material-ui/icons/Mail';
import BugReportIcon from '@material-ui/icons/BugReport';

function StatHeader(props) {
    const [totalDocs, setTotalDocs] = useState(0);
    const [totalYearDocs, setTotalYearDocs] = useState(0);
    const [totalPurchases, setTotalPurchases] = useState(0);
    const [notViewedCount, setNotViewedCount] = useState(0);
    const [viewedCount, setViewedCount] = useState(0);
    const [dateInterval, setDateInterval] = useState(null)
    const [startDate, setStartDate] = useState(0)
    const [endDate, setEndDate] = useState(0)
    const [clients, setClients] = useState(null);
    const [childrenFilters, setChildrenFilters] = useState(null)

    useEffect(() => {
        Axios.get('/client//children').then((response) => {
            if (response.data.length > 1) {
                setClients(response.data);
                /*setChildrenFilters(response.data.map(c => c.id).join(','));*/
            }
        }).catch(error => {console.error(error);});
    }, [])

    useEffect(() => {
        const date = new Date();
        var defaultStartDate = new Date();

        const startYear = new Date(date.getFullYear() + "").getTime();
        const endYear = new Date(date.getFullYear() + 1 + "").getTime();
        var d = defaultStartDate.getDate();
        defaultStartDate.setMonth(date.getMonth() - 1);
        if (defaultStartDate.getDate() != d) {
            defaultStartDate.setDate(0);
        }
        defaultStartDate.setHours(0,0,0,0);
        const startDate = dateInterval?.startDate || defaultStartDate.getTime();
        const endDate = dateInterval?.endDate || new Date().getTime();
        
        let query = '/invoice//clientStat?yearInterval='+startYear + ":" + endYear + '&dateInterval='+startDate + ":" + endDate;
        if (childrenFilters)
            query += "&clients="+encodeURIComponent(childrenFilters);
        Axios.get(query).then((response) => {
            setTotalDocs(response.data.totalDocs);
            setTotalYearDocs(response.data.totalYearDocs);
            setNotViewedCount(response.data.notViewedDocs);
            setViewedCount(totalDocs - notViewedCount);
            setStartDate(startDate);
            setEndDate(endDate);
            setTotalPurchases(response.data.totalPurchases);
        })
    }, [dateInterval, childrenFilters])

    const handleFilters = (dateInterval, clientFilters) => {
        setDateInterval(dateInterval);
        if (clientFilters)
            setChildrenFilters(clientFilters.map(c => c.id).join(','));
    }
    
    return (
    <>
        <div className="row">
            <StatHeaderElementU value={totalDocs} icon={<DescriptionIcon />} progress='100' item="Total de documents" percentage="" textColor="#112732" containerBGColor="white" />
            <StatHeaderElementU value={totalYearDocs} icon={<DescriptionIcon />} progress='100' item="Total de documents de cette année" percentage="" textColor="white" containerBGColor="#5243ac" />
            {/* <StatHeaderElement value={viewedCount} icon={<DraftsIcon style={{color: "#ff8354", fontSize: 25}} />} progress={((viewedCount * 100) / totalDocs).toFixed(2)} item="Lus" percentage={((viewedCount * 100) / totalDocs).toFixed(2) + "%"} textColor="white" containerBGColor="#5243ac" /> */}
            {/* <StatHeaderElement value="95325" icon={<BugReportIcon style={{color: "#ff716e", fontSize: 25}} />} progress='50' item="Litiges" percentage="12.5" textColor="white" containerBGColor="#82b2bd" /> */}
        
            <StatHeaderElementSales value={totalPurchases + " €"} clients={clients} item="Total des achats" dateStart={startDate} dateEnd={endDate} handleFilters={handleFilters} isClient={true} />
            <StatHeaderElementU value={notViewedCount} icon={<MailIcon />} progress={((notViewedCount * 100) / totalDocs).toFixed(2)} item="Documents non lus" percentage={((notViewedCount * 100) / totalDocs).toFixed(2) + "%"} textColor="white" containerBGColor="#112732" />
        </div>
    </>)
}



export default StatHeader
