import React, { useEffect, useState } from 'react'
import './Client.css';
import { useLocation, useHistory } from 'react-router-dom'
import Axios from '../../axios-alkor';
import BusinessIcon from '@material-ui/icons/Business';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import TitleHelmet from '../../component/UI/TitleHelmet/TitleHelmet';

function Parent() {
    const [client, setClient] = useState(null);
    let location = useLocation();
    const history = useHistory();
	console.log(location);
    useEffect(() => {
        const parentUid = location.state.parentUid;
        Axios.get('/client/'+parentUid+'/object').then((response) => {
            setClient(response.data.client);
        }).catch(err => {
            console.log(err);
        })
    },[location])

    
    return (
        <div style={{margin: "0 20px 0 20px"}}>
            <TitleHelmet title="Client | Portail Alkor" />
            <div className="tableActions">
                    <div className="tableActions__title">
                        <BusinessIcon fontSize="large" className="documentTitle__icon" />
                        <h3>Référentiel parent</h3>
                    </div>
                </div>
                <hr width="100%" style={{padding:0, marginBottom: "19px"}}/>
                {
                client ? (
                    <>
                        <div className="referentiel__container">

                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            Informations générales
                                        </div>
                                        <div className="card-body">
                                            <div className="referentiel__element">
                                                <h5 style={{fontWeight: "bold", marginRight: "20px"}}>Code Parent :</h5>
                                                <h5>{client.customerCode}</h5>
                                            </div>
                                            <div className="referentiel__element">
                                                <h5 style={{fontWeight: "bold", marginRight: "20px"}}>Nom Parent :</h5>
                                                <h5>{client.name}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            
                            <div className=" row">
                                <div className=" col-sm-6">

                                    <div className="card">
                                            <div className="card-header">
                                                Coordonnées
                                            </div>
                                            <div className="card-body">
                                    
                                                <div className="referentiel__element">
                                                    <h5 style={{fontWeight: "bold", marginRight: "20px"}}>Email :</h5>
                                                    <h5>{client.email}</h5>
                                                </div>
                                                <div className="referentiel__element">
                                                    <h5 style={{fontWeight: "bold", marginRight: "20px"}}>Ville :</h5>
                                                    <h5>{client.town}</h5>
                                                </div>
                                                <div className="referentiel__element">
                                                    <h5 style={{fontWeight: "bold", marginRight: "20px"}}>Code Postal  :</h5>
                                                    <h5>{client.postCode}</h5>
                                                </div>
                                            </div>
                                        </div>
                                </div>

                                <div className=" col-sm-6">
                                    <div className="card">
                                        <div className="card-header">
                                            Configuration Démat
                                        </div>
                                        <div className="card-body">
                                            <div className="referentiel__element">

                                                <h5 style={{fontWeight: "bold", marginRight: "20px"}}>Type :</h5>
                                                <h5> { (client.dematType ==1 ? "Démat sur portail" : (
                                                    client.dematType == 2 ? "Mail uniquement" : (
                                                        client.dematType==3 ? "Mail avec attachement" : 
                                                            <CloseIcon style={{color: "red", fontSize: "1.8rem"}} />)))  
                                                        }  
                                                </h5>

                                                {/* <h5> { (client.dematType =="PORTAL" ? "Démat sur portail" : (
                                                    client.dematType == "MAIL_ONLY" ? "Mail uniquement" : (
                                                        client.dematType=="MAIL_N_ATTCHMNT" ? "Mail avec attachement" : 
                                                            <CloseIcon style={{color: "red", fontSize: "1.8rem"}} />)))  
                                                        }  
                                                </h5> */}
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            
                            <button style={{marginTop: "19px"}} 
                                className="btn btn-primary"
                                onClick={() => {
                                    history.goBack();
                                }}
                            >
                                Retour
                            </button>
                        </div>
                    
                        
                    </>
                ) : null
            }
            
            
        </div>
    )
}

export default Parent
