import React, { useCallback, useEffect, useState } from 'react';
import './Settings.css';
import Axios from '../../axios-alkor';
import TitleHelmet from '../UI/TitleHelmet/TitleHelmet';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import MailLngForm from './MailLngForm/MailLngForm';

// Email => Bloc 
// mail de rappel 
// mail expiration des token et des document

const Settings = props => {
    const [settings, setSettings] = useState(null);
    const [updated , setUpdated] = useState(false)
    const [logo , setLogo] = useState(null)
    const [MDILogo , setMDILogo] = useState(null)
    const [newLogo , setNewLogo] = useState(null)
    const [newMDILogo , setNewMDILogo] = useState(null)
    const notyf = new Notyf();

    useEffect(() => {
        Axios.get('/settings').then(response => {
            let tsettings = response.data?.tsettings;
            
            let tsettingsMail = response.data?.tsettingsMail;
            let newTSettings = {}
            let newTSettingsM = {}
            tsettings?.map(row => {
                let key = row.key;
                let value = row.value
                newTSettings = {
                    ...newTSettings,
                    [key]: value
                }
            })
            tsettingsMail?.map(row => {
                let key = row.key;
                let adhValue = row.adhValue;
                let adhSuValue = row.adhSuValue;
                let mdiValue = row.mdiValue;
                newTSettingsM = {
                    ...newTSettingsM,
                    [key]: {
                        adhValue,
                        adhSuValue,
                        mdiValue
                    }
                }
            })
            setSettings({
                tsettings : newTSettings, 
                tsettingsMail:newTSettingsM
            })
        })
    }, [updated])
    
    useEffect(() => {
        Axios.get("/settings//logo").then(response => {
            if (response.data)
                setLogo(response.data);
        })
        Axios.get("/settings//MDIlogo").then(response => {
            if (response.data)
                setMDILogo(response.data);
        })
        
    }, [])


    const editSettings = useCallback((e,settings) => {
        e.preventDefault()
        Axios.put('/settings//settings', settings).then(response => {
            setUpdated(true)
            notyf.success('Paramètres sauvegardés');
            if (newLogo)
                Axios.put('/settings//logo', newLogo).then(response => {
                    setLogo(response.data);
                    setNewLogo(null);
                })
            if (newMDILogo)
                Axios.put('/settings//MDIlogo', newMDILogo).then(response => {
                    setMDILogo(response.data);
                    setNewMDILogo(null);
                })
        })
    }, [settings, newLogo, newMDILogo])

    const inputChangeHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let tsettingsNew = {
            ...settings?.tsettings,
            [name] : value
        }
        setSettings({
            ...settings,
            tsettings: tsettingsNew 
        })
    }

    const tsettingsMChangeHandler = (e, lngKey) => {
        let name = e.target.name;
        let value = e.target.value;
        let tsettingsMNew = {
            ...settings.tsettingsMail,
            [name] : {
                ...settings.tsettingsMail[name],
                [lngKey] : value
            }
        }
        setSettings({
            ...settings,
            tsettingsMail: tsettingsMNew 
        })
    }

    const handlePictureChange = (e) => {
        console.log(e.target.name)

        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            if (["image/jpeg", "image/png"].indexOf(file.type) !== -1) {
                const fr = new FileReader();
                fr.readAsDataURL(file);
                fr.onload = () => {
                    if( e.target.name == "mailLogo" ){
                        const newLogo = {content: fr.result, name: file.name};
                        setNewLogo(newLogo);
                    }else{
                        const newMDILogo = {content: fr.result, name: file.name};
                        setNewMDILogo(newMDILogo);
                    }
                }
            } else
                notyf.error('Le fichier doit être au format PNG ou JPG');
        }
    }
    
    return (

    <div className="emailSettings__container">
        <TitleHelmet title="Portail facture Alkor | Configuration " />
        <div className="tableActions">
            <div className="" style={{marginTop:"10px"}}>
                <h2 style={{color:"#666666"}}>Configuration</h2>
            </div>
        </div><hr width="100%" style={{ margin: "0"}}/>
        <ul className="nav nav-tabs" style={{marginTop:"9px"}}>
            <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#list-emails">Emails</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#list-smtp">SMTP</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#list-sftp">SFTP</a>
            </li>
        </ul>
        <div id="myTabContent" className="tab-content justify-top">
            <div className="tab-pane fade show active" id="list-emails">
                <form className="emailSettings__for" action="#">
                    <h1 className="emailSettings__formH1">Configuration des mails de notification</h1>
                    
                    <div className="tags-left notice">
                        <small>Les tags à renseigner pour le compte des utilisateurs :</small>
                        <ul className="tags-left ">
                            <li>
                                <small>
                                    Le tag <b>[DOC_NUMBER]</b> représente le numéro de facture.
                                </small>
                            </li>
                            <li>
                                <small>
                                    Le tag <b>[TOKEN]</b> est un token-ack permettant de télécharger une facture. 
                                </small>
                            </li>
                            <li>
                                <small>
                                    Le tag <b>[PORTAIL]</b> est un lien vers un token-ack permettant de télécharger une facture.
                                </small>
                            </li>
                            <li>
                                <small>
                                    Le tag <b>[CREATION]</b> est un lien pour la creation de compte utilisateur.
                                </small>
                            </li>
                            <li>
                                <small>
                                    Le tag <b>[CGV]</b> est un lien vers les Conditions Générales de Ventes.
                                </small>
                            </li>
                            <li>
                                <small>
                                    Le tag <b>[NBMOIS]</b> représente le délai de conservation des fichiers clients sur SFTP.
                                </small>
                            </li>
                        </ul>
                    </div>
                    <div className="tags-left notice">
                        <small>Les onglets à renseigner pour le compte des utilisateurs :</small>
                        <ul className="tags-left ">                           
                            <li>
                                <small>
                                    L'onglet  <b>CLI</b> contient le texte envoyé aux adhérents.
                                </small>
                            </li>
                            <li>
                                <small>
                                    L'onglet  <b>FOU</b> contient le texte envoyé aux fournisseurs.
                                </small>
                            </li>
                            <li>
                                <small>
                                    L'onglet  <b>MDI</b> contient le texte envoyé aux clients Majuscule Direct.
                                </small>
                            </li>
                        </ul>
                    </div>
                    <div className="core-Mails">
                        <div className="form-control-mail"> 
                            <label className="emailSettings__formLabel" htmlFor='emailReceiver'>Adresse mail de réception des reportings et alertes :</label>
                            <input className="emailSettings__formInput" type="email" 
                            name="emailReceiver" onChange={(e) => inputChangeHandler(e)} value={settings?.tsettings?.emailReceiver || ''} required  />
                        </div>
                        <div className="form-control-mail labelSpec"> 
                            <label className="emailSettings__formLabel " htmlFor='mailSubject'>Bloc sujet :</label>
                            <MailLngForm 
                                inputName="mailSubject"
                                inputValue={settings?.tsettingsMail?.mailSubject}
                                tsettingsMail={settings?.tsettingsMail}
                                tsettings={settings?.tsettings}
                                inputChange={tsettingsMChangeHandler} 
                                logo={logo}
                                MDILogo={MDILogo} 
                            />
                        </div>
                        <div className="form-control-mail labelSpec"> 
                            <label className="emailSettings__formLabel labelSpec" htmlFor='block_header'>Bloc entête :</label>
                            <MailLngForm 
                                inputName="block_header"
                                inputValue={settings?.tsettingsMail?.block_header}
                                tsettingsMail={settings?.tsettingsMail}
                                tsettings={settings?.tsettings}
                                inputChange={tsettingsMChangeHandler} 
                                logo={logo}
                                MDILogo={MDILogo}
                            />
                        </div>
                        <div className="form-control-mail labelSpec"> 
                            <label className="emailSettings__formLabel" htmlFor='block_Token'>Bloc utilisateur principal :</label>
                            <MailLngForm 
                                inputName="block_Token"
                                inputValue={settings?.tsettingsMail?.block_Token}
                                tsettingsMail={settings?.tsettingsMail}
                                tsettings={settings?.tsettings}
                                inputChange={tsettingsMChangeHandler} 
                                logo={logo}
                                MDILogo={MDILogo} 
                            />
                        </div>  
                         <div className="form-control-mail labelSpec"> 
                            <label className="emailSettings__formLabel " htmlFor='block_TokenSecondary'>Bloc utilisateur secondaire :</label>
                            <MailLngForm 
                                inputName="block_TokenSecondary"
                                inputValue={settings?.tsettingsMail?.block_TokenSecondary}
                                tsettingsMail={settings?.tsettingsMail}
                                tsettings={settings?.tsettings}
                                inputChange={tsettingsMChangeHandler}
                                logo={logo}
                                MDILogo={MDILogo} 
                            />
                        </div>  
                    </div>
                    <div className="core-Mails">
                        <div className="form-control-mail"> 
                            <label className="emailSettings__formLabel" htmlFor='block_Attachement'>Bloc attachement :</label>
                            <MailLngForm 
                                inputName="block_Attachement"
                                inputValue={settings?.tsettingsMail?.block_Attachement}
                                tsettingsMail={settings?.tsettingsMail}
                                tsettings={settings?.tsettings}
                                inputChange={tsettingsMChangeHandler} 
                                logo={logo}
                                MDILogo={MDILogo} 
                            />
                        </div>                        
                         <div className="form-control-mail labelSpec"> 
                            <label className="emailSettings__formLabel labelSpec" htmlFor='block_crateAccount'>Bloc de création de compte :</label>
                            <MailLngForm 
                                inputName="block_crateAccount"
                                inputValue={settings?.tsettingsMail?.block_crateAccount}
                                tsettingsMail={settings?.tsettingsMail}
                                tsettings={settings?.tsettings}
                                inputChange={tsettingsMChangeHandler}
                                logo={logo}
                                MDILogo={MDILogo}  
                            />
                        </div> 
                        <div className="form-control-mail"> 
                            <label className="emailSettings__formLabel" htmlFor='portalAccessMailCore'>Bloc d'accès au portail :</label>
                            <textarea className="emailSettings__formInput" rows="5" cols="90"  maxLength="600"
                            name="portalAccessMailCore" onChange={(e) => inputChangeHandler(e)} value={settings?.tsettings?.portalAccessMailCore || ''}  required  />
                        </div>
                    </div> 
                    <div className="core-Mails">
                        <div className="form-control-mail"> 
                            <label className="emailSettings__formLabel" htmlFor='mailCommonSection'>Bloc pied de page :</label>
                            <textarea className="emailSettings__formInput" rows="5" cols="90" maxLength="600"
                            name="mailCommonSection" onChange={(e) => inputChangeHandler(e)} value={settings?.tsettings?.mailCommonSection || ''} required  />
                        </div>
                        <div className="form-control-mail"> 
                            <label className="emailSettings__formLabel" htmlFor='mailLogo'>Logo CLI et FOU :</label>
                            <div>
                                <input type="file" className="emailSettings__formInput" style={{width:"300px", verticalAlign: "top"}} name="mailLogo" 
                                    onChange={(e) => handlePictureChange(e)} />
                                {logo && <div className="logo-block">
                                    <label>Logo actuel</label>
                                    <img src={logo.content} className="logo"></img>
                                </div>}
                                {newLogo && <div className="logo-block">
                                    <label>Nouveau logo</label>
                                    <img src={newLogo.content} className="logo"></img>
                                </div>}
                            </div>
                        </div>

                        <div className="form-control-mail"> 
                            <label className="emailSettings__formLabel" htmlFor='mailLogoMDI'>Logo MDI :</label>
                            <div>
                                <input type="file" className="emailSettings__formInput" style={{width:"300px", verticalAlign: "top"}} name="mailLogoMDI" 
                                    onChange={(e) => handlePictureChange(e)} />
                                {MDILogo && <div className="logo-block">
                                    <label>Logo actuel</label>
                                    <img src={MDILogo.content} className="logo"></img>
                                </div>}
                                {newMDILogo && <div className="logo-block">
                                    <label>Nouveau logo</label>
                                    <img src={newMDILogo.content} className="logo"></img>
                                </div>}
                            </div>
                        </div>
                           {/*                     
                        <div className="core-Mails">
                            <div className="form-control-mail labelSpec"> 
                                <label className="emailSettings__formLabel labelSpec" htmlFor='beforeCreationAccountTokenExpirationCore'>Rappel avant expiration du token (creation de compte) :</label>
                                <textarea className="emailSettings__formInput" rows="5" cols="90" maxLength="600" title="Avant l'expiration du token pour la creation de compte"
                                name="beforeCreationAccountTokenExpirationCore" onChange={(e) => inputChangeHandler(e)} value={settings?.tsettings?.beforeCreationAccountTokenExpirationCore || ''} required  />
                            </div>
                            <div className="form-control-mail"> 
                                <label className="emailSettings__formLabel" htmlFor='beforeDocumentTokenExpirationCore'>Rappel avant expiration du token (Document) :</label>
                                <textarea className="emailSettings__formInput" rows="5" cols="90" maxLength="600" title="Avant l'expiration du token pour la consultation des documents"
                                name="beforeDocumentTokenExpirationCore" onChange={(e) => inputChangeHandler(e)} value={settings?.tsettings?.beforeDocumentTokenExpirationCore || ''}  required  />
                            </div>
                        </div> 
                    <div className="core-Mails">
                        <div className="form-control-mail labelSpec"> 
                            <label className="emailSettings__formLabel labelSpec" htmlFor='creationAccountTokenExpirationCore'>Expiration du token (creation de compte) :</label>
                            <textarea className="emailSettings__formInput" rows="5" cols="90" maxLength="600" title="Après l'expiration du token pour la creation de compte"
                            name="creationAccountTokenExpirationCore" onChange={(e) => inputChangeHandler(e)} value={settings?.tsettings?.creationAccountTokenExpirationCore || ''} required  />
                        </div>
                        <div className="form-control-mail"> 
                            <label className="emailSettings__formLabel" htmlFor='DocumentTokenExpirationCore'>Expiration du token (Document) :</label>
                            <textarea className="emailSettings__formInput" rows="5" cols="90" maxLength="600" title="Après l'expiration du token d'un document consultable"
                            name="DocumentTokenExpirationCore" onChange={(e) => inputChangeHandler(e)} value={settings?.tsettings?.DocumentTokenExpirationCore || ''}  required  />
                        </div>
                        <div className="form-control-mail"> 
                            <label className="emailSettings__formLabel" htmlFor='docTokenDuration'>Durée du token (Document) :</label>
                            <input className="emailSettings__formInput" type="text" 
                            name="docTokenDuration" onChange={(e) => inputChangeHandler(e)} value={settings?.tsettings?.docTokenDuration || ''} required  />
                        </div>
                    </div>*/}

                        
                    </div>
                    <button type="submit" className="btn btn-primary" onClick={(e) => editSettings(e,settings)}>Sauvegarder</button>   
                </form>
                
            </div>
            <div className="tab-pane fade" id="list-smtp">
                <form className="emailSettings__for" action="#">
                    <h1 className="emailSettings__formH1">Accès au serveur SMTP</h1>
                    <div className="left">
                        <div className="form-control-mail"> 
                            <label className="emailSettings__formLabel" htmlFor='smtpLogin'>SMTP Login :</label>
                            <input className="emailSettings__formInput" type="text" 
                            name="smtpLogin" onChange={(e) => inputChangeHandler(e)} value={settings?.tsettings?.smtpLogin || ''} required  />
                        </div>
                        <div className="form-control-mail"> 
                            <label className="emailSettings__formLabel" htmlFor='smtpPassword'>SMTP Password :</label>
                            <input className="emailSettings__formInput" type="password" 
                            name="smtpPassword" onChange={(e) => inputChangeHandler(e)} value={settings?.tsettings?.smtpPassword || ''} required  />
                        </div>
                    </div>
                    <div className="left">
                        <div className="form-control-mail "> 
                            <label className="emailSettings__formLabel" htmlFor='emailFrom'>From Mail :</label>
                            <input className="emailSettings__formInput" type="text" 
                            name="emailFrom" onChange={(e) => inputChangeHandler(e)} value={settings?.tsettings?.emailFrom || ''} required  />
                        </div>
                        <div className="form-control-mail"> 
                            <label className="emailSettings__formLabel" htmlFor='fromName'>From Name :</label>
                            <input className="emailSettings__formInput" type="text" 
                            name="fromName" onChange={(e) => inputChangeHandler(e)} value={settings?.tsettings?.fromName || ''} required  />
                        </div>
                    </div>
                    <div className="left">
                        <div className="form-control-mail"> 
                            <label className="emailSettings__formLabel" htmlFor='smtpPort'>SMTP Port :</label>
                            <input className="emailSettings__formInput" type="text" 
                            name="smtpPort" onChange={(e) => inputChangeHandler(e)} value={settings?.tsettings?.smtpPort || ''} required  />
                        </div>
                        <div className="form-control-mail"> 
                            <label className="emailSettings__formLabel" htmlFor='smtpHost'>SMTP Host :</label>
                            <input className="emailSettings__formInput" type="text" 
                            name="smtpHost" onChange={(e) => inputChangeHandler(e)} value={settings?.tsettings?.smtpHost || ''} required  />
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary" onClick={(e) => editSettings(e,settings)} >Sauvegarder</button>   
                </form>
            </div>

            <div className="tab-pane fade" id="list-sftp">
                <form className="emailSettings__for" action="#">
                    <h1 className="emailSettings__formH1">Paramètres SFTP</h1>
                    <div className="form-control-mail left"> 
                        <label className="emailSettings__formLabel" htmlFor='sftpStorageDuration'>Délai de conservation des fichiers clients sur SFTP (en mois) :</label>
                        <input className="emailSettings__formInput" type="number" min="1" max="6" name="sftpStorageDuration" onChange={(e) => inputChangeHandler(e)} value={settings?.tsettings?.sftpStorageDuration || ''} required  />
                    </div> 
                    <button type="submit" className="btn btn-primary" onClick={(e) => editSettings(e,settings)} >Sauvegarder</button>         
                </form>
            </div>

        </div>
    </div>

    )
}

export default Settings
