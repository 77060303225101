import React, { useState } from 'react'
import './TableBody.css'
import DraftsIcon from '@material-ui/icons/Drafts';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import MarkunreadIcon from '@material-ui/icons/Markunread';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';




import { Link } from 'react-router-dom';
import RenderPdf from './RenderPdf/RenderPdf';
import { Tooltip, withStyles } from '@material-ui/core';

const CTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 15,
    },
}))(Tooltip);

const KO_STEPS = ['KO_SIGN', 'KO_DEPO', 'KO_TRAD2', 'KO_MAIL']
const KO_STEPS_LIBELLES = { 'KO_SIGN': 'SIGNATURE', 'KO_DEPO': 'ARCHIVAGE AU COFFRE', 'KO_TRAD2': 'TRADUCTION', 'KO_MAIL': 'ENVOI DE MAIL' }
const PENDING_STEPS = ['WF_SIGN', 'WF_DEPO', 'WF_TRAD2', 'WF_TRAD2_SERVER_RETRY', 'WF_MAIL', 'WF_MAIL2', 'WF_MAIL3']
const PENDING_STEPS_LIBELLES = { 'WF_SIGN': 'SIGNATURE', 'WF_DEPO': 'ARCHIVAGE AU COFFRE', 'WF_TRAD2': 'TRADUCTION', 'WF_TRAD2_SERVER_RETRY': 'TRADUCTION', 'WF_MAIL': 'ENVOI DE MAIL', 'WF_MAIL2': 'ENVOI DE MAIL', 'WF_MAIL3': 'ENVOI DE MAIL' }


function TableBody(props) {
    const { data, formatDate, setViewed, selectedRows, setSelectedRows, replayKoInvoiceHandler, showErrors, showRetry } = props;

    const formatNumber = (number) => {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number).replaceAll('.', ' ')
    }

    const getSelectedRowValue = (id) => {
        if (selectedRows) {
            return selectedRows[id] || false
        } else return false
    }

    const selectRowHandler = (e) => {
        let id = e.target.name;
        let val = e.target.checked;
        setSelectedRows(id, val)
    }

    const getTimeDate = (date) => {
        let newDate = new Date(date);
        let hour = newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours()
        let minutes = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes()
        return hour + ":" + minutes
    }

    const replayKoInvoice = (e, uid, number) => {
        console.log("replayKoInvoice")
        e.preventDefault();
        replayKoInvoiceHandler(uid, number)
    }

    return (
        <>
            {
                data.map(row => (
                    <tr key={row.id} className={!KO_STEPS.includes(row.step) ? "tableBody__tr" : "tableBody__tr_error"}>
                        <td >
                            {!KO_STEPS.includes(row.step) && !PENDING_STEPS.includes(row.step) &&
                                <input type="checkbox" name={row.uid} onChange={(e) => selectRowHandler(e)} checked={getSelectedRowValue(row.uid)} className="form-check-input" style={{ margin: 0 }} id="exampleCheck1" />
                            }
                        </td>
                        {showErrors &&
                            <td >
                                {
                                    KO_STEPS.includes(row.step) ?
                                        <CTooltip title={"NON ENVOYEE, ERREUR " + row.step + ", CONTACTEZ LE SUPPORT"} className="tableActions__icon" style={{ color: '#022f67', fontSize: "20px" }}>
                                            <ErrorOutlineIcon />
                                        </CTooltip>
                                        : PENDING_STEPS.includes(row.step) ?
                                            <CTooltip title={"EN COURS DE TRAITEMENT, ETAPE " + PENDING_STEPS_LIBELLES[row.step]} className="tableActions__icon" style={{ color: '#022f67', fontSize: "20px" }}>
                                                <HourglassEmptyIcon />
                                            </CTooltip>
                                            : null
                                }
                            </td>
                        }
                        <td >
                            {(!KO_STEPS.includes(row.step) && !PENDING_STEPS.includes(row.step)) ? (
                                <>
                                    {+row.firstAccessDate > 0 ?
                                        <CTooltip title={formatDate(+row.firstAccessDate) + " " + getTimeDate(+row.firstAccessDate)} className="tableActions__icon" style={{ color: '#022f67', fontSize: "20px" }}>
                                            <DraftsIcon />
                                        </CTooltip> :
                                        <MarkunreadIcon className="tableActions__icon" style={{ color: "#ffa618", fontSize: "20px" }} />}
                                </>
                            ) : null
                            }
                        </td>
                        <td >
                            <div className="tableBody__companyName">
                                <Link to={{
                                    pathname: "/client",
                                    state: {
                                        clientUid: row.clientUid
                                    }
                                }}>
                                    {row.clientName}
                                </Link>
                            </div>

                        </td>
                        <td >{row.clientEnseigne} </td>
                        <td >{row.clientCode} </td>

                        <td >{row.number}</td>
                        <td> {row.invoiceType} </td>
                        <td > <div className="tableBody__Amount"> {formatNumber(row.exclusiveTaxAmount)} </div> </td>
                        <td > <div className="tableBody__Amount"> {formatNumber(row.inclusiveTaxAmount)} </div> </td>
                        <td > {formatDate(+row.issuingDate)} </td>
                        <td > {formatDate(+row.dueDate)} </td>
                        <td >
                            {
                                KO_STEPS.includes(row.step) ?
                                    (<>{"NON ENVOYEE, ERREUR " + KO_STEPS_LIBELLES[row.step] + ", CONTACTEZ LE SUPPORT"}
                                        {showRetry &&
                                            <CTooltip title={"REJOUER"} className="tableActions__icon" style={{ color: '#022f67', fontSize: "20px" }}>
                                                <div className="tableActions__action" style={{ border: "none" }}>
                                                    <CompareArrowsIcon onClick={(e) => replayKoInvoice(e, row.uid, row.number)} fontSize="small" />
                                                </div>
                                            </CTooltip>
                                        }
                                    </>)
                                    : PENDING_STEPS.includes(row.step) ?
                                        (<>{"EN COURS : " + PENDING_STEPS_LIBELLES[row.step]}
                                        </>)
                                        : (<>
                                            <RenderPdf number={row.id} uid={row.uid} type="PDF" docName={row.localCopy} linkName="PDF" clicked={setViewed} />
                                            {+row.clientUbl === 1 ? (
                                                <>
                                                    <span style={{ margin: "0 2px" }}>|</span>
                                                    <RenderPdf number={row.id} uid={row.uid} type="UBL" docName={row.localCopy} linkName="UBL" clicked={setViewed} />
                                                </>
                                            ) : null}
                                        </>)
                            }
                        </td>
                    </tr>

                ))

            }
        </>
    )
}

export default TableBody
