import React from 'react';
import './TableHead.css'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CheckIcon from '@material-ui/icons/Check';

function TableHead(props) {
    const {columns, reverse, setSortQuery, setReverse, getData, selectedAllRows, setSelectedAllRows} = props;
    
    const columnClickHandler = (field) => {
        setSortQuery(field);
        let reverseValue= reverse[field] ? reverse[field] : false 
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse);
        getData(field,newReverse);
    }

    const selectAllRowsHandler = (e) => {
        setSelectedAllRows(!selectedAllRows);
    }

    return (
        <>
            <thead className="">
                    <tr>
                        <th key={"column123"} scope="col" className="columnHead" style={{width: "50px",paddingTop:0}}>
                            <div className="columnHead__container">
                                <div className="columnHead__title">
                                    <CheckIcon onClick={(e) => selectAllRowsHandler(e)} />
                                </div>
                            </div>
                        </th>
                        {columns.map(column => (
                            <th key={column.field} scope="col" id={column.field} className="columnHead" style={{borderTop: "none", width: column.width}} onClick={() => columnClickHandler(column.field)}>
                                <div className="columnHead__container">
                                    <div className="columnHead__title">
                                        {column.title}
                                    </div>
                                    <div className="columnHead__sort">
                                        <ArrowUpwardIcon style={{fontSize: 20, display: reverse[column.field] === true ? 'block' : 'none' }} />
                                        <ArrowDownwardIcon style={{fontSize: 20, display: reverse[column.field] === false ? 'block' : 'none' }}/>
                                    </div>
                                </div>    
                            </th>
                            
                        ))}
                        <th key={"column1453"} scope="col" className="columnHead" style={{width: "150px",paddingTop:0}}>
                            <div className="columnHead__container">
                                <div className="columnHead__title">
                                    Telecharger
                                </div>
                            </div>
                        </th>
                    </tr>
            </thead>
        </>
    )
}

export default TableHead
