import React, { useCallback, useEffect, useState } from 'react';
import './ChgLog.css';
import Axios from '../../axios-alkor';
import TitleHelmet from '../UI/TitleHelmet/TitleHelmet';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import 'react-nl2br';
import createPalette from '@material-ui/core/styles/createPalette';

// Email => Bloc 
// mail de rappel 
// mail expiration des token et des document

const ChgLog = props => {
    const [versions, setVersions] = useState(null);
    const [updated , setUpdated] = useState(false);
    const [version, setVersion] = useState(null);
    const [versionDate, setVersionDate] = useState(null);
    const [versionFeatures, setVersionFeatures] = useState(null);
    const notyf = new Notyf();
    const nl2br = require('react-nl2br');

    const login = localStorage.getItem('login');

    useEffect(() => {
        Axios.get('/chglog').then(response => {
            setVersions(response.data.result);
        })
    }, [updated])
    
    const inputChangeHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setVersions({
            ...versions,
            [name]: value
        })
    }

    const submitHandler = () => {
        Axios.post('/chglog', {version, versionFeatures}).then(response=> {
            setVersions(response.data.result);
            setVersion(null);
            setVersionFeatures(null);
        })
    }

    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0){
            let newDate = new Date(parseInt(date));
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
        return day + '/' + month + '/' + newDate.getFullYear()
        }else return null;    
    }, []);

    return (

    <div className="emailSettings__container">
        <TitleHelmet title="Portail Alkor | Notes de version " />
        <div className="tableActions">
            <div className="" style={{marginTop:"10px"}}>
                <h2 style={{color:"#666666"}}>Notes de version</h2>
            </div>
        </div><hr width="100%" style={{ margin: "0"}}/>

        <div className="row">
            <div className="col-md-2">
                <strong>Version</strong>
            </div>
            <div className="col-md-2">
                <strong>Date</strong>
            </div>
            <div className="col-md-7">
                <strong>Features</strong>
            </div>
        </div>

        {
            login == 'admin@icd.fr' ?
            <div className="row">
                <div className="col-md-2">
                    <input type="text" onChange = { (e) => setVersion(e.target.value)}></input>
                </div>
                <div className="col-md-2">
                </div>
                <div className="col-md-7">
                    <textarea style={{ width:"90%" }} onChange = { (e) => setVersionFeatures(e.target.value)}></textarea>
                </div>
                <div className="col-md-1">
                    <button className="btn btn-primary" onClick = { () => submitHandler() }>Save</button>
                </div>
            </div>
            : null

        }
        

        {
            versions && versions.map(v => (
            <div className="row">
                <div className="col-md-2">
                    {v.versionNumber}
                </div>
                <div className="col-md-2">
                    {formatDate(v.versionDate)}
                </div>
                <div className="col-md-8">
                    {nl2br(v.versionFeatures)}
                </div>
            </div>
            ))
        }

    </div>

    )
}

export default ChgLog
