import React from 'react'
import './StatHeaderElement.css'

function StatHeaderElementU(props) {
    const {value, icon, progress, item, percentage, containerBGColor, textColor, width} = props
    
    return (
        <div className="col-sm-3">
            <div className="card">
                <div className="card-header">
                    <span>{icon}</span> {item}
                </div>
                
                <div className="card-body">
                    <div className="element__header">
                        <div className="elementHeader__value">
                            {value}
                        </div>
                        {percentage && <div className="elementHeader__icon">
                            <span>{percentage}</span>
                        </div>}
                    </div>
                    <div className="element__progressBar">
                        <span style={{width: progress + '%'}} ></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatHeaderElementU
