import React, { useCallback, useRef, useState } from 'react'
import Axios from '../../../axios-alkor';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ReplayIcon from '@material-ui/icons/Replay';
import { Tooltip, withStyles } from '@material-ui/core';
import { CSVLink } from "react-csv";

const CTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
}))(Tooltip);

function TableActions(props) {
    const {resetLogs, setShowFilters, showFilters, formatDate, logsFilters} = props;
    const [csvData, setCsvData] = useState();
    const csvBtn = useRef();

    const csvHeaders = [
        {label: 'Type', key: 'type'},
        {label: 'Entité', key: 'entity'},
        {label: 'Name', key: 'name' },
        {label: 'Siret', key: 'siret'},
        {label: 'N° document', key: 'invoiceNumber'},
        {label: 'Date facture', key: 'invoiceDate'},
        {label: 'Date émission', key: 'invoiceTransmissionDate'},
        {label: 'Code statut', key: 'statusCode'},
        {label: 'Description', key: 'description'},
        {label: 'Niveau', key: 'level'},
        {label: 'Nom fichier', key: 'fileReference'},
        {label: 'Étape', key: 'step'}
    ]
    
    const exportSelectionCsv = useCallback((filters) => {
        let newFilters
        if(filters){
            for (const key in filters) {
                if(filters[key])
                    newFilters = {
                        ...newFilters,
                        [key]: filters[key]
                    }
            }
        }
        let csvDataTemp = null;
        Axios.get('/logs//csvCompressedDocument', {
            params: {...newFilters}
        }).then((response) => {
                csvDataTemp = response.data.content.map(row => {
                    return {
                        ...row,
                        creationDate: formatDate(+row.creationDate)
                    } 
                })
                setCsvData(csvDataTemp);
                csvBtn.current.link.click();
        }).catch(err => console.log(err.data))
    },[])

    return (
        <div className="tableActions">
            <div className="tableActions__icons">
                <div className="tableActions__action" style={{border: "none"}}>
                    <CTooltip title="Reinitialiser les filtres" className="tableActions__icon">
                        <ReplayIcon onClick={() => resetLogs()} fontSize="small"  />
                    </CTooltip>
                </div>
                <div className="tableActions__action">
                    <CTooltip title="Filtres" className="tableActions__icon"  style={{color: showFilters ? '#ea5e0b' : '#00000080'}}>
                        <FilterListIcon onClick={() => setShowFilters(!showFilters)} fontSize="small"  />
                    </CTooltip>
                </div> 
                <div className="tableActions__action"  onClick={() => exportSelectionCsv(logsFilters)}>
                    <CTooltip title="Exporter Csv" className="tableActions__icon" >
                        <ImportExportIcon  fontSize="small"/>
                    </CTooltip>
                </div>
                {csvData ? (<CSVLink
                    ref={csvBtn}
                    data={csvData}
                    filename={"LogsCsv.csv"}
                    headers={csvHeaders}>
                </CSVLink>) : null} 
            </div>
            
        </div>
    )
}

export default TableActions
