import React, { useEffect, useState } from 'react'
import './Client.css';
import { useLocation, useHistory } from 'react-router-dom'
import Axios from '../../axios-alkor';
import BusinessIcon from '@material-ui/icons/Business';
import CloseIcon from '@material-ui/icons/Close';
import TitleHelmet from '../../component/UI/TitleHelmet/TitleHelmet';
import { Link} from 'react-router-dom';
import { Fragment } from 'react';

function Client() {
    const [client, setClient] = useState(null);
    const [childrens, setChildrens] = useState(null);
    const [isParent, setIsParent] = useState(false);
    let location = useLocation();
    const history = useHistory();
	console.log(location);
    let emailsCc = client?.emailsCc.split(",");
    useEffect(() => {
        const clientUid = location.state.clientUid;
        Axios.get('/client/'+clientUid+'/object').then((response) => {
            setClient(response.data.client)
            setChildrens(response.data.childrens)
        }).catch(err => {
            console.log(err);
        })
    },[location])  
    
    return (
        <div style={{margin: "0 20px 0 20px"}}>
            <TitleHelmet title="Portail facture Alkor | Client" />
            <div className="tableActions">
                    <div className="tableActions__title">
                        <BusinessIcon fontSize="large" className="documentTitle__icon" />
                        <h3>Référentiel { isParent ? "parent" : "client" } </h3>
                    </div>
                </div>
                <hr width="100%" style={{padding:0, marginBottom: "19px"}}/>
            {
                client ? (
                    <>
                        <div className="referentiel__container">

                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            Informations générales
                                        </div>
                                        <div className="card-body row " style={{marginTop:"0px"}} >
                                            <div className="col-sm-4">
                                            <div className="referentiel__element">
                                                <h5 style={{fontWeight: "bold", marginRight: "20px"}}>Code client :</h5>
                                                <h5>{client.customerCode}</h5>
                                            </div>
                                            <div className="referentiel__element">
                                                <h5 style={{fontWeight: "bold", marginRight: "20px"}}>Nom du client :</h5>
                                                <h5>{client.name}</h5>
                                            </div>
                                            <div className="referentiel__element">
                                                <h5 style={{fontWeight: "bold", marginRight: "20px"}}>Type du client :</h5>
                                                <h5>{client.supplier == 0 ? "client" : "fournisseur" } </h5>
                                            </div>

                                            { client.parentName != null ?
                                                <div className="referentiel__element">
                                                    <h5 style={{fontWeight: "bold", marginRight: "20px"}}>Parent :</h5>
                                                    <Link to={{
                                                        pathname: "/client",
                                                            state: {
                                                                clientUid: client.parentUid
                                                                }
                                                            }} onClick={() => {
                                                                setIsParent(true);
                                                            }}>
                                                        <h5>{client.parentName}</h5>
                                                    </Link>
                                                </div>
                                                : ""
                                            }
                                            { client.emailsCc.trim() != "" ?
                                                <div className="referentiel__element_forCc">
                                                    <h5 style={{fontWeight: "bold", marginRight: "20px"}}>Mails en copie :</h5>
                                                    <div>
                                                    {

                                                        emailsCc.map(emailCc => (
                                                            <span>{emailCc}</span>
                                                            )
                                                        )
                                                    }
                                                    </div>
                                                </div> : ""
                                            }
                                            </div>
                                            {childrens?.length > 0  ? 
                                            <div className="col-sm-4 referentiel__element_childrens">
                                                    <h5 style={{fontWeight: "bold", marginRight: "20px"}}>Enfants :</h5>
                                                    <div className="row" style={{marginTop:"9px"}}>
                                                        <h5 style={{fontWeight: "bold",fontSize:"13px"}} className="col-sm-2">Code</h5>
                                                        <h5 style={{fontWeight: "bold",fontSize:"13px"}} className="col-sm-10">Name</h5>
                                                    </div>
                                                    
                                                    {childrens.map(row => (
                                                        <div className="row" style={{marginTop:"0px"}}>
                                                            <h5 className="col-sm-2" style={{fontSize:"12.5px"}}>{row.customerCode} </h5>
                                                            <h5 className="col-sm-10" style={{fontSize:"12.5px"}}>{row.name}</h5>
                                                        </div>   
                                                    ))}
                                                
                                            </div> : ""
                                            }
                                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            
                            <div className=" row">
                                <div className=" col-sm-6">

                                    <div className="card">
                                            <div className="card-header">
                                                Coordonnées
                                            </div>
                                            <div className="card-body">
                                    
                                                <div className="referentiel__element">
                                                    <h5 style={{fontWeight: "bold", marginRight: "20px"}}>Email :</h5>
                                                    <h5>{client.email}</h5>
                                                </div>
                                                <div className="referentiel__element">
                                                    <h5 style={{fontWeight: "bold", marginRight: "20px"}}>Ville :</h5>
                                                    <h5>{client.town}</h5>
                                                </div>
                                                <div className="referentiel__element">
                                                    <h5 style={{fontWeight: "bold", marginRight: "20px"}}>Code Postal  :</h5>
                                                    <h5>{client.postCode}</h5>
                                                </div>
                                                <div className="referentiel__element">
                                                    <h5 style={{fontWeight: "bold", marginRight: "20px"}}>Région  :</h5>
                                                    <h5>{client?.department?.name}</h5>
                                                </div>
                                            </div>
                                        </div>
                                </div>

                                <div className=" col-sm-6">
                                    <div className="card">
                                        <div className="card-header">
                                            Configuration Démat
                                        </div>
                                        <div className="card-body">
                                            <div className="referentiel__element">

                                                <h5 style={{fontWeight: "bold", marginRight: "20px"}}>Type :</h5>
                                                <h5> { (client.dematType ==1 ? "Démat sur portail" : (
                                                    client.dematType == 2 ? "Mail uniquement" : (
                                                        client.dematType==3 ? "Mail avec attachement" : 
                                                            <CloseIcon style={{color: "red", fontSize: "1.8rem"}} />)))  
                                                        }  
                                                </h5>

                                                {/* <h5> { (client.dematType =="PORTAL" ? "Démat sur portail" : (
                                                    client.dematType == "MAIL_ONLY" ? "Mail uniquement" : (
                                                        client.dematType=="MAIL_N_ATTCHMNT" ? "Mail avec attachement" : 
                                                            <CloseIcon style={{color: "red", fontSize: "1.8rem"}} />)))  
                                                        }  
                                                </h5> */}
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            
                            <button style={{marginTop: "19px"}} 
                                className="btn btn-primary"
                                onClick={() => {
                                    history.goBack();
                                    setIsParent(false);
                                }}
                            >
                                Retour
                            </button>
                        </div>
                    
                        
                    </>
                ) : null
            }
            
            
        </div>
    )
}

export default Client
