import React, { useCallback, useRef, useState } from 'react'
import './TableActions.css';
import Axios from '../../../axios-alkor';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import ArchiveIcon from '@material-ui/icons/Archive';
import ReplayIcon from '@material-ui/icons/Replay';
import { Tooltip, withStyles } from '@material-ui/core';
import { CSVLink } from "react-csv";
import ManualAckModal from './ManualAckModal/ManualAckModal' 

const CTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
}))(Tooltip);

function TableActions(props) {
    const {showFilters, filterState, resetData, getCompressedDoc, selectedRows, formatDate, showManualAck} = props;
    const [csvData, setCsvData] = useState();
    const csvBtn = useRef();
    const [showManualAckModal, setShowManualAckModal] = useState(false)

    const csvHeaders = [
        {label: "Code Client", key: "customerCode"},
        {label: "Client", key: "name"},
        {label: "Numéro", key: "number"},
        {label: "Type", key: "invoiceType"},
        {label: "Montant HT", key: "exclusiveTaxAmount"},
        {label: "Montant TTC", key: "inclusiveTaxAmount"},
        {label: "Date de facture", key: "issuingDate"},
        {label: "Date d'echeance", key: "dueDate"},
        //{label: "Litige", key: "litigation"},
    ]
    const exportSelectionZip = (selectedRows) => {
        let selectedIds = [];
        for (const key in selectedRows) {
            
            if (selectedRows[key])
                selectedIds.push(key) 
        }
        getCompressedDoc(selectedIds)
    }
    const exportSelectionCsv = useCallback((selectedRows) => {
        let selectedIds = [];
        for (const key in selectedRows) {
            
            if (selectedRows[key])
                selectedIds.push(key) 
        }
        let csvDataTemp = null;
        Axios.get('/invoice//csvCompressedDocument?selection='+selectedIds)
            .then((response) => {
                csvDataTemp = response.data.content.map(row => {
                    return {
                        ...row,
                        issuingDate: formatDate(+row.issuingDate),
                        dueDate: formatDate(+row.dueDate),
                        litigation: row.litigation === "1" ? "O" : "N"
                    } 
                })
                setCsvData(csvDataTemp);
                csvBtn.current.link.click();
        }).catch(err => console.log(err.data))
    },[])

    const showExportZip = (selectedRows) => {
        for (const key in selectedRows) {
            if (selectedRows[key])
                return true
        }
        return false
    }
    return (
        <div className="tableActions">
            <div className="tableActions__icons">
                <div className="tableActions__action" style={{border: "none"}}>
                    <CTooltip title="Reinitialiser les filtres" className="tableActions__icon">
                        <ReplayIcon onClick={() => resetData()} fontSize="small"  />
                    </CTooltip>
                </div>
                <div className="tableActions__action">
                    <CTooltip title="Filtres" className="tableActions__icon"  style={{color: filterState ? 'rgb(38, 146, 235)' : ''}}>
                        <FilterListIcon onClick={() => showFilters()} fontSize="small"  />
                    </CTooltip>
                </div>
                <div className="tableActions__action" style={{display: showManualAck ? 'flex' : 'none'}} onClick={() => setShowManualAckModal(true)}>
                	<CTooltip title="Rentrer rejet chorus manuellement" className="tableActions__icon" >
                       <AddToQueueIcon  fontSize="small"/>
               		</CTooltip>
                 </div>
                <div className="tableActions__action" style={{display: showExportZip(selectedRows) ? 'flex' : 'none'}}>
                    <CTooltip title="Exporter Zip" className="tableActions__icon" onClick={() => exportSelectionZip(selectedRows)}>
                        <ArchiveIcon  fontSize="small"/>
                    </CTooltip>
                </div>
                <div className="tableActions__action" style={{display: showExportZip(selectedRows) ? 'flex' : 'none'}} onClick={() => exportSelectionCsv(selectedRows)}>
                    <CTooltip title="Exporter Csv" className="tableActions__icon" >
                           <ImportExportIcon  fontSize="small"/>
                    </CTooltip>
                </div>
                {csvData ? (<CSVLink
                    ref={csvBtn}
                    data={csvData}
                    filename={"donneesCsv.csv"}
                    headers={csvHeaders}>
                </CSVLink>) : null}
                {showManualAckModal ? (
                    <ManualAckModal
                        modalClosed = {() => setShowManualAckModal(false)}
                        show = {showManualAckModal}
                />) : null}
            </div>
        </div>
    )
}

export default TableActions
